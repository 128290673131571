import React from "react";
import { COLORS } from "../../utils/constants";
import "./configLabel.css";

export interface ConfigLabelProps {
  /**
   * This is the text value.
   */
  label: string;
  /**
   * Children is optional and postfixed.
   */
  children?: string | JSX.Element | JSX.Element[];
  /**
   * Children is optional and prefixed.
   */
  childrenPrefix?: string | JSX.Element | JSX.Element[];
  /**
   * The color of the label.
   */
  color?: string;
}

/**
 * Configurable Label is a text component that can have childrens like Price or Icons
 */
export const ConfigLabel = ({
  label = "test",
  children,
  childrenPrefix,
  color = "black",
}: ConfigLabelProps) => {
  return (
    <div>
      <span className="configLabel-container" style={{ color: COLORS[color] }}>
        {childrenPrefix}
        {` ${label} `}
        {children}
      </span>
    </div>
  );
};
