import translations from '../util/translationFiles/index'
import data from '../util/dataFiles/index'
const useFileManager = () => {
  //Given a language (string) return the correct translation file
  const chooseTranslation = (language: string): any => {
    const lan = `translation_${language}` as keyof typeof translations
    return translations[lan] ?? translations['translation_default']
  }

  //Given just a brand (string) return the latest version of data.json file
  const findLatest = (brand: string) => {
    const latest = Object.keys(data)
      .filter((key) => key.startsWith(brand))
      .reduce(
        (latest: any, current) => {
          const versionNumber = parseInt(current.split('_')[1].replace('v', ''))
          if (!isNaN(versionNumber) && versionNumber > latest.version) {
            return { version: versionNumber, variable: current }
          }
          return latest
        },
        { version: -1, variable: null },
      )
    return latest.variable as keyof typeof data
  }

  //Given a string, return the correct data,json file
  const chooseDataFile = (file: string): any => {
    const dataFile = `${file}` as keyof typeof data
    const dataFileDefault = findLatest('storke')
    console.log(data[dataFileDefault])

    //if no string, return latest storke file
    if (!file) {
      return data[dataFileDefault]
    }
    //if only brand present, return latest file
    if (!file.split(/(\_)+/)[1]) {
      return data[findLatest(file)] ?? data[dataFileDefault]
    }
    return data[dataFile] ?? data[dataFileDefault]
  }
  return { chooseTranslation, chooseDataFile }
}
export default useFileManager
