import { ColorMapping } from "./types";
import { Info, ShoppingCart, Plus } from "react-feather";

export enum Languages {
  NL = "NL",
  FR = "FR",
  DE = "DE",
}

export const SIZES: any = {
  small: 14,
  medium: 16,
  large: 20,
  larger: 24,
  largest: 40,
};

export const ICONS: any = {
  info: Info,
  cart: ShoppingCart,
  plus: Plus,
};

export const COLORS: ColorMapping = {
  white: "var(--white-default)",
  black: "var(--black-default)",
  grey: "var(--grey-default)",
  blue: "var(--blue-default)",
  orange: "var(--orange-default)",
  red: "var(--red-default)",
  green: "var(--green-default)",
  greenOff: "var(--green-off)",
  yellowOff: "var(--yellow-off)",
  greyLight: "var(--grey-light)",
  blueLight: "var(--blue-light)",
  blueLighter: "var(--blue-lighter)",
  blueLightest: "var(--blue-lightest)",
};
