import { cloudImageProps } from '@legio-invest/react-product-configurator/dist/esm/util/types'
export const MAIN_FILTERS = [
  {
    attribute: 'brand_bc',
    value: 'storke',
  },
  {
    attribute: 'brand_bc',
    value: 'linie',
  },
]

export const LOGO_URL =
  'https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/b/f/0/5/bf058f507dc8f5380e21ce01eda66b32ae17d8b1_Storke_logo_2022.png'

const token = 'aduwlvajep'
export const CLOUD_IMAGE_PROPS: cloudImageProps | undefined = token
  ? {
      token: token,
      generalImagesProps: 'force-format=webp',
      smallImagesProps: 'force_format=webp&w=200',
      width: 3376,
      height: 2997,
    }
  : undefined
