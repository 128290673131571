import React from 'react'
import useUrlParser from '../../hooks/useUrlParser'
import ProductConfigurator from '@legio-invest/react-product-configurator/dist/esm/containers/ProductConfigurator'
import { displayTypes } from '@legio-invest/react-product-configurator/dist/esm/util/types'
import DemoDataCollector from '@legio-invest/react-wall-data-collector/dist/esm/components/DemoDataCollector'
import { CLOUD_IMAGE_PROPS } from '../../util/constants'
import useFileManager from '../../hooks/useFileManager'
const DisplaySelector = () => {
  const { chooseTranslation, chooseDataFile } = useFileManager()
  const { error, currentURL, parsedUrlParams, mainFilter } = useUrlParser()
  const displayError = error
  if (currentURL === '') {
    return null
  }
  if (!parsedUrlParams) {
    return <div>Error: Invalid URL</div>
  }
  const { language, display_type, channel, file } = parsedUrlParams
  const displayType = display_type === displayTypes.view ? displayTypes.view : displayTypes.controller
  return (
    <div className={'wall-container'}>
      {displayError ? (
        <div>Error: Invalid URL</div>
      ) : (
        <ProductConfigurator
          dataCollector={DemoDataCollector}
          mainFilters={[mainFilter]}
          display={displayType}
          channel={channel}
          jsonData={chooseDataFile(file)}
          cloudImageProps={CLOUD_IMAGE_PROPS}
          translations={chooseTranslation(language)}
        />
      )}
    </div>
  )
}

export default DisplaySelector
