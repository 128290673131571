module.exports = {
  "msrpPrice": {
    "label": "Voordeelprijs"
  },
  "PdpMsrpAdvantage": {
    "label": "Voordeel"
  },
  "sections": {
    "filter": "Filter Section"
  },
  "divergent": {
    "modal": {
      "title": "Opgelet: Door deze keuze kunnen wijzigingen plaatsvinden",
      "content": "Je keuze gaat niet samen met de reeds geselecteerde opties. Sommige opties zijn automatisch ingesteld. Je kan deze optie(s) nog aanpassen na deze bevestiging.",
      "approve": "Keuze bevestigen",
      "cancel": "Annuleren"
    }
  },
  "header": {
    "title": "Mix & Match",
    "text": "Stel je Storke Edge meubel samen en zie het resultaat op ware grote.",
    "action": {
      "info": "Info",
      "reload": "Vernieuw"
    }
  },
  "content": {
    "back": "Overzicht",
    "attribute": {
      "color_detail_bc": "Kleur onderbouwkast",
      "color_detail_t": "Kleur wastafel",
      "series_t": "Wastafelmodel",
      "type_basin_t": "Configuratie wastafel",
      "depth_bc": "Diepte",
      "series_bc": "Serie",
      "brand_bc": "Merk",
      "standing_floating_bc": "Staand of zwevend",
      "sku": "Sku",
      "width_t": "Breedte"
    },
    "multiple": {
      "title": "Mogelijke configuraties"
    }
  },
  "bottom": {
    "multiple": {
      "views": " mogelijke configuraties"
    }
  }
}
;