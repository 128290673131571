import React from "react";
import "./price.css";
import usePrice from "./usePrice";
import { COLORS, SIZES } from "../../utils/constants";

export interface PriceProps {
  /**
   * Price final ammount.
   */
  value: number;
  /**
   * Euro sign is optional.
   */
  showEuroSign: boolean;
  /**
   * Store language, used to set Euro sign and more.
   */
  language: string;
  /**
   * Should we use color?
   */
  colored?: boolean;
  /**
   * Option, this will override any color logic.
   */
  fontColor?: string;
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large" | "larger" | "largest";
}

/**
 * Price component displays a price value with an optional euro sign and customizable styling.
 */
export const Price = ({
  value = 0,
  showEuroSign = true,
  language = "NL",
  colored = true,
  size = "medium",
  fontColor,
  ...props
}: PriceProps) => {
  const { finalValue, color } = usePrice({
    value,
    showEuroSign,
    language,
    colored,
  });
  const colorToUse = colored && color ? color : "black";
  return (
    <span
      style={{
        color: COLORS[fontColor ? fontColor : colorToUse],
        fontSize: SIZES[size],
      }}
      className={`price-${size}`}
    >
      {finalValue}
    </span>
  );
};
