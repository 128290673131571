module.exports = {
  "msrpPrice": {
    "label": "Prix conseillé"
  },
  "PdpMsrpAdvantage": {
    "label": "Avantage"
  },
  "sections": {
    "filter": "Section de filtrage"
  },
  "divergent": {
    "modal": {
      "title": "Attention: Ce choix peut entraîner des modifications",
      "content": "Votre choix n'est pas compatible avec certaines options précédemment sélectionnées. Ces options seront automatiquement définies. Vous pouvez encore modifier ces options après avoir confirmé.",
      "approve": "Confirmer mon choix",
      "cancel": "Annuler"
    }
  },
  "header": {
    "title": "Mix & Match",
    "text": "Composez votre meuble Storke Edge et découvrez le résultat en taille réelle.",
    "action": {
      "info": "Info",
      "reload": "Actualiser"
    }
  },
  "content": {
    "back": "Retour à l'aperçu",
    "attribute": {
      "color_detail_bc": "Couleur du meuble",
      "color_detail_t": "Couleur des lavabos/de la tablette",
      "series_t": "Modèle des lavabos/de la tablette",
      "type_basin_t": "Type:lavabo ou tablette",
      "depth_bc": "Profondeur",
      "series_bc": "Série",
      "brand_bc": "Marque",
      "standing_floating_bc": "Posé ou suspendu",
      "sku": "Numéro d'articles",
      "width_t": "Largeur"
    },
    "multiple": {
      "title": "Configurations possibles"
    }
  },
  "bottom": {
    "multiple": {
      "views": " configurations possibles"
    }
  }
}
;