import { PriceProps } from "./Price";
import { Languages } from "../../utils/constants";
const usePrice = (props: PriceProps) => {
  const { value, showEuroSign, language } = props;

  // Limit decimals to 2 or 0 if none
  const roundPrice = (value: number) => {
    return value
      .toFixed((Math.round(value * 100) / 100) % 1 === 0 ? 0 : 2)
      .replace(".", ",");
  };

  let finalValue = "" + roundPrice(value);
  const color =
    finalValue.charAt(finalValue.length - 1) === "9" ? "red" : "blue";

  //We add `,-` if language is BE or NL and have no decimals.
  let postFix = language === Languages.NL && value % 1 === 0 ? ",-" : "";
  //We want to add the euro sign at the end if the web is in french or german.
  let isEuroPostFixed = language === Languages.FR || language === Languages.DE;

  if (showEuroSign && value != null) {
    finalValue = isEuroPostFixed ? `${finalValue} €` : `€ ${finalValue}`;
  }
  finalValue += postFix;
  return { finalValue, color };
};

export default usePrice;
