import React, { Component } from "react";
import { COLORS, ICONS, SIZES } from "../../utils/constants";
import "./label.css";

export interface LabelProps {
  /**
   * Label text.
   */
  text: string;
  /**
   * Size
   */
  size: "small" | "medium" | "large";
  /**
   * Color of the label.
   */
  color: string;
  /**
   * Background color of the label.
   */
  backgroundColor: string;
  /**
   * Should we show an arrow to the left.
   */
  arrowLeft?: boolean;
  /**
   * Should we show an arrow to the right.
   */
  arrowRight?: boolean;
}

/**
 * Price component displays a price value with an optional euro sign and customizable styling.
 */
export const Label = ({
  text = "test",
  size = "medium",
  color = "black",
  backgroundColor = "orange",
  arrowLeft = false,
  arrowRight = false,
}: LabelProps) => {
  const mainStyles = {
    fontSize: SIZES[size],
    color: COLORS[color],
    backgroundColor: COLORS[backgroundColor],
  };
  const triangleClass = arrowLeft ? "triangleLeft" : "triangleRight";
  return (
    <div
      className={
        arrowLeft || arrowRight ? `container to${triangleClass}` : "label"
      }
      style={mainStyles}
    >
      <div className={arrowLeft || arrowRight ? triangleClass : ""}></div>
      {text}
    </div>
  );
};
