module.exports = {
  "sections": {
    "filter": "Filet Section"
  },
  "divergent": {
    "popup": {
      "title": "Heads up: Something else changes as well",
      "content": "This choice does not allow us to keep all previously set options. We will set some options automaticallt. You can still change these option(s) after confirmation.",
      "accept": "Continue with choice",
      "cancell": "Do not apply choice"
    }
  }
}
;