import React, { Component } from "react";
import { COLORS, ICONS, SIZES } from "../../utils/constants";

export interface IconProps {
  /**
   * Url to Icon image.
   */
  icon: string;
  /**
   * Size of the icon
   */
  size: "small" | "medium" | "large" | "larger";
  /**
   * Color of the Icon image.
   */
  color: string;
}

/**
 * Price component displays a price value with an optional euro sign and customizable styling.
 */
export const Icon = ({
  icon = "info",
  size = "small",
  color = "black",
}: IconProps) => {
  const SelectedIcon = ICONS[icon];
  return <SelectedIcon size={SIZES[size]} color={COLORS[color]} />;
};
