import React from "react";
import { ConfigLabel } from "../ConfigLabel/ConfigLabel";
import { Price } from "../../atoms/Price/Price";
import { Icon } from "../../atoms/Icon/Icon";
import { Label } from "../../atoms/Label/Label";
import "./priceTemplate.css";

export interface PriceTemplateProps {
  /**
   * Main price
   */
  price: number;
  /**
   * Original price
   */
  msrpPrice: number;
  /**
   * App localization
   */
  language: string;
  /**
   * Where is it placed?
   */
  location?: string;
  /**
   * Where is it placed?
   */
  translate?: CallableFunction;
}

/**
 * This is the MSRP discount info description.
 */
export const PriceTemplate = ({
  price,
  msrpPrice,
  language,
  translate = (string: string) => string,
  location = "gallery",
}: PriceTemplateProps) => {
  return (
    <div className="msrp root">
      {/**
       * Msrp info
       */}
      <ConfigLabel label={translate("msrpPrice.label")} color="grey">
        <Price
          language={language}
          showEuroSign={false}
          value={msrpPrice}
          fontColor="grey"
        />
        <Icon size="medium" icon="info" color="grey" />
      </ConfigLabel>
      {/**
       * Main price
       */}
      <Price
        language={language}
        showEuroSign={true}
        value={price}
        fontColor="blue"
        size={location === "pdp" ? "largest" : "large"}
      />
      {location === "pdp" && (
        <div className="priceTemplate-label">
          <Label
            text="50%"
            size="medium"
            color="white"
            arrowLeft={true}
            backgroundColor="orange"
          />
        </div>
      )}
      {/**
       * Msrp discount advantage
       */}
      <ConfigLabel label={translate("PdpMsrpAdvantage.label")} color="orange">
        <Price
          language={language}
          showEuroSign={true}
          value={msrpPrice - price}
          fontColor="orange"
        />
      </ConfigLabel>
    </div>
  );
};
