import linie_v2 from './linie-v2.json'
import linie_v3 from './linie-v3.json'
import storke_v5 from './storke-v5.json'
import storke_v6 from './storke-v6.json'
import storke_v7 from './storke-v7.json'

export default {
  linie_v2,
  linie_v3,
  storke_v5,
  storke_v6,
  storke_v7,
}
