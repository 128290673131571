module.exports = {
  "config": {
    "logo": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/4/5/8/a/458a5f8121e13b918ffe9999cb9b12ee7193a1ed_Linie_logo.png"
  },
  "aggregations": [
    {
      "attribute_code": "color_detail_bc",
      "label": "Kleur detail",
      "filter_type": "Swatch",
      "options": [
        {
          "label": "Beton donkergrijs",
          "value": "concrete_dark_gray",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/8/8/1/6/8816b9efb56d2f9549d0e36dfe6b4f53e854f858_Base_color_storke_edge_concrete_grey.png"
        },
        {
          "label": "Blauw antraciet",
          "value": "blue_anthracite",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/3/b/f/3/3bf38c583e77ade28e2933a242d931190985c8ff_Base_color_linie_blue_anthracite.png"
        },
        {
          "label": "Bruine eik",
          "value": "brown_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/c/e/1/0/ce10764de77111f17c3a5d5d0322591fb133135d_Base_color_linie_brown_oak.png"
        },
        {
          "label": "Cotto",
          "value": "cotto",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/b/c/f/9/bcf9fd3fcd98ff65118dff36c3566626f6b7a334_Base_color_linie_cotto.png"
        },
        {
          "label": "Donkere notelaar",
          "value": "dark_walnut",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/7/8/1/a/781a21ff1b3d73a6748888971fe669f0e5c21260_Base_color_linie_dark_nut.png"
        },
        {
          "label": "Glanzend wit",
          "value": "shiny_white",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/0/9/8/c/098cc4744baa07014e9089ee2342d7e7d9c44c3e_Base_color_storke_edge_shiny_white.png"
        },
        {
          "label": "Greige",
          "value": "greige",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/9/9/0/7/99070178583f82b41f784a7a8cb71c2c19c67f52_Base_color_linie_greige.png"
        },
        {
          "label": "Grijze eik",
          "value": "gray_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/4/f/1/7/4f17ef9557a77ddd0e15b116f82d60a983786e15_Base_color_linie_grey_oak.png"
        },
        {
          "label": "Lichte eik",
          "value": "light_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/d/9/1/e/d91e4cf8d2681e6389e45459bea9edd5d2c6bb90_Base_color_linie_light_oak.png"
        },
        {
          "label": "Mat wit",
          "value": "mat_white",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/4/0/b/f/40bfa028f4ef6b0836cf8569627509ddcc2e0744_Base_color_storke_seda_white.png"
        },
        {
          "label": "Mat zwart",
          "value": "mat_black",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/3/2/8/5/32858913a33126e71a5c93e32082147d3de34554_Base_color_storke_edge_black.png"
        },
        {
          "label": "Natuur eik",
          "value": "natural_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/e/8/d/b/e8dbcdf8316b55de816179899937587d33a5a5f6_Base_color_storke_seda_rough_oak.png"
        },
        {
          "label": "Witte eik",
          "value": "white_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/9/4/0/9/94092e2eb62a500725fa10deac9108de1ad7baab_Base_color_linie_white_oak.png"
        }
      ]
    },
    {
      "attribute_code": "standing_floating_bc",
      "label": "Staand of zwevend",
      "filter_type": "PopupSelect",
      "options": [
        {
          "label": "Staand",
          "value": "standing",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/1/5/5/8/15583c9a1c3d8954d6ea6fd1c0197d52573538a9_Drawers_3.png"
        },
        {
          "label": "Zwevend",
          "value": "floating",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/2/caf2b355d0e6d05a8ee559fd8717c4a9176ae9a8_Drawers_2.png"
        }
      ]
    },
    {
      "attribute_code": "brand_bc",
      "label": "Merk",
      "filter_type": "Text",
      "options": [
        {
          "label": "Linie",
          "value": "linie",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "series_bc",
      "label": "Reeks",
      "filter_type": "Text",
      "options": [
        {
          "label": "Lado",
          "value": "lado",
          "swatch": null
        },
        {
          "label": "Ribbo",
          "value": "ribbo",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "depth_bc",
      "label": "Diepte",
      "filter_type": "Text",
      "options": [
        {
          "label": "46 cm",
          "value": "46cm",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "color_detail_t",
      "label": "Kleur detail",
      "filter_type": "Swatch",
      "options": [
        {
          "label": "Beton donkergrijs",
          "value": "concrete_dark_gray",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/7/0/2/0/7020a9b5244bde87e87710e5faef541700e3d77d_Top_color_concrete_grey.png"
        },
        {
          "label": "Donkergrijs",
          "value": "dark_gray",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/d/e/8/4/de84deb2159103732380eb27d568300c1d45cebd_Top_color_dark_grey.png"
        },
        {
          "label": "Glanzend wit",
          "value": "shiny_white",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/1/9/f/0/19f086bb84c067aa2288ee0f09781d0ee2e24da0_Top_color_shiny_white.png"
        },
        {
          "label": "Leisteen grijs",
          "value": "slatestone_gray",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/b/5/4/7/b54710568acac4bd3b50a4c3eaaeb2ea61390b6c_Top_color_slate_grey.png"
        },
        {
          "label": "Mat wit",
          "value": "mat_white",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/3/a/5/6/3a567beebebbe560c09c9df2c567f598bb4ff762_Top_color_matt_white.png"
        },
        {
          "label": "Mat zwart",
          "value": "mat_black",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/0/4/6/b/046b98551e50aa8a6ce5022ef6cc466041c9443f_Top_color_matt_black.png"
        },
        {
          "label": "Natuur eik",
          "value": "natural_oak",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/1/6/e/6/16e6a74be263f7d585ed44a3e4f57d4319208a5f_Top_color_natural_oak.png"
        }
      ]
    },
    {
      "attribute_code": "type_basin_t",
      "label": "Type wastafel",
      "filter_type": "Text",
      "options": [
        {
          "label": "Tablet voor waskom(men)",
          "value": "upmount",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/6/5/9/e/659e6751077cd3d7092ed7b15ebd64b3c8486f85_Config_Tablet.png"
        },
        {
          "label": "Wastafel",
          "value": "integrated",
          "swatch": "_storage_/akeneo.x2o.assets.prod/assets/4/8/5/d/485d57874f45cc4374a8069659fa136bcbd02a9a_Config_Washbasin.png"
        }
      ]
    },
    {
      "attribute_code": "width_t",
      "label": "Breedte",
      "filter_type": "Text",
      "options": [
        {
          "label": "60 cm",
          "value": "60cm",
          "swatch": null
        },
        {
          "label": "70 cm",
          "value": "70cm",
          "swatch": null
        },
        {
          "label": "80 cm",
          "value": "80cm",
          "swatch": null
        },
        {
          "label": "90 cm",
          "value": "90cm",
          "swatch": null
        },
        {
          "label": "100 cm",
          "value": "100cm",
          "swatch": null
        },
        {
          "label": "120 cm",
          "value": "120cm",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "series_t",
      "label": "Reeks",
      "filter_type": "PopupSelect",
      "options": [
        {
          "label": "Baro",
          "value": "baro",
          "swatch": null
        },
        {
          "label": "Lado",
          "value": "lado",
          "swatch": null
        },
        {
          "label": "Vano",
          "value": "vano",
          "swatch": null
        }
      ]
    }
  ],
  "items": [
    {
      "title": "Linie Lado zwevend badkamermeubel 120 x 46 cm bruine eik met Vano asymmetrisch rechtse wastafel in mat composiet marmer",
      "sku": "200131_200133_70988",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/3/a/883a2edc035ececd30d4162db9bcbc801a346e4e_200131_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/8/e/3/48e3ce30af6810db8cb3da13186ab61d684266f6_70988_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 120 x 46 cm bruine eik met Vano asymmetrisch rechtse wastafel in glanzend composiet marmer",
      "sku": "200131_200133_70980",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/3/a/883a2edc035ececd30d4162db9bcbc801a346e4e_200131_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/b/4/b0b4424194e09853ba70a55c5938a3b89c0edffb_70980_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 120 x 46 cm bruine eik met Vano asymmetrisch linkse wastafel in mat composiet marmer",
      "sku": "200133_200131_70987",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/e/6/a/1e6a561e2656ae6ac7622e52f5063ae1a2686052_200133_200131_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/c/e/3/1ce3000db7480ed5dc28a7bba0259cc1fc1c62c7_70987_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 120 x 46 cm bruine eik met Vano asymmetrisch linkse wastafel in glanzend composiet marmer",
      "sku": "200133_200131_70979",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/e/6/a/1e6a561e2656ae6ac7622e52f5063ae1a2686052_200133_200131_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/0/4/8904d08618fb675fc8d54b6630fbea3302e0b6c6_70979_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Vano dubbele wastafel in composietmarmer",
      "sku": "201929_201867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/b/0/1bb0f0580f4d0a584c76cc2e9eb25d1e686a6aa7_201867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Vano dubbele wastafel in composietmarmer",
      "sku": "201929_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Vano dubbele wastafel in composietmarmer",
      "sku": "201929_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Vano dubbele wastafel in composietmarmer",
      "sku": "201925_201867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/b/0/1bb0f0580f4d0a584c76cc2e9eb25d1e686a6aa7_201867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Vano dubbele wastafel in composietmarmer",
      "sku": "201925_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Vano dubbele wastafel in composietmarmer",
      "sku": "201925_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "201921_201867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/b/0/1bb0f0580f4d0a584c76cc2e9eb25d1e686a6aa7_201867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "201921_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "201921_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Vano dubbele wastafel in composietmarmer",
      "sku": "201917_201867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/b/0/1bb0f0580f4d0a584c76cc2e9eb25d1e686a6aa7_201867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Vano dubbele wastafel in composietmarmer",
      "sku": "201917_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Vano dubbele wastafel in composietmarmer",
      "sku": "201917_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Vano dubbele wastafel in composietmarmer",
      "sku": "200135_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Vano dubbele wastafel in composietmarmer",
      "sku": "200135_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Vano dubbele wastafel in composietmarmer",
      "sku": "200162_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Vano dubbele wastafel in composietmarmer",
      "sku": "200162_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Vano dubbele wastafel in composietmarmer",
      "sku": "90656_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Vano dubbele wastafel in composietmarmer",
      "sku": "90656_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70918_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70918_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70915_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70915_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70909_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70909_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70906_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70906_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70903_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70903_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70890_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70890_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70885_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70885_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70875_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70875_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70870_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70870_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70865_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70865_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70860_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Vano dubbele wastafel in composietmarmer",
      "sku": "70860_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70856_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Vano dubbele wastafel in composietmarmer",
      "sku": "70856_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70852_70986",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/8/2/9/3829566c6e462fa621969613cb91e86725ff45e6_70986_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Vano dubbele wastafel in composietmarmer",
      "sku": "70852_70978",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/e/7/c3e79b066e19b2540ef2dfab900f810b7a0e4038_70978_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 100 x 46 cm bruine eik met Vano asymmetrisch rechtse wastafel in mat composiet marmer",
      "sku": "200131_200132_70985",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/8/3/b78306963c3421894d1a05badf59cca69a90f93a_200131_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/9/8/8798e331f6c5fb5afcb5b3d484bcbf37bcc2ace2_70985_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 100 x 46 cm bruine eik met Vano asymmetrisch rechtse wastafel in glanzend composiet marmer",
      "sku": "200131_200132_70977",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/8/3/b78306963c3421894d1a05badf59cca69a90f93a_200131_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/8/f/9/78f9631356aeccf8b78cf266854c03775b8e0a9b_70977_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 100 x 46 cm bruine eik met Vano asymmetrisch linkse wastafel in mat composiet marmer",
      "sku": "200132_200131_70984",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/9/c/4d9c20439d5bc59635219a61c4f88034e5a668b1_200132_200131_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/d/1/9/bd192374a5a682955c7185da530839721fefa06a_70984_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201928_201864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/4/9/a/c49a3dd27b356dd4d1cd05d987a11274219a3a36_201864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201928_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201928_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201924_201864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/4/9/a/c49a3dd27b356dd4d1cd05d987a11274219a3a36_201864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201924_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201924_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201920_201864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/4/9/a/c49a3dd27b356dd4d1cd05d987a11274219a3a36_201864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201920_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201920_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201916_201864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/4/9/a/c49a3dd27b356dd4d1cd05d987a11274219a3a36_201864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201916_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201916_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200134_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200134_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200161_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200161_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90655_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90655_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70917_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70917_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70914_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70914_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Vano enkele wastafel in composietmarmer",
      "sku": "70911_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Vano enkele wastafel in composietmarmer",
      "sku": "70911_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70908_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70908_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70905_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70905_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70902_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70902_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70889_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70889_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70884_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70884_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70874_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70874_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70869_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70869_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70864_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70864_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70859_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70859_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70855_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70855_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70851_70983",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/c/6/4dc656de4872ed79483a1eaed94e4221844d6bfa_70983_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70851_70975",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/9/6/b/896b6cb0b67941c5eb9b243dc5097d7ed3dd21dd_70975_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Vano enkele wastafel in composietmarmer",
      "sku": "200170_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "200168_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "200166_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "200164_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200118_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "200116_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "200114_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "200112_200086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/a/f/6/caf68f8e2efeab0cb20beb2cc2d7c705da01c7eb_200086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201927_201862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/3/9/b939ed9b171e1870f690c9623c8f3b400caa0ec6_201862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201927_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201927_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201923_201862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/3/9/b939ed9b171e1870f690c9623c8f3b400caa0ec6_201862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201923_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201923_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201919_201862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/3/9/b939ed9b171e1870f690c9623c8f3b400caa0ec6_201862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201919_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201919_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201915_201862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/3/9/b939ed9b171e1870f690c9623c8f3b400caa0ec6_201862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201915_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201915_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200133_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200133_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200160_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200160_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90654_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90654_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70916_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70916_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70913_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70913_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70907_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70907_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70904_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70904_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70901_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70901_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70888_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70888_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70883_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70883_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70873_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70873_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70868_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70868_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70863_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70863_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70858_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70858_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70854_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70854_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70850_70982",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/f/7/c/2f7c8ff56d918ca66dc8af3674f70065c9e326a3_70982_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70850_70974",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/f/1/2/3f12beb9445605a71e79227a5927e1a4371f1a84_70974_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Vano enkele wastafel in composietmarmer",
      "sku": "200169_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "200167_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "200165_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "200163_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200117_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "200115_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "200113_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "200111_200085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/8/a/2e8a4c734baacedf32ad886cfd9b9fa9157e217e_200085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201926_201860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/b/3/d/3b3def019d5c6fbb3255234e792a38afbb2ead79_201860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201926_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46 cm cotto met Vano enkele wastafel in composietmarmer",
      "sku": "201926_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201922_201860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/b/3/d/3b3def019d5c6fbb3255234e792a38afbb2ead79_201860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201922_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46 cm blauw antraciet met Vano enkele wastafel in composietmarmer",
      "sku": "201922_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201918_201860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/b/3/d/3b3def019d5c6fbb3255234e792a38afbb2ead79_201860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201918_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46 cm lichte eik met Vano enkele wastafel in composietmarmer",
      "sku": "201918_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201914_201860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/b/3/d/3b3def019d5c6fbb3255234e792a38afbb2ead79_201860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201914_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46 cm greige met Vano enkele wastafel in composietmarmer",
      "sku": "201914_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200132_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Vano enkele wastafel in composietmarmer",
      "sku": "200132_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90653_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Vano enkele wastafel in composietmarmer",
      "sku": "90653_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70887_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70887_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70882_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70882_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70872_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Vano enkele wastafel in composietmarmer",
      "sku": "70872_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70867_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Vano enkele wastafel in composietmarmer",
      "sku": "70867_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70862_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70862_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70857_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Vano enkele wastafel in composietmarmer",
      "sku": "70857_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70853_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Vano enkele wastafel in composietmarmer",
      "sku": "70853_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70849_70981",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/4/1/4341a88b2524776d4badbfb13c22794e05bbf5c8_70981_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Vano enkele wastafel in composietmarmer",
      "sku": "70849_70973",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/3/7/3737a52749229c5a8fdd2ca1fa384aeff76615bd_70973_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "vano",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Lado enkel of dubbel tablet in melamine",
      "sku": "201929_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Lado enkel of dubbel tablet in melamine",
      "sku": "201929_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Lado enkel of dubbel tablet in melamine",
      "sku": "201925_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Lado enkel of dubbel tablet in melamine",
      "sku": "201925_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "201921_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "201921_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Lado enkel of dubbel tablet in melamine",
      "sku": "201917_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Lado enkel of dubbel tablet in melamine",
      "sku": "201917_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200135_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200135_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200162_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200162_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90656_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90656_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90656_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70918_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70918_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70918_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70915_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70915_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70909_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70909_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70906_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70906_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70903_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70903_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70903_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70890_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70890_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70890_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70885_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70885_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70875_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70875_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70870_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70870_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70865_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70865_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70865_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70860_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70860_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70860_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70856_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70856_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70852_71012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/7/2/6/37265a876f9a7e90f968acaacc582f128acc24c3_71012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70852_71008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/c/a/a/8caad5aa1cdbbcbe452bd295855379deeccdd479_71008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70852_71004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/a/5/6/fa563643c5210c95b3989dbccc1336f4ed30419e_71004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Lado enkel of dubbel tablet in melamine",
      "sku": "201928_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Lado enkel of dubbel tablet in melamine",
      "sku": "201928_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Lado enkel of dubbel tablet in melamine",
      "sku": "201924_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Lado enkel of dubbel tablet in melamine",
      "sku": "201924_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "201920_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "201920_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Lado enkel of dubbel tablet in melamine",
      "sku": "201916_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Lado enkel of dubbel tablet in melamine",
      "sku": "201916_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200134_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200134_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200161_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Lado enkel of dubbel tablet in melamine",
      "sku": "200161_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90655_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90655_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Lado enkel of dubbel tablet in melamine",
      "sku": "90655_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70917_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70917_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70917_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70914_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70914_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Lado enkel of dubbel tablet in melamine",
      "sku": "70911_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm donkere notelaar met Lado enkel of dubbel tablet in melamine",
      "sku": "70911_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70908_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70908_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70905_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70905_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70902_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70902_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70902_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70889_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70889_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70889_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70884_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70884_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70874_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70874_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70869_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70869_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70864_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70864_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70864_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70859_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70859_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Lado enkel of dubbel tablet in melamine",
      "sku": "70859_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70855_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Lado enkel of dubbel tablet in melamine",
      "sku": "70855_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70851_71011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/9/d/1b9dcb421aa32360ffb7189827a262cbe4b2cefb_71011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70851_71007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/6/8/8/968816fdabaf118ee018a8d15d363f27f40c365a_71007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Lado enkel of dubbel tablet in melamine",
      "sku": "70851_71003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/d/9/3/9d93338a835d20e9fc5ecdff3bac48b5c6aca50e_71003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Lado enkel tablet in melamine",
      "sku": "200170_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Lado enkel tablet in melamine",
      "sku": "200170_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46,5  cm mat zwart met Lado enkel tablet in natuur eiken melamine",
      "sku": "200170_200128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/2/6/b726e1d0c9141f86749aa81bb197c7a12af9c3a1_200128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200168_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200168_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200168_200128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/2/6/b726e1d0c9141f86749aa81bb197c7a12af9c3a1_200128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "200166_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "200166_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200164_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200164_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200164_200128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/2/6/b726e1d0c9141f86749aa81bb197c7a12af9c3a1_200128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200118_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200118_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "200116_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "200116_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "200114_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "200114_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90,5 x 46,5  cm natuur eik met Lado enkel tablet in beton donkergrijze melamine",
      "sku": "200112_200180",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/7/68576fd4d2202f4521df190249fd9f484064b858_200180_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46 cm natuur eik met Lado enkel tablet in leisteen grijze melamine",
      "sku": "200112_200130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/8/9/1/58912361c9bdcfc30d2baae53a1c1ea6d8aa9154_200130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "dark_gray",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46 cm natuur eik met Lado enkel tablet in natuur eiken melamine",
      "sku": "200112_200128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/7/2/6/b726e1d0c9141f86749aa81bb197c7a12af9c3a1_200128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "90cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Lado enkel tablet in melamine",
      "sku": "201927_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Lado enkel tablet in melamine",
      "sku": "201927_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Lado enkel tablet in melamine",
      "sku": "201923_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Lado enkel tablet in melamine",
      "sku": "201923_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Lado enkel tablet in melamine",
      "sku": "201919_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Lado enkel tablet in melamine",
      "sku": "201919_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Lado enkel tablet in melamine",
      "sku": "201915_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Lado enkel tablet in melamine",
      "sku": "201915_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200133_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200133_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200160_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200160_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90654_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90654_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90654_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70916_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70916_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70916_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70913_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70913_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70907_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70907_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70904_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70904_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70901_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70901_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70901_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70888_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70888_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70888_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70883_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70883_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70873_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70873_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70868_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70868_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70863_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70863_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70863_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70858_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70858_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70858_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70854_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70854_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70850_71010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/2/6/7/5267edb6f75c0a45b74dab0848be9186c9385d5d_71010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70850_71006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/6/b/106b6498eaf65ff8c9fd0eff1367174660b04d4a_71006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70850_71002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/e/4/5/2e45c85dd8be5aef2a635deb1ea985e175c19df6_71002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "80cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Lado enkel tablet in melamine",
      "sku": "200169_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Lado enkel tablet in melamine",
      "sku": "200169_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46,5  cm mat zwart met Lado enkel tablet in natuur eiken melamine",
      "sku": "200169_200127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/a/2/c/1a2cebb63c0e5f6f6b180e5355d1b3959ca86ff1_200127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200167_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200167_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "200167_200127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/a/2/c/1a2cebb63c0e5f6f6b180e5355d1b3959ca86ff1_200127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "200165_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "200165_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200163_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200163_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "200163_200127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/a/2/c/1a2cebb63c0e5f6f6b180e5355d1b3959ca86ff1_200127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200117_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200117_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "200115_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "200115_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "200113_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "200113_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70,5 x 46,5  cm natuur eik met Lado enkel tablet in beton donkergrijze melamine",
      "sku": "200111_200179",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/c/6/fec68137101e84dd4a872ae96a617e107c47d518_200179_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46 cm natuur eik met Lado enkel tablet in leisteen grijze melamine",
      "sku": "200111_200129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/2/a/4f2a33e22d1025ca8701d1ddbabc86652fb69505_200129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46 cm natuur eik met Lado enkel tablet in natuur eiken melamine",
      "sku": "200111_200127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/a/2/c/1a2cebb63c0e5f6f6b180e5355d1b3959ca86ff1_200127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "70cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Lado enkel tablet in melamine",
      "sku": "201926_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Lado enkel tablet in melamine",
      "sku": "201926_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Lado enkel tablet in melamine",
      "sku": "201922_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Lado enkel tablet in melamine",
      "sku": "201922_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Lado enkel tablet in melamine",
      "sku": "201918_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Lado enkel tablet in melamine",
      "sku": "201918_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Lado enkel tablet in melamine",
      "sku": "201914_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Lado enkel tablet in melamine",
      "sku": "201914_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200132_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Lado enkel tablet in melamine",
      "sku": "200132_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90653_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90653_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Lado enkel tablet in melamine",
      "sku": "90653_71001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/d/4/c/8d4ccee8d2c5a1736a8a4d3fd1382a7f2c58c8d3_71001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70887_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70887_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70887_71001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/d/4/c/8d4ccee8d2c5a1736a8a4d3fd1382a7f2c58c8d3_71001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70882_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70882_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70872_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Lado enkel tablet in melamine",
      "sku": "70872_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70867_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Lado enkel tablet in melamine",
      "sku": "70867_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70862_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70862_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70862_71001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/d/4/c/8d4ccee8d2c5a1736a8a4d3fd1382a7f2c58c8d3_71001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70857_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70857_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Lado enkel tablet in melamine",
      "sku": "70857_71001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/d/4/c/8d4ccee8d2c5a1736a8a4d3fd1382a7f2c58c8d3_71001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70853_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Lado enkel tablet in melamine",
      "sku": "70853_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70849_71009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/a/b/1/9ab1ae05e67fd34b03a8f0923f328e9c6753261a_71009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "slatestone_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70849_71005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/7/9/7/c79737026bd962a7e872dd290c269ce53e8d9039_71005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Lado enkel tablet in melamine",
      "sku": "70849_71001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/d/4/c/8d4ccee8d2c5a1736a8a4d3fd1382a7f2c58c8d3_71001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "60cm",
      "series_t": "lado",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200170_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200170_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200170_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/0/3c10b0784131de7ebbc92416b2ad85f6f7205627_200170_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200168_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200168_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200168_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/4/8/3d482bef43ad1a879e25b6db57b45e608a6ef777_200168_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200166_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200166_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200166_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/3/1/4/731454465d38f0e68943aead7517a42e3c110701_200166_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200164_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200164_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200164_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/9/d/d/99dd83ef54e252d64381423c145fd93415b44684_200164_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200118_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200118_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200118_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/2/f/b42f92445f545d41b5d388249837f35999a66a15_200118_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200116_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200116_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200116_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/0/a/5f0a9af7404ee89326948778253d15e8c0ccce58_200116_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200114_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200114_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  90 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200114_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/d/5/2/4d52bf0517d961448b4d8bd84592dd1e194e3973_200114_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46 cm natuur eik met Baro enkele wastafel in mat zwarte porselein",
      "sku": "200112_200094",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/4/7/a/847a67905bcd1df6bced9c3370a110238e85f6fb_200094_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46 cm natuur eik met Baro enkele wastafel in mat witte porselein",
      "sku": "200112_200092",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/8/3/3d83eac729b7967ba9ac5069df4f0ab391ad4418_200092_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 90 x 46 cm natuur eik met Baro enkele wastafel in glanzend witte porselein",
      "sku": "200112_200090",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/7/7/f777033a840edc09a4d2dc393415b985ce09518e_900_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/b/f/a/8bfa3a282ce686ff1b423fbcc3f6dc4285d9c570_200112_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/1/3/3e13faf7be7e0118bad7628c69dc1231bb2097f4_200090_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "90cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200169_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200169_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat zwart met Baro enkele wastafel in porselein",
      "sku": "200169_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/5/5/3c55944930231387bba140bb2081b73c47d9fa10_200169_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200167_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200167_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "200167_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/7/2/7/f72770730fcedf4593d5a5ad86f7275e78a300e2_200167_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200165_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200165_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "200165_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/2/6/7/f26768a68046df4a32ff47128c560f0444023010_200165_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200163_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200163_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "200163_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/1/4/93149d52a5d5e1a7c17bdfb62b60d3df2ecc64ad_200163_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200117_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200117_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200117_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/2/e/d/12ed86b5b4535a57df0c490506c4a2b8f515b266_200117_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200115_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200115_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "200115_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/c/9/c0c9d5a596c861ce7d52dc32878bcfcc8db861bd_200115_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200113_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200113_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  70 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "200113_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/3/d/5/93d568b04532f357dc1a1837ddc7345f6e1e89d3_200113_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46 cm natuur eik met Baro enkele wastafel in mat zwarte porselein",
      "sku": "200111_200093",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/4/7/8/b478d88659c31cedde961e325972eae25c40b697_200093_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46 cm natuur eik met Baro enkele wastafel in mat witte porselein",
      "sku": "200111_200091",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/3/2/f532131a95c39fa20193c61da9813c4e1d875129_200091_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend badkamermeubel 70 x 46 cm natuur eik met Baro enkele wastafel in glanzend witte porselein",
      "sku": "200111_200089",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/0/d/8/70d8bbc523f5206a7147c07083e70cc489489d79_700_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/7/1/e/871e5a90b73cd8979c3a98862d715e8e1dbba772_200111_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/7/7/c/977c422b8409b126a4f138e34b311611993abf53_200089_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "70cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Baro dubbele wastafel in porselein",
      "sku": "201929_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Baro dubbele wastafel in porselein",
      "sku": "201929_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm cotto met Baro dubbele wastafel in porselein",
      "sku": "201929_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/6/b/f/c6bfcb24afea708526e5eb1cb211c42c09a1a3cf_201929_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Baro dubbele wastafel in porselein",
      "sku": "201925_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Baro dubbele wastafel in porselein",
      "sku": "201925_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm blauw antraciet met Baro dubbele wastafel in porselein",
      "sku": "201925_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/d/7/9bd7197efd9164ba6d74498bca90cf039db9db34_201925_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Baro dubbele wastafel in porselein",
      "sku": "201921_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Baro dubbele wastafel in porselein",
      "sku": "201921_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm lichte eik met Baro dubbele wastafel in porselein",
      "sku": "201921_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/d/c/6/3dc634e181f38b7b43f84fd7b0de012ff97ae964_201921_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Baro dubbele wastafel in porselein",
      "sku": "201917_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Baro dubbele wastafel in porselein",
      "sku": "201917_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 120 x 46,5  cm greige met Baro dubbele wastafel in porselein",
      "sku": "201917_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/5/a/0/c5a067cdfce4a713a553468638977f0fd1d1d955_201917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200135_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200135_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200135_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/8/0/4180cec25a11e52e0eed94b874b7e5520900c198_200135_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200162_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200162_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm bruine eik met Baro dubbele wastafel in porselein",
      "sku": "200162_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/5/0/b/250b847ae9b935b152cb2f5191ac73770e4736bf_200162_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Baro dubbele wastafel in porselein",
      "sku": "90656_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Baro dubbele wastafel in porselein",
      "sku": "90656_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm mat wit met Baro dubbele wastafel in porselein",
      "sku": "90656_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/f/d/5/dfd5a2302b09393471ef51c9eb186602affae117_90656_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70918_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70918_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70918_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/b/2/f/7b2fe66a0867b9c7fe41fee2df560b3adbddd332_70918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70915_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70915_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70915_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/e/8/9/8e8959c4caab57afe0e5994980929c8101900e33_70915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70909_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70909_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70909_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/6/a/8/36a89ab114a565be5ebb8cf4511ab75244a9ec4c_70909_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70906_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70906_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70906_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/0/5/0/a050c565f4ec94bc88d15a9c09363377a1bf0e21_70906_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70903_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70903_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70903_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/1/0/9/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/e/b/feebeb3e599c9585b314bb2f8ac6fa73ab5dce4b_70903_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70890_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70890_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70890_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/8/0/6a8000364a9eece25a5739ce7a2134aef6f0a76e_70890_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70885_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70885_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70885_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/e/0/e/7e0e2fc53f9593b258fba94297eb1ef2df76227c_70885_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70875_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70875_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm grijze eik met Baro dubbele wastafel in porselein",
      "sku": "70875_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/3/5/6/835631052d5c58a8946eb582f2ef605d73487248_70875_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70870_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70870_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm witte eik met Baro dubbele wastafel in porselein",
      "sku": "70870_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/2/e/a32e6d7675af38f09c01320f823cd7877f150128_70870_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70865_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70865_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70865_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/9/c/6/09c672f753856841dfc2c64a92232bf517f6bae2_70865_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70860_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70860_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm glanzend wit met Baro dubbele wastafel in porselein",
      "sku": "70860_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/0/e/9/f0e9bd992d255d615bf76469fc7b84cf97688006_70860_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70856_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70856_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm beton donkergrijs met Baro dubbele wastafel in porselein",
      "sku": "70856_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/6/b/5/26b567d5c1d338f32937e83e1914e8824d1e42d4_70856_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70852_87620",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/a/6/3/6a63d72f490d1e7ff254313270b1934610e1b134_87620_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70852_87616",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/c/6/5/5c65e2243e90ec4db05a792473b28b29df04501f_87616_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  120 x 46 cm natuur eik met Baro dubbele wastafel in porselein",
      "sku": "70852_71000",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/e/7/e/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/9/2/6/b926b042b9c382d5f188ed85f68494a3f24983dd_70852_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/e/e/8aeefd705f732865ee202e5797ce31a6093245c7_71000_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201928_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201928_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201928_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/a/0/5/8a05ac7db4aad3e02e286f90353177544f8d4e16_201928_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201924_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201924_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201924_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/8/6/0/d86002bbeb3e8c43b0007a8f135ae36dcf6b0d97_201924_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201920_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201920_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201920_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/3/a/653a814e824fb40a1833aac9bff47e7eb04f60a9_201920_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201916_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201916_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 100 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201916_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/a/5/c/2a5cfe358555dfe71ba855d1de726d70ca441c98_201916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200134_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200134_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200134_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/4/a/a/14aa060a2096cafeefc61711d12b922035d1387a_200134_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200161_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200161_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200161_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/0/7/8/c0783b2a8583132bbc459ac2803abf38e25e7950_200161_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90655_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90655_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90655_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/f/f/c/5ffc1cb07d032a71db2489ce7f0ee8bfb7a9a336_90655_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70917_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70917_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70917_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/d/b/3/1db3708b481067a5e9080df8b99c8cd2bde35f6d_70917_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70914_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70914_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70914_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/1/b/5/c1b50c5d05940338e2470af7697280c8accaa3ae_70914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Baro enkele wastafel in porselein",
      "sku": "70911_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Baro enkele wastafel in porselein",
      "sku": "70911_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand badkamermeubel 100 x 46,5  cm donkere notelaar met Baro enkele wastafel in porselein",
      "sku": "70911_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/4/f/6/24f6af84d499953457ea4ed1ed56c3a71455d606_70911_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "dark_walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70908_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70908_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70908_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/8/8/7/5/887572a877c4de7bc5ce8a8c36517902ac3ff567_70908_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70905_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70905_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70905_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/6/a/f/56afc2b937a8156905fcbc54b277f081a32dc244_70905_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70902_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70902_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70902_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/8/b/c/c8bc6de15f9e7d91947d4079ffc44bf081214af2_1000_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/d/f/9/7df96f772cdfe1b7ebf1db6b67915183d5d28bc6_70902_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70889_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70889_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70889_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/0/0/c/b00c6c6d14337d5a59e92820b65db02409d73e39_70889_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70884_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70884_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70884_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/b/1/50b1dd02bdbd97c39bea266f4686945b9ae1e8b3_70884_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70874_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70874_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70874_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/1/b/8/41b84c3dbdfc9f9f4a4b75c68301c52cffc5745f_70874_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70869_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70869_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70869_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/4/b/b/a4bb50d026ac274b70cff6dae0a3423051377c99_70869_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70864_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70864_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70864_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/6/6/5/a6650debed2f3c6f1a021eadf55978f4aa76c3d6_70864_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70859_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70859_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70859_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/f/8/d/4f8d5d3bbee5b27a36887009c818bf48217efcf9_70859_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70855_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70855_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70855_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/8/5/8/f858e3965bd46c864b519519626583a81201e05b_70855_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70851_87619",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/5/b/4/65b4d25d7d6f19a9ee99545b2d6c3e5d4651bc2e_87619_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70851_87615",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/5/b/6/e5b68714af47edcc7fee22587c1a1bd4770b3f6b_87615_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  100 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70851_70999",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/7/4/e/2/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/0/8/6/e086b14159f7936942e69ca562fdabf9b5ae13a6_70851_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/7/7/5/6775b9eecfa13b6a49064406541deec24d5506b8_70999_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201927_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201927_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201927_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/8/3/7/a83709744d7f95ba2bca99a88039317f1552fa26_201927_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201923_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201923_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201923_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/c/1/4/3c14befdacac56f8f57b419074ee94a16fbc1904_201923_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201919_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201919_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201919_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/1/1/1/511151d2e5d7a7a759818db1b9af6232141fdd31_201919_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201915_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201915_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 80 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201915_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/4/3/0/d43007b857cd5cd3ea5bd6d7f91a6370ac8046a2_201915_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200133_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200133_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200133_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/4/2/0/4420af82ac229717ce4819377eaaf995d0597684_200133_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200160_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200160_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200160_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/e/2/0/ae20e7f4980766bd37c8ebb30a7b380f129c5f17_200160_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90654_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90654_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90654_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/7/1/f/471fa8fbd54d3511b7f3a540e465994e735848b9_90654_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70916_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70916_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70916_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/3/e/e/43ee8ef1c3b7e48793906ca5bde2c0b2ed99f9e3_70916_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70913_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70913_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70913_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/1/8/1/2181cec308eacfb03d962ad382a5ef78d56a3775_70913_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70907_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70907_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70907_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/e/d/1/ced1e13693c2e4e31bf401af2d0c150df41d041d_70907_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70904_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70904_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70904_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/5/f/6/45f6b2dab4e71d7e208abdca5c7e026ab3f1ab4f_70904_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70901_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70901_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado staand  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70901_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/8/a/5/98a55d711fcfc26561be65f557e16cfa80801ed0_800_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/0/1/b/2/01b2acd66ee2a6aa2f735b801d23a1ca0545b876_70901_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70888_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70888_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70888_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/9/1/e/591e4d632fa5524bfff76d7fe28e0b7315e5a9f7_70888_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70883_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70883_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70883_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/6/8/e/668ed86d3627fca3c8c43563e3c2cf3d7b1f975b_70883_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70873_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70873_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70873_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/7/4/e/a74edbc2e374acbc45dd4ef9912e1e65fdd58d41_70873_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70868_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70868_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70868_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/2/a/0/a2a07d739b5117d51f8c7ec1e2cd91c46445596f_70868_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70863_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70863_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70863_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/4/9/3/34931204d3f063fd3de06428318c3401a79b9ba7_70863_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70858_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70858_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70858_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/3/3/6/e33658fe4100bda1fa9c0bc9988b99adc4b21cae_70858_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70854_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70854_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70854_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/5/0/8/f508da31f16caf95c5644caf7df52c71c5c1129c_70854_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70850_87618",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/c/3/3/1/c331bc899c484853913e1226802eee9365b0a090_87618_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70850_87614",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/4/6/7/94671604108d2734ff4e4aa0342ee2a58b59fb33_87614_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  80 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70850_70998",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/a/8/8/5a88e633cb14467e12e6df1e86c2f337192fbdea_800_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/a/3/c/2/a3c21b1876d7e96c2d0be32d1eb69afa76e77b35_70850_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/0/a/d/50ad2c8ad03c4235ada6e838d5108730ce67876a_70998_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "80cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201926_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201926_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm cotto met Baro enkele wastafel in porselein",
      "sku": "201926_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/e/a/f/3eaff37fdbc7e501dcdf5d857a40fa26a447eb50_201926_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "cotto",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201922_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201922_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm blauw antraciet met Baro enkele wastafel in porselein",
      "sku": "201922_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/8/5/3/685367c562c95aa2b38750f2a96077e6d551c108_201922_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "blue_anthracite",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201918_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201918_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm lichte eik met Baro enkele wastafel in porselein",
      "sku": "201918_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/b/e/8/1be81087777a63dd7e4cb33c7803f15b97688380_201918_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "light_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201914_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201914_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Ribbo zwevend badkamermeubel 60 x 46,5  cm greige met Baro enkele wastafel in porselein",
      "sku": "201914_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/2/8/7/4/287455bf17763929aa0b2cd5564cfd8cd23bb2f8_linie_linie.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/5/b/9/d/5b9d33f8ef3cd82d2a7e58ee42b0be0857c99d14_201914_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "greige",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "ribbo",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200132_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200132_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm bruine eik met Baro enkele wastafel in porselein",
      "sku": "200132_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/4/e/f/9/4ef9134759ece27177158f19ec2ec937d2baad7f_200132_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "brown_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90653_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90653_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm mat wit met Baro enkele wastafel in porselein",
      "sku": "90653_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/7/a/8/e7a804b07cc1efe475cc9be294a15a4b4624809a_90653_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70887_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70887_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70887_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/d/1/a/dd1a72791ad2680dee71c57795ad210e545488c5_70887_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70882_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70882_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70882_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/2/a/9/62a9fbcbc070b50d44475ddf3da5f71bdb04c772_70882_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70872_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70872_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm grijze eik met Baro enkele wastafel in porselein",
      "sku": "70872_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/0/3/9/9039c9f044d92c455ae3e88c4b3a531ac0c11d64_70872_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "gray_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70867_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70867_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm witte eik met Baro enkele wastafel in porselein",
      "sku": "70867_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/b/b/5/6/bb56baeaa68ea0313bf595719dcd2ed6c5ebf5c1_70867_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "white_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70862_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70862_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70862_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/6/0/0/0/6000572249da8f9e5028aa634993baccdde59667_70862_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70857_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70857_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm glanzend wit met Baro enkele wastafel in porselein",
      "sku": "70857_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/f/7/f/1f7f273e58585d365e10e84140c4cea34d274dd7_70857_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70853_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70853_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm beton donkergrijs met Baro enkele wastafel in porselein",
      "sku": "70853_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/f/4/d/c/f4dc7ddf01eb6e968e55f33c866c1ab4aacb77d6_70853_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70849_87617",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/e/9/5/3/e953f0d27754ac8a6b5591f72a3ddbfe1473b817_87617_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70849_87613",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/1/1/0/1110e28a244c53802f363ae047e0c84dcd7315a3_87613_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    },
    {
      "title": "Linie Lado zwevend  60 x 46 cm natuur eik met Baro enkele wastafel in porselein",
      "sku": "70849_70997",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/1/0/a/4/10a43922ee9008302854336b634a03d0cfb270ba_linie_lado.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/9/b/f/b/9bfb4af31ae4d8c1134e79363bfc9409f4e75926_600_suspended_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/3/9/9/c/399c51eb60b9469c4a4352c7a57d365748442d3f_70849_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "_storage_/akeneo.x2o.assets.prod/assets/d/6/e/c/d6ec550f0c4f62c682fa28ae5b0c1b9d60999da3_70997_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "linie",
      "series_bc": "lado",
      "depth_bc": "46cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "60cm",
      "series_t": "baro"
    }
  ]
}
;