import { useEffect, useState } from 'react'
import { urlParams } from '../types'
import { displayTypes } from '@legio-invest/react-product-configurator/dist/esm/util/types'
import { MAIN_FILTERS } from '../util/constants'

const useUrlParser = () => {
  const [currentURL, setCurrentURL] = useState<string>('')

  let displayType = displayTypes.default
  let error = false
  useEffect(() => {
    setCurrentURL(window.location.pathname)
  }, [])

  const [, language, shop_id, type, number, fileString] = currentURL.split(/[\/]+/)
  const [fileName, version] = fileString?.split(/[\-]+/) ?? ['', '']

  if (currentURL !== '') {
    if (isNaN(Number(shop_id)) || !isNaN(Number(type)) || isNaN(Number(number)) || currentURL === '/') {
      error = true
    }
    if (type == displayTypes.view) {
      displayType = displayTypes.view
    } else if (type == displayTypes.controller) {
      displayType = displayTypes.controller
    } else {
      error = true
    }
  }

  const language2 = language == '' || !isNaN(Number(language)) || language?.charAt(2) !== '-' ? 'default' : language
  const lang = language2.replace(/-/g, '_')

  const channel = `${Number(shop_id)}-${Number(number)}`

  const file = version ? fileName + '_' + version : fileName

  const mainFilter = MAIN_FILTERS.find((filter: any) => filter.value === fileName) ?? MAIN_FILTERS[0]

  const parsedUrlParams: urlParams = {
    language: lang,
    display_type: displayType,
    channel: channel,
    file: file,
  }

  return { error, currentURL, setCurrentURL, parsedUrlParams, mainFilter }
}
export default useUrlParser
