import translation_nl_be from './translation-nl-be.json'
import translation_nl_nl from './translation-nl-nl.json'
import translation_fr_be from './translation-fr-be.json'
import translation_fr_fr from './translation-fr-fr.json'
import translation_de_de from './translation-de-de.json'
import translation_default from './translation-default.json'
export default {
  translation_nl_be,
  translation_nl_nl,
  translation_fr_be,
  translation_fr_fr,
  translation_de_de,
  translation_default,
}
