module.exports = {
  "config": {
    "logo": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/b/f/0/5/bf058f507dc8f5380e21ce01eda66b32ae17d8b1_Storke_logo_2022.png"
  },
  "aggregations": [
    {
      "attribute_code": "color_detail_bc",
      "label": "D\u00e9tail couleur",
      "filter_type": "Swatch",
      "options": [
        {
          "label": "Blanc brillant",
          "value": "shiny_white",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/8\/c\/098cc4744baa07014e9089ee2342d7e7d9c44c3e_Base_color_storke_edge_shiny_white.png"
        },
        {
          "label": "Blanc mat",
          "value": "mat_white",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/b\/f\/40bfa028f4ef6b0836cf8569627509ddcc2e0744_Base_color_storke_seda_white.png"
        },
        {
          "label": "Bleu nuit",
          "value": "midnight_blue",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/d/5/6/6/d566d4cb302be39a010a25e4e8b0e9b2d82da8a4_Base_color_storke_seda_blue.png"
        },
        {
          "label": "B\u00e9ton gris argent",
          "value": "concrete_silver",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/9\/7\/6297e0c257161c550692ce4f1e1473adff2bd390_Base_color_storke_edge_light_cement.png"
        },
        {
          "label": "B\u00e9ton gris fonc\u00e9",
          "value": "concrete_dark_gray",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/1\/6\/8816b9efb56d2f9549d0e36dfe6b4f53e854f858_Base_color_storke_edge_concrete_grey.png"
        },
        {
          "label": "Ch\u00eane brut",
          "value": "rough_oak",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/4\/c\/1b4c2a6fa674593ad9f1eca6dfe707868d878c9a_Base_color_storke_edge_rough_oak.png"
        },
        {
          "label": "Ch\u00eane naturel",
          "value": "natural_oak",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/d\/b\/e8dbcdf8316b55de816179899937587d33a5a5f6_Base_color_storke_seda_rough_oak.png"
        },
        {
          "label": "Ch\u00eane noir",
          "value": "black_oak",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/5/0/0/1/5001aeb076b3ded57302fec3d768eedbd004f22f_Base_color_storke_seda_black.png"
        },
        {
          "label": "Noir mat",
          "value": "mat_black",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/8\/5\/32858913a33126e71a5c93e32082147d3de34554_Base_color_storke_edge_black.png"
        },
        {
          "label": "Noyer",
          "value": "walnut",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/0\/7\/a507f1132191d61d81ca4131b93f10d4ace63f22_Base_color_storke_edge_walnut.png"
        },
        {
          "label": "Soie grise",
          "value": "silk_grey",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/b/f/5/9/bf5903710e22d8e1f1d0091df9359f33a776276d_Top_color_silk_grey.png"
        },
        {
          "label": "Vert olive",
          "value": "olive_green",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/f/1/f/4/f1f427bfcfe999309dfb2936994938afe46bae55_Base_color_storke_seda_green.png"
        }
      ]
    },
    {
      "attribute_code": "standing_floating_bc",
      "label": "\u00c0 poser ou suspendu",
      "filter_type": "PopupSelect",
      "options": [
        {
          "label": "A poser",
          "value": "standing",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/5\/5\/8\/15583c9a1c3d8954d6ea6fd1c0197d52573538a9_Drawers_3.png"
        },
        {
          "label": "Suspendu",
          "value": "floating",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/f\/2\/caf2b355d0e6d05a8ee559fd8717c4a9176ae9a8_Drawers_2.png"
        }
      ]
    },
    {
      "attribute_code": "brand_bc",
      "label": "Marque",
      "filter_type": "Text",
      "options": [
        {
          "label": "Storke",
          "value": "storke",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "series_bc",
      "label": "S\u00e9rie",
      "filter_type": "Text",
      "options": [
        {
          "label": "Edge",
          "value": "Edge",
          "swatch": null
        },
        {
          "label": "Ribs",
          "value": "ribs",
          "swatch": null
        },
        {
          "label": "Seda",
          "value": "seda",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "depth_bc",
      "label": "Profondeur",
      "filter_type": "Text",
      "options": [
        {
          "label": "52  cm",
          "value": "52cm",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "color_detail_t",
      "label": "D\u00e9tail couleur",
      "filter_type": "Swatch",
      "options": [
        {
          "label": "Blanc\/noir mat terrazzo",
          "value": "mat_white_black",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/5\/6\/3a567beebebbe560c09c9df2c567f598bb4ff762_Top_color_matt_white.png"
        },
        {
          "label": "Blanc brillant",
          "value": "shiny_white",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/0\/19f086bb84c067aa2288ee0f09781d0ee2e24da0_Top_color_shiny_white.png"
        },
        {
          "label": "Blanc mat",
          "value": "mat_white",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/5\/6\/3a567beebebbe560c09c9df2c567f598bb4ff762_Top_color_matt_white.png"
        },
        {
          "label": "B\u00e9ton gris argent",
          "value": "concrete_silver",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/a\/5\/51a5e8a68d15ecc227c72c1564349bd481945e9a_Top_color_light_cement.png"
        },
        {
          "label": "B\u00e9ton gris fonc\u00e9",
          "value": "concrete_dark_gray",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/2\/0\/7020a9b5244bde87e87710e5faef541700e3d77d_Top_color_concrete_grey.png"
        },
        {
          "label": "Ch\u00eane brut",
          "value": "rough_oak",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/c\/d\/65cd2dd3cf0d45dfbd91b2c5fd5b81e6fc8249cb_Top_color_rough_oak.png"
        },
        {
          "label": "Ch\u00eane fonc\u00e9",
          "value": "dark_oak",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/6\/a\/ff6a12d4c86ee4e466b05e0d027f1032dfd3a60a_Top_color_black_oak.png"
        },
        {
          "label": "Ch\u00eane naturel",
          "value": "natural_oak",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/1/6/e/6/16e6a74be263f7d585ed44a3e4f57d4319208a5f_Top_color_natural_oak.png"
        },
        {
          "label": "Noir mat",
          "value": "mat_black",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/6\/b\/046b98551e50aa8a6ce5022ef6cc466041c9443f_Top_color_matt_black.png"
        },
        {
          "label": "Teck rustique",
          "value": "rustiqueTeak",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/d/6/3/7/d637532f61ebc646578d64f840953676def478b1_Top_color_rustique_teak.png"
        }
      ]
    },
    {
      "attribute_code": "type_basin_t",
      "label": "Type de lavabo",
      "filter_type": "Text",
      "options": [
        {
          "label": "Lavabo",
          "value": "integrated",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/4/8/5/d/485d57874f45cc4374a8069659fa136bcbd02a9a_Config_Washbasin.png"
        },
        {
          "label": "Tablette pour vasques",
          "value": "upmount",
          "swatch": "https://s3.eu-central-1.amazonaws.com/akeneo.x2o.assets.prod/assets/6/5/9/e/659e6751077cd3d7092ed7b15ebd64b3c8486f85_Config_Tablet.png"
        }
      ]
    },
    {
      "attribute_code": "width_t",
      "label": "Largeur",
      "filter_type": "Text",
      "options": [
        {
          "label": "65  cm",
          "value": "65cm",
          "swatch": null
        },
        {
          "label": "75  cm",
          "value": "75cm",
          "swatch": null
        },
        {
          "label": "85  cm",
          "value": "85cm",
          "swatch": null
        },
        {
          "label": "95  cm",
          "value": "95cm",
          "swatch": null
        },
        {
          "label": "100  cm",
          "value": "100cm",
          "swatch": null
        },
        {
          "label": "105  cm",
          "value": "105cm",
          "swatch": null
        },
        {
          "label": "106  cm",
          "value": "106cm",
          "swatch": null
        },
        {
          "label": "110  cm",
          "value": "110cm",
          "swatch": null
        },
        {
          "label": "120  cm",
          "value": "120cm",
          "swatch": null
        },
        {
          "label": "130  cm",
          "value": "130cm",
          "swatch": null
        },
        {
          "label": "135  cm",
          "value": "135cm",
          "swatch": null
        },
        {
          "label": "140  cm",
          "value": "140cm",
          "swatch": null
        },
        {
          "label": "145 cm",
          "value": "145cm",
          "swatch": null
        },
        {
          "label": "150  cm",
          "value": "150cm",
          "swatch": null
        },
        {
          "label": "155 cm",
          "value": "155cm",
          "swatch": null
        },
        {
          "label": "165  cm",
          "value": "165cm",
          "swatch": null
        },
        {
          "label": "170  cm",
          "value": "170cm",
          "swatch": null
        }
      ]
    },
    {
      "attribute_code": "series_t",
      "label": "S\u00e9rie",
      "filter_type": "PopupSelect",
      "options": [
        {
          "label": "Diva",
          "value": "diva",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/1\/e\/341e3b99bd8e8cfadb051ee0e443f7d06e45561b_Top_modal_diva.png"
        },
        {
          "label": "Edge",
          "value": "Edge",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/f\/d\/76fd845a0159bca93ef38976324a5f368cf8f860_x2o_groot.png"
        },
        {
          "label": "Kada",
          "value": "kada",
          "swatch": null
        },
        {
          "label": "Mara",
          "value": "mara",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/1\/1\/dd11433c8a0a26a75c27e76a1825683947c9a402_Top_modal_mara.png"
        },
        {
          "label": "Mata",
          "value": "mata",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/b\/5\/7eb5daddec849a6ce21e00d2ffeffb4d18787253_Top_modal_mata.png"
        },
        {
          "label": "Mata High",
          "value": "mata_high",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/4\/3\/93437f0dfb4998547fbbfe6a8de1a1f9ca330c9e_Top_modal_mata_high.png"
        },
        {
          "label": "Panton",
          "value": "panton",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/5\/a\/f\/d5afe4ad7b9bfa412d829a8b581aca2c4397dade_Top_modal_panton.png"
        },
        {
          "label": "Precioza",
          "value": "precioza",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/a\/b\/60ab1c464ced8704304e594da4862dd11ed31d0d_Top_modal_precioza.png"
        },
        {
          "label": "Scuro",
          "value": "scuro",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/e\/6\/fce61bc8c37e787ce12cacb56b2b23d97158945d_Top_modal_scuro.png"
        },
        {
          "label": "Scuro High",
          "value": "scuro_high",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/1\/2\/3\/d1230f281379653286aaf1c2dc37d955e8d33d25_Top_modal_scuro_high.png"
        },
        {
          "label": "Tabula",
          "value": "tabula",
          "swatch": null
        },
        {
          "label": "Tavola",
          "value": "tavola",
          "swatch": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/a\/e\/43ae93ea483349f140fb1223aafce6b422a813c6_Top_modal_tavola.png"
        }
      ]
    }
  ],
  "items": [
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201250_201245_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201250_201245_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201250_201245_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201208_201207_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201208_201207_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201208_201207_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200253_200249_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200253_200249_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200253_200249_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200084_200079_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200084_200079_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface",
      "sku": "200084_200079_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200084_200078_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200084_200078_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200084_200078_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200064_200060_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200064_200060_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200064_200060_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200039_200041_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200039_200041_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200039_200041_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65547_65549_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65547_65549_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65547_65549_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65539_65541_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65539_65541_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65539_65541_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65521_65523_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65521_65523_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65521_65523_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65521_65523_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65505_65509_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane naturel avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65505_65509_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Tavola tablette simple ou double en solid surface",
      "sku": "65505_65509_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65497_65499_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65497_65499_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65497_65499_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89214_89209_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89214_89209_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89214_89209_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89214_89208_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89214_89208_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89214_89208_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89205_89200_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89205_89200_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89205_89200_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89205_89199_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89205_89199_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89205_89199_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89196_89191_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9",
      "sku": "89196_89191_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89196_89191_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89196_89191_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89196_89190_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89196_89190_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89196_89190_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89196_89190_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89178_89172_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89178_89172_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en solid surface",
      "sku": "89178_89172_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88779_89218_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88779_89218_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88779_89218_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88779_88784_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88779_88784_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88779_88784_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88773_89168_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88773_89168_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88773_89168_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88773_88782_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88773_88782_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88773_88782_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83717_83713_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83717_83713_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83717_83713_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83710_83706_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83710_83706_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83710_83706_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83703_83699_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83703_83699_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83703_83699_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "83703_83699_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83696_83692_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83696_83692_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83696_83692_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201245_201250_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201245_201250_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201245_201250_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201207_201208_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201207_201208_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201207_201208_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200249_200253_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200249_200253_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200249_200253_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200079_200084_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200079_200084_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface",
      "sku": "200079_200084_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200078_200084_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200078_200084_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200078_200084_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200060_200064_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200060_200064_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200060_200064_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200041_200039_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200041_200039_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200041_200039_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65549_65547_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65549_65547_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65549_65547_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65541_65539_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65541_65539_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65541_65539_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65523_65521_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65523_65521_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65523_65521_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65523_65521_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65509_65505_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane naturel avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65509_65505_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Tavola tablette simple ou double en solid surface",
      "sku": "65509_65505_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65499_65497_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65499_65497_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65499_65497_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89218_88779_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89218_88779_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89218_88779_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89209_89214_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89209_89214_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89209_89214_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89208_89214_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89208_89214_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89208_89214_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89200_89205_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89200_89205_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89200_89205_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89199_89205_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89199_89205_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89199_89205_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89191_89196_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9",
      "sku": "89191_89196_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89191_89196_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89191_89196_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89190_89196_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89190_89196_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89190_89196_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89190_89196_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89172_89178_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89172_89178_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en solid surface",
      "sku": "89172_89178_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89168_88773_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89168_88773_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89168_88773_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88784_88779_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88784_88779_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88784_88779_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88782_88773_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88782_88773_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88782_88773_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83713_83717_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83713_83717_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83713_83717_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83706_83710_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83706_83710_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83706_83710_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83699_83703_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83699_83703_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83699_83703_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "83699_83703_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83692_83696_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83692_83696_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83692_83696_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201731_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201731_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201731_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm soie grise avec  tablette simple ou double en hpl",
      "sku": "201731_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201724_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201724_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201724_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm vert olive avec  tablette simple ou double en hpl",
      "sku": "201724_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201717_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201717_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201717_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm bleu nuit avec  tablette simple ou double en hpl",
      "sku": "201717_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91094_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91094_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91094_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm noir mat avec  tablette simple ou double en hpl",
      "sku": "91094_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91091_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91091_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91091_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm blanc mat avec  tablette simple ou double en hpl",
      "sku": "91091_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91088_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "91088_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91088_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91088_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec  tablette simple ou double en hpl",
      "sku": "91088_84271",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/d\/b\/33dbe1af7f5e24da3b67aa03fa7d59f7aef969dc_84271_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "kada",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201301_201301_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201301_201301_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201301_201301_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201247_201247_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201247_201247_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201247_201247_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200251_200251_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200251_200251_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200251_200251_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200251_200251_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200081_200081_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200081_200081_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200081_200081_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200081_200081_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200062_200062_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200062_200062_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200062_200062_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200062_200062_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200037_200037_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200037_200037_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200037_200037_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200037_200037_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65545_65545_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65545_65545_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65545_65545_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 150 x 52  cm noir mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65545_65545_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65537_65537_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65537_65537_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65537_65537_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 150 x 52  cm blanc mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65537_65537_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65519_65519_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65519_65519_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65519_65519_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65519_65519_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65519_65519_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65511_65511_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65511_65511_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en solid surface",
      "sku": "65511_65511_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65511_65511_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65495_65495_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65495_65495_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65495_65495_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65495_65495_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89211_89211_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89211_89211_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89211_89211_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89211_89211_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89202_89202_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89202_89202_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89202_89202_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89202_89202_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89193_89193_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89193_95777",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/4\/6\/bb46b2ac23eaf096da0af1b844f799c90c2538e5_95777_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89193_89193_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89193_89193_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89193_89193_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88777_88777_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88777_88777_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88777_88777_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88777_88777_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88771_88771_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88771_88771_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88771_88771_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88771_88771_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83715_83715_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83715_83715_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83715_83715_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83715_83715_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83708_83708_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83708_83708_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83708_83708_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83708_83708_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83694_83694_201170",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/a\/0\/45a0dea0dc447c41c790593d7e9bb713e4af5d71_201170_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83694_83694_94431",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/1\/8d31567daebecdf6c7011c07d32c1c54120d5718_94431_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83694_83694_65866",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/a\/d\/44adf49457da6671f0eea3f5278b3e12bf8e86c3_65866_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83694_83694_73085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/7\/3\/437374a6ab95f740d7a86eb4884515aa78f2cf7b_73085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "150cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201299_201303_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201299_201303_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201299_201303_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201244_201249_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201244_201249_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201244_201249_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200077_200083_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200077_200083_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200077_200083_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200076_200083_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200076_200083_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200076_200083_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89217_89219_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89217_89219_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89217_89219_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89216_89219_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89216_89219_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89216_89219_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89207_89213_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89207_89213_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89207_89213_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89206_89213_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89206_89213_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89206_89213_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89189_89195_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89189_89195_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89189_89195_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89189_89195_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89188_89195_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89188_89195_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89188_89195_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89188_89195_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89167_89169_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89167_89169_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89167_89169_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89166_89169_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89166_89169_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89166_89169_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201245_201248_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201245_201248_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201245_201248_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201207_201302_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201207_201302_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201207_201302_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200249_200252_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200249_200252_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200249_200252_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200079_200082_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200079_200082_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200079_200082_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200078_200082_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200078_200082_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200078_200082_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200060_200063_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200060_200063_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200060_200063_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200041_200038_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200041_200038_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200041_200038_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65549_65546_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65549_65546_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65549_65546_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65541_65538_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65541_65538_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65541_65538_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65523_65520_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65523_65520_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65523_65520_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65523_65520_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65499_65496_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65499_65496_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65499_65496_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89218_88778_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89218_88778_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89218_88778_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89209_89212_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89209_89212_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89209_89212_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89208_89212_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89208_89212_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89208_89212_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89200_89203_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89200_89203_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89200_89203_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89199_89203_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89199_89203_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89199_89203_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89191_89194_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89191_89194_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89191_89194_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89191_89194_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89190_89194_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89190_89194_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89190_89194_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89190_89194_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89168_88772_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89168_88772_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89168_88772_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88784_88778_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88784_88778_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88784_88778_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88782_88772_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88782_88772_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88782_88772_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83713_83716_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83713_83716_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83713_83716_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83706_83709_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83706_83709_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83706_83709_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83699_83702_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83699_83702_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83699_83702_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "83699_83702_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83692_83695_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83692_83695_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83692_83695_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201302_201207_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201302_201207_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201302_201207_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201248_201245_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201248_201245_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201248_201245_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200252_200249_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200252_200249_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200252_200249_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200082_200079_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200082_200079_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200082_200079_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200082_200078_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200082_200078_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200082_200078_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200063_200060_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200063_200060_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200063_200060_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200038_200041_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200038_200041_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200038_200041_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65546_65549_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65546_65549_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65546_65549_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65538_65541_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65538_65541_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65538_65541_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65520_65523_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65520_65523_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65520_65523_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65520_65523_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65496_65499_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65496_65499_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65496_65499_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89212_89209_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89212_89209_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89212_89209_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89212_89208_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89212_89208_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89212_89208_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89203_89200_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89203_89200_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89203_89200_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89203_89199_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89203_89199_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89203_89199_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89194_89191_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89191_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89194_89191_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89194_89191_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89194_89190_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89190_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89194_89190_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89194_89190_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88778_89218_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88778_89218_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88778_89218_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88778_88784_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88778_88784_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88778_88784_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88772_89168_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88772_89168_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88772_89168_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88772_88782_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88772_88782_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88772_88782_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83716_83713_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83716_83713_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83716_83713_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83709_83706_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83709_83706_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83709_83706_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83695_83692_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83695_83692_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83695_83692_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201303_201299_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201303_201299_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201303_201299_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201249_201244_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201249_201244_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201249_201244_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200083_200077_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200083_200077_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200083_200077_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200083_200076_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200083_200076_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200083_200076_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89219_89217_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89219_89217_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89219_89217_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89219_89216_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89219_89216_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89219_89216_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89213_89207_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89213_89207_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89213_89207_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89213_89206_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89213_89206_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89213_89206_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89195_89189_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89195_89189_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89195_89189_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89195_89189_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89195_89188_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89195_89188_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89195_89188_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89195_89188_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89169_89167_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89169_89167_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89169_89167_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89169_89166_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89169_89166_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89169_89166_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201730_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201730_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201730_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201723_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201723_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201723_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201716_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201716_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201716_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91093_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91093_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91093_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91090_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91090_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91090_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91087_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "91087_95773",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/6\/174683b9e137709b3b9f36b375648c17c85a75bf_95773_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91087_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91087_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201300_201300_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201300_201300_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201300_201300_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201246_201246_201169",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/a\/6\/caa609f5163be7ad13258486c5524a3045f89fdc_201169_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201246_201246_94427",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/9\/a\/e19adf57b2e7efd82eaf91fe650a10e930453267_94427_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201246_201246_65865",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/7\/8\/677881f77dcaf73d09e424f0ac976440ac92bd6e_65865_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "130cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201741_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201741_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Tavola Tablette simple ou double im solid surface",
      "sku": "201741_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201304_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201304_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201304_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201251_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201251_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201251_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201245_201247_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201245_201247_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201245_201247_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201207_201301_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201207_201301_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201207_201301_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200249_200251_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200249_200251_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200249_200251_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200079_200081_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200079_200081_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200079_200081_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200078_200081_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200078_200081_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200078_200081_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200060_200062_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200060_200062_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200060_200062_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200041_200037_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200041_200037_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200041_200037_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65549_65545_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65549_65545_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65549_65545_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65541_65537_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65541_65537_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65541_65537_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65523_65519_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65523_65519_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65523_65519_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65523_65519_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65523_65519_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65515_65511_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65515_65511_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en solid surface",
      "sku": "65515_65511_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65499_65495_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65499_65495_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65499_65495_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89218_88777_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89218_88777_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "89218_88777_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89209_89211_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89209_89211_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89209_89211_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89208_89211_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89208_89211_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89208_89211_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89200_89202_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89200_89202_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89200_89202_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89199_89202_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89199_89202_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89199_89202_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89191_89193_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89191_89193_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89191_89193_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89191_89193_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89191_89193_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89190_89193_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89190_89193_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89190_89193_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89190_89193_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89190_89193_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89168_88771_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89168_88771_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "89168_88771_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88784_88777_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88784_88777_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88784_88777_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88782_88771_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88782_88771_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88782_88771_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83713_83715_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83713_83715_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83713_83715_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83706_83708_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83706_83708_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83706_83708_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83699_83701_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83699_83701_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83699_83701_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "83699_83701_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83699_83701_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83692_83694_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83692_83694_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83692_83694_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201729_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201729_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201729_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm soie grise avec  tablette simple ou double en hpl",
      "sku": "201729_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201722_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201722_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201722_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm vert olive avec  tablette simple ou double en hpl",
      "sku": "201722_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "201715_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "201715_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "201715_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm bleu nuit avec  tablette simple ou double en hpl",
      "sku": "201715_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91092_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91092_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91092_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm noir mat avec  tablette simple ou double en hpl",
      "sku": "91092_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91089_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91089_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91089_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm blanc mat avec  tablette simple ou double en hpl",
      "sku": "91089_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "91086_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "91086_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "91086_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "91086_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec  tablette simple ou double en hpl",
      "sku": "91086_84269",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/0\/7\/44076e33395f2ce36251639cbc8e88fdea3f5e1f_84269_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rustiqueTeak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "kada",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201301_201207_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201301_201207_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201301_201207_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201247_201245_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201247_201245_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201247_201245_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200251_200249_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200251_200249_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200251_200249_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200081_200079_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200081_200079_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200081_200079_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200081_200078_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200081_200078_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200081_200078_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200062_200060_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200062_200060_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200062_200060_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200037_200041_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200037_200041_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200037_200041_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65545_65549_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65545_65549_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65545_65549_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65537_65541_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65537_65541_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65537_65541_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65519_65523_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65519_65523_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65519_65523_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65519_65523_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65519_65523_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65511_65515_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65511_65515_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple ou double en solid surface",
      "sku": "65511_65515_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "65495_65499_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "65495_65499_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65495_65499_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89211_89209_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89211_89209_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89211_89209_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89211_89208_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89211_89208_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89211_89208_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89202_89200_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89202_89200_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89202_89200_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89202_89199_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89202_89199_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89202_89199_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89193_89191_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89191_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89193_89191_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89193_89191_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89191_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89193_89190_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89190_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89193_89190_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89193_89190_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89190_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88777_89218_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88777_89218_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88777_89218_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88777_88784_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88777_88784_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88777_88784_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88771_89168_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88771_89168_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88771_89168_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88771_88782_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88771_88782_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88771_88782_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83715_83713_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83715_83713_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83715_83713_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83708_83706_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83708_83706_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83708_83706_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83694_83692_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83694_83692_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83694_83692_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201302_201299_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201302_201299_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Tavola Tablette simple ou double im solid surface",
      "sku": "201302_201299_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im terrazzo",
      "sku": "201248_201244_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201248_201244_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Tavola Tablette simple ou double im solid surface",
      "sku": "201248_201244_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200252_200248_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200252_200248_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200252_200248_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200082_200077_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200082_200077_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200082_200077_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200082_200076_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200082_200076_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200082_200076_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "200063_200059_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200063_200059_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200063_200059_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89212_89207_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89212_89207_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89212_89207_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89212_89206_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89212_89206_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89212_89206_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89203_89198_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89203_89198_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89203_89198_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89203_89197_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89203_89197_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "89203_89197_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89194_89189_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89189_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89194_89189_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89194_89189_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89189_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "89194_89188_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89188_95772",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/6\/3\/6a63f4224d8717f6fe57b2681b9a9875161b5f86_95772_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89194_89188_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "89194_89188_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_89188_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88778_89217_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88778_89217_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88778_89217_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88778_89216_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88778_89216_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Tavola tablette simple ou double en solid surface",
      "sku": "88778_89216_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88772_89167_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88772_89167_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88772_89167_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "88772_89166_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88772_89166_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "88772_89166_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83716_83712_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83716_83712_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83716_83712_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83709_83705_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83709_83705_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "83709_83705_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en blanc\/noir terrazzo mat",
      "sku": "83695_83691_201168",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/d\/c\/c\/5dcc0870ea4ea44f7a8f3cfb58bc4c3f264597e8_201168_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83695_83691_94426",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/2\/5\/b325e6c1ac2139ef62b4273b85b0ca6d1cdaedf2_94426_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "83695_83691_65864",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/b\/d\/6bbd1a638969e2dbfd291537835c9150699f49fa_65864_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Tavola Tablette simple im terrazzo",
      "sku": "201739_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201739_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Tavola Tablette simple im solid surface",
      "sku": "201739_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201728_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201728_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201728_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201721_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201721_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201721_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201714_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201714_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201714_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Tavola Tablette simple im terrazzo",
      "sku": "201303_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201303_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Tavola Tablette simple im solid surface",
      "sku": "201303_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Tavola Tablette simple im terrazzo",
      "sku": "201249_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201249_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Tavola Tablette simple im solid surface",
      "sku": "201249_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm noyer avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200083_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200083_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200083_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200083_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200083_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noyer avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200057_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200057_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200057_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200057_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200057_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91082_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91082_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm noir mat avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "91082_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm noir mat avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "91082_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91082_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91081_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91081_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm blanc mat avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "91081_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm blanc mat avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "91081_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91081_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91080_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "91080_95768",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/e\/810e8647afa391f26f4187a68b9a8ab32f767617_95768_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91080_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm ch\u00eane brut avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "91080_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 95 x 52 cm ch\u00eane brut avec Edge plan simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "91080_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91080_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89219_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89219_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89219_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89219_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "89219_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89213_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89213_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89213_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89213_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "89213_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89195_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89195_95768",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/e\/810e8647afa391f26f4187a68b9a8ab32f767617_95768_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89195_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89195_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89195_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "89195_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89169_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89169_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89169_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89169_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "89169_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83594_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83594_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83594_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83594_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "83594_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83593_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83593_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83593_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83593_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "83593_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83592_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83592_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83592_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83592_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "83592_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83590_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83590_95768",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/e\/810e8647afa391f26f4187a68b9a8ab32f767617_95768_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83590_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83590_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83590_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "83590_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83587_201167",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/2\/f\/202f17a5f2ff0a39ecf915ffe2034087ca722629_201167_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83587_94422",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/0\/84707c6869c4bf786a611f82aab72bb4b68eb1c4_94422_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83587_83596",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/c\/7\/8fc7ae243c0e9d4abd4627939e96d1a7f165e83e_83596_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83587_83595",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/6\/89d65e84190042fd005783644f407f3716de4e10_83595_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "83587_83573",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/2\/e\/6b2ea069a0d99c563e0321ed56b0d636bcbe54f7_83573_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "95cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Tavola Tablette simple im terrazzo",
      "sku": "201737_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201737_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Tavola Tablette simple im solid surface",
      "sku": "201737_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201727_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201727_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201727_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201720_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201720_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201720_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "201713_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "201713_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Tavola tablette simple en solid surface blanc mat",
      "sku": "201713_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Tavola Tablette simple im terrazzo",
      "sku": "201301_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201301_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Tavola Tablette simple im solid surface",
      "sku": "201301_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Tavola Tablette simple im terrazzo",
      "sku": "201247_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201247_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Tavola Tablette simple im solid surface",
      "sku": "201247_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200251_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200251_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200251_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200251_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200251_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200251_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200081_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200081_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200081_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200081_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200081_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200081_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200062_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200062_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200062_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200062_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200062_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200062_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noyer avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "200037_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200037_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200037_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200037_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200037_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "200037_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91078_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91078_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91078_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91077_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91077_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91077_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91076_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91076_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91076_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "91063_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "91063_95594",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/8\/5\/dd858b062df087115614ea4c540d9e4bfd77e9bb_95594_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "91063_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface blanc mat",
      "sku": "91063_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "65545_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65545_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "65545_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65545_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65545_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "65545_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "65537_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65537_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "65537_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65537_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65537_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "65537_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "65519_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9",
      "sku": "65519_95594",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/8\/5\/dd858b062df087115614ea4c540d9e4bfd77e9bb_95594_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65519_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "65519_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65519_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65519_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "65519_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "65511_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65511_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple en solid surface",
      "sku": "65511_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65511_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65511_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "65511_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "65495_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65495_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "65495_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65495_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65495_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "65495_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89211_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89211_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "89211_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89211_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89211_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89211_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89202_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89202_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "89202_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89202_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89202_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "89202_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "89193_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_95594",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/8\/5\/dd858b062df087115614ea4c540d9e4bfd77e9bb_95594_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89193_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "89193_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89193_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Lucida meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9",
      "sku": "89193_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Lucida meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9",
      "sku": "89193_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "88777_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88777_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "88777_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88777_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "88777_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "88777_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "88771_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88771_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "88771_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88771_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "88771_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "88771_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83715_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83715_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "83715_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83715_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83715_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83715_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83708_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83708_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "83708_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83708_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83708_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83708_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en blanc\/noir terrazzo mat",
      "sku": "83694_201166",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/1\/1\/921185a483d2b5785aefca2432792071ea3745d7_201166_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83694_94261",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/e\/2\/5fe2d09282e19860fbbf783f9986e722aa3d1f0c_94261_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "83694_65861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/a\/6\/2da6e961950ccb0c24228db42e436c0b3a71b2a6_65861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83694_73080",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/9\/d\/219d7b69415bdf28d998b17d827a8f034736db99_73080_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83694_72085",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/e\/445e69dc21064fd88ff2aaf303d80a05bb994f07_72085_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris fonc\u00e9",
      "sku": "83694_71302",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/d\/2\/fdd2b5fb02cf711bba98ebb374ce07f41e8f750f_71302_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_dark_gray",
      "type_basin_t": "upmount",
      "width_t": "75cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201299_201301_201299_94430",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/8\/5\/788597dbe1123a4692a91834312283d34ee42af9_201299_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/2\/b6120cc5a65f15b103b11c538408e54fd2361cc6_94430_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "145cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201244_201247_201244_94430",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/e\/2\/d4e235511ef46a5471ee425ef05562f4d600dce6_201244_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/2\/b6120cc5a65f15b103b11c538408e54fd2361cc6_94430_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "145cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201250_201244_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/2\/75b21f65605a0effcdf12a10c52950416eb27bdd_201250_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201208_201299_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/2\/5\/e325b3769ea0bd42871e3165b1f145d01a403b5e_201208_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200253_200248_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/0\/e\/1\/10e1541cd8d8408839dfd3c80e0d7515f51e56d1_200253_200248_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200084_200077_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/5\/4\/fa548b1e8eabfa5c9d4c53dfcedbd20e2fb8b8e2_200084_200077_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200084_200076_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/b\/64dbb8b471bde4564662f465affa866be43ce457_200084_200076_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200064_200059_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/2\/3\/3e233947e07c626d28ed8d3340007bc150efebac_200064_200059_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89214_89207_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/9\/c\/849c6589c337e12d6015a890497b6380ee89210c_89214_89207_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89214_89206_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/c\/c\/90cc9f0d7d1e8f9958116146d49ee91af6d0f96d_89214_89206_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89205_89198_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/1\/4b316f0520b097247dad9473528a5d4d3f6762c2_89205_89198_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89205_89197_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/a\/0\/c3a0622f33e13adcdfa68a9bdd34c0208dda11ec_89205_89197_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89196_89189_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/6\/2\/f9629dee1ca72bf827e4eafd3be0586abd423b07_89196_89189_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89196_89189_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/6\/2\/f9629dee1ca72bf827e4eafd3be0586abd423b07_89196_89189_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89196_89188_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/c\/41fc39ea98df749e1bdc53b4a2d0544eae6065a9_89196_89188_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89196_89188_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/c\/41fc39ea98df749e1bdc53b4a2d0544eae6065a9_89196_89188_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89178_89170_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/e\/9\/cae9c5141571fa91e3f9afef133b12ca02364d86_89178_89170_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88779_89217_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/6\/f\/006fe865a4832c769a7efbee904bc0dc559bbc1a_88779_89217_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88779_89216_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/c\/819c60fd262ce4e08040c2daad38b747920f7e92_88779_89216_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88773_89167_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/b\/a79b03be0a4bec4d1fb23f956f676ced5d5ff2f0_88773_89167_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "88773_89166_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/8\/9f1819710fd5fe52824644b219f1c236da736d15_88773_89166_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83717_83712_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/9\/d8894ad63bd4098accb4f7fb778442f68a87c24e_83717_83712_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83710_83705_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/9\/6\/589692f791af641ea7b66f16a1871f9e1a1e337a_83710_83705_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83703_83698_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/9\/0\/b490474d462123a53a5ef71eb3d34b483cbbb01c_83703_83698_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83703_83698_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/9\/0\/b490474d462123a53a5ef71eb3d34b483cbbb01c_83703_83698_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83696_83691_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/c\/b61c9944016574b3a58ce8f53e52af553c8b9ff5_83696_83691_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201299_201208_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/f\/a\/4bfaa15fac24ae40019c3b9afb0e63aa551b1c65_201299_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201244_201250_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/8\/e\/0c8e5391945ab3d4a1755f36a811458ccf09ef1c_201244_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200248_200253_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/0\/cc001d491f1a3b61eb5a816e38470016fb93c7c0_200248_200253_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200077_200084_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/d\/2\/1bd22b3122fdf8486314cfb7012210317b4993a6_200077_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200076_200084_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/d\/d\/8eddddcd1f09783a96e5b30ec7af7614c2be5424_200076_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "200059_200064_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/0\/b\/8c0bd021dde4fa3109ba058b4c97c76d9ae6f2d8_200059_200064_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89217_88779_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/b\/0\/a4b0b304e2d272a4aa8d67755765528fb016414e_89217_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89216_88779_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/b\/1e0bda70c073165a859284e6d97945c97960ad9c_89216_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89207_89214_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/3\/6\/5e36aa6a26e7467a0f888e9515ece5431e92421a_89207_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89206_89214_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/2\/d77248a5fd4cae9bd939eddb80254ad275b0810e_89206_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89198_89205_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/0\/6640fcace08104eb24b61ccad77b63e4005cb020_89198_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89197_89205_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/1\/c9d13cd5a563071322643259498955d7ff359ee6_89197_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89189_89196_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/a\/e\/a7ae23a381149876eb4d4297b986a1a016d518ec_89189_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89189_89196_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/a\/e\/a7ae23a381149876eb4d4297b986a1a016d518ec_89189_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89188_89196_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/6\/9\/6769ea58d12b67c4592bf288c2a3b40f2d5e3ac1_89188_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89188_89196_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/6\/9\/6769ea58d12b67c4592bf288c2a3b40f2d5e3ac1_89188_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89170_89178_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/c\/3\/cdc3baf1272bf3ca45ae26f0e03e341a484047b0_89170_89178_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89167_88773_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/1\/c\/271c2f8eb4a8fb01ae4e64f30a5f517fd1e6bf39_89167_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "89166_88773_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/b\/3\/eab37aa5f1a5006a4a9d88a5dc2c4940cd2b3202_89166_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83712_83717_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/6\/9\/4e697b78446e037700f8f0417c51c277ee06e861_83712_83717_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83705_83710_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/f\/aaefe601d35ae60a89162891fd6ff8764dc37cf5_83705_83710_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm ch\u00eane brut avec Panton tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83698_83703_95775",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/f\/6\/e3f63275a2017f896625c45bb357c40162f12367_83698_83703_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/7\/3\/3873af17670c7330b91ad74a109522ecadd067ab_95775_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83698_83703_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/f\/6\/e3f63275a2017f896625c45bb357c40162f12367_83698_83703_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple ou double en mdf enduit de poudre noir mat",
      "sku": "83691_83696_94429",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/0\/c4b090ba920b0feccad23d14ba0534bab105bebe_83691_83696_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/b\/f\/20bf5c9a57330b057cb045f1316b2eff84164c50_94429_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "140cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm noyer mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201299_201300_201299_94428",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/d\/b\/3fdb5c9730781388e8584eb575c410d6af9e4a16_201299_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/0\/a\/360a6c14b29e70f2de289e2683ebc9343d942119_94428_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "135cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm ch\u00eane noir mit Panton Tablette simple ou double im mdf enduit de poudre",
      "sku": "201244_201246_201244_94428",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/4\/5\/b345dc7d3fc6b5d24755fec39d708062a2719a99_201244_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/0\/a\/360a6c14b29e70f2de289e2683ebc9343d942119_94428_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "135cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201299_201301_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/7\/8\/9f78585b31ee941f4ae2f02fe3120b48ed2ad3d6_201299_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201244_201247_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/8\/8\/94883ee602d473e77c8587078863bcf8d0869348_201244_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200248_200251_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/2\/5162c8c3335aae95debc4f51258c6ebfb2e94377_200248_200251_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200077_200081_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/2\/9ed2aa8c501ef24bdfa84155c167e368a16af6ac_200077_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200076_200081_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/b\/7\/6db7b1c5c0465b531ad5d811fea5bc374b5558f5_200076_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200059_200062_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/3\/9\/a739587676250e5cc2220d563745dc01890e61ab_200059_200062_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89217_88777_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/5\/5\/eb55e5290656bb4756a060541e49572215a96d9e_89217_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89216_88777_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/e\/6\/2\/de62c52dcc32f13c8a31ce3c6bcd56d073f0ceab_89216_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89207_89211_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/d\/2\/e5d28fe05b048f5ae60358b7ff825c9b6c6a3492_89207_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89206_89211_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/1\/e\/711e4587323d85ec01212a4ee6fe6c003b13baa5_89206_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89198_89202_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/f\/1\/67f1302c73f3c9de5fd86d490d44d9de867f3cef_89198_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89197_89202_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/3\/2\/8e32e611e68aaa424f2366ab5bdd39ede12fca11_89197_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89189_89193_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/0\/b\/7b0b915eb4fbdb4408851d652be4e1b293425f35_89189_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89189_89193_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/0\/b\/7b0b915eb4fbdb4408851d652be4e1b293425f35_89189_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89188_89193_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/3\/5\/00352dbd8a04c904226eb5b9758e08d2fc12dabc_89188_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89188_89193_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/3\/5\/00352dbd8a04c904226eb5b9758e08d2fc12dabc_89188_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89167_88771_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/7\/c96754caeff4a52bd18aed4e097d51fe61d1d8d6_89167_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89166_88771_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/1\/9\/8\/d198489bd8be4a3ec682d726d61c5a36c0f43e80_89166_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83712_83715_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/d\/3\/a5d336df9d4bb8517dd1a7aae0c36bcfa9cb9f05_83712_83715_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83705_83708_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/3\/c\/f13c8dd17a75f3b9bb4952f51f7e2589aaff693d_83705_83708_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83698_83701_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/d\/2\/62d2a84ece6db17652b9f9cc9fa1cb5e9dd4198c_83698_83701_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83698_83701_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/d\/2\/62d2a84ece6db17652b9f9cc9fa1cb5e9dd4198c_83698_83701_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83691_83694_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/e\/8d3e0c198c08b7c53cbf4a33b4597412bf8c0d4c_83691_83694_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201245_201246_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/1\/3\/da1380971aed640cb314a1a42f02f8de2504d053_201245_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201207_201300_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/8\/d\/ae8d5012a52197ec5b956db63c5cb5c7f8594596_201207_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200249_200250_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/1\/d641333dec1034e75b2835abec1b380bce61d567_200249_200250_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200079_200080_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/b\/3\/37b3b23fc6d7ae8890b34d08a4ccf64345751522_200079_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200078_200080_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/7\/2\/707291a6ac0379823d49de403ec8535b5a0a6033_200078_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200060_200061_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/6\/1\/7e61419f522e9e4e4c9c3b940d68a7ef56e91233_200060_200061_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200041_200036_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/e\/f\/d8efe3ed0ab73131518a663ccbf9c9854870afd5_200041_200036_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65549_65544_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/3\/f\/a53f3df5e3ac0fe5144f9e6c0cfe7ad294e9f66e_65549_65544_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65541_65536_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/d\/f7fd48fca4f5305d0a22bf8600d2229e7bf2daad_65541_65536_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65523_65518_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/6\/7\/11678ed19b1d7954ca64b93ccb361608d1062d7f_65523_65518_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65523_65518_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/6\/7\/11678ed19b1d7954ca64b93ccb361608d1062d7f_65523_65518_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65499_65494_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/0\/9b501afac9c756c8849ad7b4b66c829e0f301a8d_65499_65494_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89218_88776_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/5\/4c856130551b98e25c70e6c15bb8cf5d600602f3_89218_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89209_89210_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/7\/d\/6a7d48ba90ddb3c724fda027a33874bb70453b9c_89209_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89208_89210_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/f\/8\/e2f82314b2e476c062ec4671f2d700b67a47a1eb_89208_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89200_89201_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/0\/2\/930253d4ea38f0b58a0d4b6ae8698de8b0bfbf47_89200_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89199_89201_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/8\/f\/0a8f360ad0fa502670efd27770a64ee0f38e1322_89199_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89191_89192_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/7\/6\/897671e7d97ad4833f354b14953e9cb9a86fd765_89191_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89191_89192_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/7\/6\/897671e7d97ad4833f354b14953e9cb9a86fd765_89191_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89190_89192_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/4\/6\/c846b12ce269b5d911f9aefa0cd6be6cb7c5fd3c_89190_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89190_89192_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/4\/6\/c846b12ce269b5d911f9aefa0cd6be6cb7c5fd3c_89190_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89168_88770_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747049617a5af185b8b951b3c4195b7f682b587_89168_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88784_88776_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/4\/c9d46c89a0576dd348e9929e8692bcf89135ce33_88784_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88782_88770_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/f\/6\/e8f66534664bd497fe4bf65565de1a742a0912bf_88782_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83713_83714_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/9\/afc98fd712744419e3b8c435d1c27756ccbdd037_83713_83714_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83706_83707_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/0\/f\/320f935a08ad033f6c925994a69f20ae547f03c2_83706_83707_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83699_83700_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/d\/3\/12d3f9587550204fa79c1043733fd69f0956be08_83699_83700_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83699_83700_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/d\/3\/12d3f9587550204fa79c1043733fd69f0956be08_83699_83700_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83692_83693_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/f\/819fbe9b15d62eb0ba3d1b0071cb98c17fc1679e_83692_83693_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201300_201207_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201246_201245_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200250_200249_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200080_200079_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200080_200078_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200061_200060_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200036_200041_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65544_65549_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65536_65541_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65518_65523_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65518_65523_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "65494_65499_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89210_89209_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89210_89208_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89201_89200_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89201_89199_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89192_89191_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89192_89191_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89192_89190_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89192_89190_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88776_89218_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88776_88784_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88770_89168_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88770_88782_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83714_83713_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83707_83706_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83700_83699_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83700_83699_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83693_83692_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201301_201299_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/5\/e\/f55ee343058d9af2e7d02ffa077c1eed0612bf4c_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Panton Plan simple im mdf enduit de poudre",
      "sku": "201247_201244_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/4\/f\/df4f03520540b740cdf5c0c7b1b9f812be9b6133_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200251_200248_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/a\/7\/75a7f1d1d92e5d2292b043d072b8518d85432499_200251_200248_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200081_200077_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/3\/2\/35320320f81160b02572e3981292abc2fcb620f2_200081_200077_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200081_200076_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/4\/5\/8845d22b8429e2ccef487449773d93d81629d7a5_200081_200076_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "200062_200059_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/7\/f\/937f629c34f492fc2ad34d8818e50989c67b2aa0_200062_200059_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89211_89207_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c03b07acfdd0903ef6ab3d9befc7782c8a166_89211_89207_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89211_89206_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/c\/d\/61cda3c0081bd7289e241086f87754cae6f4793a_89211_89206_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89202_89198_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/0\/a\/b80ae82e403b02c2af95bbebb06c5fb3b2606e00_89202_89198_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89202_89197_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/b\/c\/bcbc4b141a7b8920fcde46ec265988020f30e507_89202_89197_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89189_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/f\/7\/06f7a7d8c1a8a4206997756a7b75e0f95bfd9cde_89193_89189_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89193_89189_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/f\/7\/06f7a7d8c1a8a4206997756a7b75e0f95bfd9cde_89193_89189_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89193_89188_95771",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/3\/46f34d3e7b895fec28c36fc3f66e08b2280d54a0_89193_89188_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/b\/528b05a436c5c3060f040cb7241c42f631f21300_95771_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "89193_89188_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/3\/46f34d3e7b895fec28c36fc3f66e08b2280d54a0_89193_89188_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88777_89217_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/b\/4\/72b47435eb12a80e36135b26a7f87e55ab9392c1_88777_89217_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88777_89216_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/2\/ddb285284a7bf154f26e2e93105fdcad01f98255_88777_89216_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88771_89167_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/2\/6\/98262a25a1942061a4e504b1f5f12d2fa5c0046d_88771_89167_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "88771_89166_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/e\/5\/14e5c4368dc23734d2456bcf0375fc740bf3cdf0_88771_89166_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83715_83712_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/2\/d642c1a3d848be9df06e7351ee5b8bde6414e4f4_83715_83712_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83708_83705_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/2\/0a420ef1a77fbf5974c2e465faff86605f1e3e9e_83708_83705_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton plan simple en mdf enduit de poudre noir mat",
      "sku": "83694_83691_94425",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/4\/0\/e640dbe51a233ae693fa544ad7e714aef64be01f_83694_83691_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/c\/f\/3ecf48815aad7e3b50ac59b01e72c1ddc1a45a8f_94425_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "110cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201740_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Tavola Tablette simple im solid surface",
      "sku": "201740_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201250_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Tavola Tablette simple im solid surface",
      "sku": "201250_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201208_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Tavola Tablette simple im solid surface",
      "sku": "201208_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200253_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200253_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200253_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "200253_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200084_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200084_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200084_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200064_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200064_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200064_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200039_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200039_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "200039_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65547_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "65547_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65547_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "65547_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65539_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "65539_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65539_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "65539_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65521_95770",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/9\/0\/b5904d7cb9e77408c234569fc7b07cece1e13da8_95770_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65521_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "65521_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65521_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65505_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Tavola tablette simple en solid surface",
      "sku": "65505_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65505_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "65505_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65497_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "65497_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "65497_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89214_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "89214_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89214_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "89214_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89205_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "89205_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89205_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "89205_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89196_95770",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/9\/0\/b5904d7cb9e77408c234569fc7b07cece1e13da8_95770_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89196_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "89196_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89196_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89187_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Tavola tablette simple en solid surface",
      "sku": "89187_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89187_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "89187_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89178_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Tavola tablette simple en solid surface",
      "sku": "89178_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "89178_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88779_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "88779_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88779_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "88779_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88773_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "88773_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "88773_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83717_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "83717_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83717_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "83717_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83710_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "83710_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83710_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane naturel",
      "sku": "83710_72081",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/f\/af9f8c2ebd8bd661f272c51e5a5168ccd76cfb4a_72081_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "natural_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83703_95770",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/9\/0\/b5904d7cb9e77408c234569fc7b07cece1e13da8_95770_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83703_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "83703_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83703_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83696_94424",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/6\/a\/0a6a9f288053d2a88f6a42e92a813f0f5a27e7a0_94424_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "83696_65863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/3\/9\/563928ae4596581c83ad0df9eda9568cb1dd5d6f_65863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 ch\u00eane fonc\u00e9",
      "sku": "83696_73082",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/a\/e\/5aae8adfcf70a561102f71881f4e8f057a67dbee_73082_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "dark_oak",
      "type_basin_t": "upmount",
      "width_t": "105cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201299_201300_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/c\/8\/60c8b568cabb83005a042df2a26926c933daf0f5_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201244_201246_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/0\/1\/8501b375db62e4238b823fcd8a4c8cd941c5165b_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200248_200250_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/9\/7\/1\/49711bf482bd4f024ab8c3e8103deb3d4f0cd71e_200248_200250_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200077_200080_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/b\/b10bd3cadfff8acc953c436ad6ef374fc1eabe70_200077_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200076_200080_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/e\/0\/93e050355772a2572d374b2e4eed0da90a33525c_200076_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200059_200061_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/f\/f\/b3ffe351e86a88fb2e77ed7bac704dfed44b9ff0_200059_200061_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89217_88776_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/e\/ddbe430502c7d936a275599097a49da815618ce1_89217_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89216_88776_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/e\/2\/eae22decc498c46aeb65d0e59447fc0c63bc1144_89216_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89206_89210_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/9\/f\/c89fc70f8b1f3024768aa6938e7978408dec119e_89206_89210_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89198_89201_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/1\/d\/a41d2b54a81e02443f9a80d2a029aa73d9e78b2b_89198_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89197_89201_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/e\/f36ecb4b524508068b92073f9994587b6d9cbf3f_89197_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89189_89192_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/9\/8\/8a98e7dbec16dbd36342cd5cdfa2965887b3d24d_89189_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89189_89192_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/9\/8\/8a98e7dbec16dbd36342cd5cdfa2965887b3d24d_89189_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89188_89192_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/f\/1\/eef106d0c31a045b50bd4c965c0a4f4b1d6d6447_89188_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89188_89192_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/f\/1\/eef106d0c31a045b50bd4c965c0a4f4b1d6d6447_89188_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89167_88770_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/7\/5287ced70a6f0a96e5db3ab78b9fdbd69711e9d1_89167_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89166_88770_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/f\/5\/16f5403217733e30da585271070878fc7bc7943c_89166_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83712_83714_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/0\/0\/8600c05216071bd31015bc459573e65fb072a2ed_83712_83714_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83705_83707_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/5\/e\/d95eeb83256ff7b3228cfe0c87c9a756f6accb94_83705_83707_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83698_83700_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/c\/2\/0fc20293af0566a0b0f6b798e7afc57d25e6ffde_83698_83700_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83698_83700_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/c\/2\/0fc20293af0566a0b0f6b798e7afc57d25e6ffde_83698_83700_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83691_83693_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/d\/b\/b4db4726839a5d08f9e1218be318608d72cc73e6_83691_83693_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201300_201299_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/0\/18503f9f0ceb721779d958c0fc36cde3d10d5bf9_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201246_201244_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/c\/6\/28c66862bfe84cd2aff1c53f3bfa9247e4c99824_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200250_200248_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/9\/4\/f694a833810f1e224089213ae5043d8bd147e3d9_200250_200248_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200080_200077_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/f\/1\/faf1307c37f2752fcd895a54de494a0504d84fa8_200080_200077_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200080_200076_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/a\/819a23093b71e8740db85b9f87979da37395af1a_200080_200076_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200061_200059_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/7\/a36770d4415c0c4a463ca30fd14ddfa6c64b6531_200061_200059_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89210_89207_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/e\/2\/c7e2bfec7f91b613b59f14ccbb74c60d20bba7ff_89210_89207_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89210_89206_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/7\/2\/0a722b841ae53a7ff8a70192ad8c869b99ac647a_89210_89206_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89201_89198_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/3\/c\/ce3cbbb4428fbf5ded54d6a1e5bf108faabdc275_89201_89198_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89201_89197_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/8\/30c882cea76abdb9806dbf7a227bd6bb9e808441_89201_89197_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89192_89189_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/9\/7\/a297d0be7adb3e650c12f01040fcf2094e28ef7e_89192_89189_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89192_89189_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/9\/7\/a297d0be7adb3e650c12f01040fcf2094e28ef7e_89192_89189_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89192_89188_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/1\/0\/1b1068afaf142dacaf7ad964cbd350257642eb96_89192_89188_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89192_89188_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/1\/0\/1b1068afaf142dacaf7ad964cbd350257642eb96_89192_89188_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88776_89217_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/8\/5\/24850be38f793e7294c0c687c98a2aa8b6b668f8_88776_89217_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88776_89216_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/8\/2\/2882fd706340ec5be23072e42c0e0507f390a95e_88776_89216_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88770_89167_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/c\/7\/b3c7987dd04be9d59c5984acb7c807c7475c6051_88770_89167_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88770_89166_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/d\/6\/e0d6b66fbef4aff52ba9259ed661299f7a29f2f1_88770_89166_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83714_83712_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/3\/1\/7431b769c2cec24647afd3a7e396716bdbb4ed10_83714_83712_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83707_83705_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/1\/e\/321e7376a8cfbbe96454a76de8a5990ad1979564_83707_83705_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83700_83698_95769",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/b\/a\/9\/aba9e50e67873ec7eea7b2b5e30eec968385009d_83700_83698_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/2\/c\/d02c66e326438747b339224827ad266c9a6bafac_95769_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83700_83698_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/b\/a\/9\/aba9e50e67873ec7eea7b2b5e30eec968385009d_83700_83698_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83693_83691_94423",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/5\/5\/6655fdff1acbf082c2cecc81516f760bc86f2dd6_83693_83691_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/4\/0\/f\/540f8e6a6696f4d86e0b5adb5dc068ffb266f727_94423_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "100cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201738_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Tavola Tablette simple im solid surface",
      "sku": "201738_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201302_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Tavola Tablette simple im solid surface",
      "sku": "201302_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Panton Tablette simple im mdf enduit de poudre",
      "sku": "201248_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Tavola Tablette simple im solid surface",
      "sku": "201248_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200252_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200252_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200252_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200082_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200082_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200082_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200063_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200063_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200063_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noyer avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "200038_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200038_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "200038_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65546_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "65546_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 85 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65546_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65538_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "65538_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 85 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65538_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Panton plan simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65520_95767",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/1\/9\/3719f36055fd7311b62893d4915ee3efdca5ca9b_95767_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65520_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "65520_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65520_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "65496_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "65496_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "65496_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89212_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "89212_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89212_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89203_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "89203_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89203_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm ch\u00eane brut avec Panton tablette simple en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "89194_95767",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/1\/9\/3719f36055fd7311b62893d4915ee3efdca5ca9b_95767_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89194_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "89194_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89194_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "89185_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Tavola tablette simple en solid surface",
      "sku": "89185_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "89185_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88778_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "88778_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "88778_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "88772_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "88772_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "88772_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83716_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "83716_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm noir mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83716_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83709_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "83709_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm blanc mat avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83709_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Panton tablette simple en mdf enduit de poudre noir mat",
      "sku": "83695_94421",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/0\/9\/920968d7b0db9418d152800da6722d31ed4d883d_94421_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "panton"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "83695_65862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/3\/8\/9\/6389b8aa6c0f9f0b2b6abe4f0cacb715a03827b6_65862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Edge tablette simple en m\u00e9lamin\u00e9 b\u00e9ton gris argent",
      "sku": "83695_72086",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/3\/b103430df75db222de6bb4dc6621514f6dc45cc6_72086_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "concrete_silver",
      "type_basin_t": "upmount",
      "width_t": "85cm",
      "series_t": "Edge",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200038_200038_66059",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/c\/f\/16cfe56c9da82a2789d3dbb4b7e2152bd0fa8602_200038_200038_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/6\/7\/fe673cee46c3452a5502d91d3b3ea9dd3b459b89_66059_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "65546_65546_66059",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/4\/d\/744d21cf933a4455f8a5fefe4306ecd77ca0f948_65546_65546_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/6\/7\/fe673cee46c3452a5502d91d3b3ea9dd3b459b89_66059_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "65538_65538_66059",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/1\/2\/4d12a167a4e086edb37d771be31af6e173880368_65538_65538_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/6\/7\/fe673cee46c3452a5502d91d3b3ea9dd3b459b89_66059_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "65520_65520_66059",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/7\/f\/917f11c20a9ae4403d4872e6efd1c9ba906ddcda_65520_65520_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/6\/7\/fe673cee46c3452a5502d91d3b3ea9dd3b459b89_66059_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "65496_65496_66059",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/2\/7\/5b2786a98399fab01baa669a99ec0456709480b6_65496_65496_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/6\/7\/fe673cee46c3452a5502d91d3b3ea9dd3b459b89_66059_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Scuro double vasque en quartz mat",
      "sku": "201731_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Scuro double vasque en quartz mat",
      "sku": "201724_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Scuro double vasque en quartz mat",
      "sku": "201717_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro double vasque en quartz mat",
      "sku": "91094_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro double vasque en quartz mat",
      "sku": "91091_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz mat",
      "sku": "91088_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Scuro Double vasque im quartz",
      "sku": "201301_201301_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Scuro Double vasque im quartz",
      "sku": "201247_201247_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro double vasque en quartz mat",
      "sku": "200251_200251_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200081_200081_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200062_200062_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200037_200037_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "65545_65545_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "65537_65537_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "65519_65519_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Scuro double vasque en quartz",
      "sku": "65511_65511_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "65495_65495_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "89211_89211_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "89202_89202_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "89193_89193_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro double vasque en quartz",
      "sku": "88777_88777_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "88771_88771_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "83715_83715_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "83708_83708_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "83694_83694_66058",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/d\/c\/05dc56302ab009968933c3aa5deb67de95ee3cb5_66058_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Scuro double vasque en quartz mat",
      "sku": "201730_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Scuro double vasque en quartz mat",
      "sku": "201723_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Scuro double vasque en quartz mat",
      "sku": "201716_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro double vasque en quartz mat",
      "sku": "91093_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro double vasque en quartz mat",
      "sku": "91090_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz mat",
      "sku": "91087_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro Double vasque im quartz",
      "sku": "201300_201300_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro Double vasque im quartz",
      "sku": "201246_201246_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro double vasque en quartz mat",
      "sku": "200250_200250_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/c\/0\/2ec01e2c5c4e406c81fbe103f1a472de777bf193_200250_200250_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200080_200080_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/b\/e\/cbbe5866bd8cf7dc7e68a341a1f1ecf8ded5fb0c_200080_200080_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200061_200061_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/e\/9\/97e99682c57d39a2ff7e1dca5cb74b0331528eaf_200061_200061_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200036_200036_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/8\/c\/818c22186377536c187c6baf315f2ad5f50f74db_200036_200036_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "65544_65544_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/f\/a36fc6f429a665acb2e46b0017edb4bb4b26fe84_65544_65544_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "65536_65536_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/a\/7\/6aa71c2d49e4bca474941a8d594f079ac07c399e_65536_65536_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "65518_65518_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/1\/3\/c913e08f0fb7f0df9b1ebfebb26f44523e9dde48_65518_65518_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "65494_65494_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/5\/7\/fe57e214b2f9d4d3e8cd18136019a39179941ea2_65494_65494_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "89210_89210_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/1\/5\/ce150d23dad09631d33578fb3b30b7714f56a6d0_89210_89210_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "89201_89201_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/e\/6\/57e6e725991627b53224f3ac477e7c0424ec364d_89201_89201_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "89192_89192_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/d\/6\/9bd62dc2fe25c3662e4641e9d2228507dc826f9b_89192_89192_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane naturel avec Scuro double vasque en quartz",
      "sku": "89183_89183_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/d\/5\/f0d5ddd2e88db6b11a4ff7ea3ed615cd5dca85bd_89183_89183_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro double vasque en quartz",
      "sku": "88776_88776_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/9\/9\/0e9905041c386d732fd200f3dbc0c1968ed3b370_88776_88776_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "88770_88770_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/a\/0\/59a02504eaf6b87d503115461e43f9df5aac37fd_88770_88770_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "83714_83714_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/f\/6\/6\/1f66a83f29595b19bd8ad798f330d8c3edf48dfa_83714_83714_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "83707_83707_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/d\/1\/5ed1d67722c97a6106525598b5714cda0b149490_83707_83707_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "83700_83700_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/f\/f\/f9ff4e896d5ca2758dc79a79ead88165699b64ee_83700_83700_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "83693_83693_66057",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/2\/7\/3727705d589a411debef4449e5ab7c2353cf0a24_83693_83693_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/9\/c\/f19c05be0f82514e707a9975fe093affe00b6e71_66057_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Scuro Double vasque im quartz",
      "sku": "201741_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro Double vasque im quartz",
      "sku": "201304_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro Double vasque im quartz",
      "sku": "201251_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro double vasque en quartz mat",
      "sku": "200254_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/c\/d\/64cda44abd769a1f23845f562b65e9f6945b36ec_200254_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro double vasque en quartz mat",
      "sku": "200034_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/5\/c\/5\/d5c594cce623190d10d7bbeb8f800c484eb89afd_200034_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble noir mat 120cm",
      "sku": "65548_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/b\/9\/97b9d19293513c1f15109a85b9ee40e21eb7c793_65548_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble blanc mat 120cm",
      "sku": "65540_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/6\/83c683c928e14542c67ce81127d934280ac4105a_65540_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 120cm",
      "sku": "65522_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/7\/e\/867e202528bafd14a8587ea88583135ac7b0dfdf_65522_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble b\u00e9ton gris argent\u00e9 120cm",
      "sku": "65514_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/a\/a\/16aa0df738e55b135752761ff07bb6c6a4341120_65514_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble gris b\u00e9ton 120cm",
      "sku": "65498_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/8\/6\/f18691599bfcd0952c4bb698efbf090ed690507d_65498_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "65488_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/3\/9\/5c39be9f4b06691def373893b5cfab7a91221a23_65488_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "65481_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/a\/1\/74a1720634385f179bfe9cd1fff53521e18b7e62_65481_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "65426_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/2\/46f244e806de0299e171fb62075942ebb22d2326_65426_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris argent avec Scuro double vasque en quartz",
      "sku": "65417_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/b\/f\/eebfd99ddb1fb14a55c3601ae741cdab1bacb9b7_65417_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane naturel avec Scuro double vasque en quartz",
      "sku": "62102_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/0\/b\/d20b408e25db8ea6e5db74259bba8a0d0ba188f2_62102_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro double vasque en quartz",
      "sku": "88781_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/b\/a\/67badeca73b946aa93bcb4027169c4597443540a_88781_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "88774_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/2\/4b5216726dc33041ea40a94f552a01506a2fd183_88774_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "83718_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/1\/3\/6713f6c79d2c057ad6b86981a9dc19f37d637eae_83718_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "83711_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/3\/d\/fd3df1b837e975785d4094b6a0ed1501420df4db_83711_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "83704_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "83697_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/2\/b\/9d2b409f4e965999ddf445b74a219d8cdf0f4267_83697_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Scuro double vasque en quartz mat",
      "sku": "201729_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Scuro double vasque en quartz mat",
      "sku": "201722_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Scuro double vasque en quartz mat",
      "sku": "201715_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro double vasque en quartz mat",
      "sku": "91092_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro double vasque en quartz mat",
      "sku": "91089_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz mat",
      "sku": "91086_66056",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/d\/a\/c3da6ee27b2ac47feb7f3442dae9c8cf698bf8df_66056_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Scuro Plan simple im quartz",
      "sku": "201740_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201250_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201208_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Scuro plan simple en quartz mat",
      "sku": "200253_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200084_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200064_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200039_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "65547_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "65539_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "65521_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Scuro plan simple en quartz",
      "sku": "65505_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "65497_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "89214_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "89205_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "89196_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Scuro plan simple en quartz",
      "sku": "89187_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Scuro plan simple en quartz",
      "sku": "89178_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "88779_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "88773_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "83717_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "83710_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "83703_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "83696_66055",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/3\/7\/773786593e571618a8cce38a6bbf6ecdd8a5545b_66055_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Scuro Plan simple im quartz",
      "sku": "201738_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201302_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201248_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Scuro plan simple en quartz mat",
      "sku": "200252_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200082_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200063_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200038_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "65546_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "65538_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "65520_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "65496_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "89212_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "89203_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "89194_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Scuro plan simple en quartz",
      "sku": "89185_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "88778_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "88772_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "83716_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "83709_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "83695_66054",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/3\/5163f9f98619373fd18657ff46e4a9474212fa34_66054_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Scuro Plan simple im quartz",
      "sku": "201737_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Scuro plan simple en quartz mat",
      "sku": "201727_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Scuro plan simple en quartz mat",
      "sku": "201720_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Scuro plan simple en quartz mat",
      "sku": "201713_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201301_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201247_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Scuro plan simple en quartz mat",
      "sku": "200251_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200081_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200062_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200037_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro plan simple en quartz mat",
      "sku": "91078_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro plan simple en quartz mat",
      "sku": "91077_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz mat",
      "sku": "91076_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz mat",
      "sku": "91063_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "65545_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "65537_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "65519_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Scuro plan simple en quartz",
      "sku": "65511_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "65495_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "89211_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "89202_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "89193_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "88777_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "88771_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "83715_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "83708_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "83694_66053",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/d\/29dd0d5dbf8097cb1f150c9183f48dc2be33e5fb_66053_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Scuro Plan simple im quartz",
      "sku": "201736_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201300_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201246_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Scuro plan simple en quartz mat",
      "sku": "200250_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noyer avec Scuro plan simple en quartz",
      "sku": "200080_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200061_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noyer avec Scuro plan simple en quartz mat",
      "sku": "200036_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "65544_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "65536_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "65518_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "65494_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "89210_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "89201_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "89192_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Scuro plan simple en quartz",
      "sku": "89183_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "88776_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "88770_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "83714_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "83707_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "83700_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "83693_66052",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/c\/3\/ccc3e5d3bda3e1689e0f00d1ff75d55afb2751ee_66052_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200038_200038_66032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/c\/f\/16cfe56c9da82a2789d3dbb4b7e2152bd0fa8602_200038_200038_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/b\/8\/01b8c0e1f8e2f7f35611b76788885297f2e8622c_66032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52 cm noyer avec Mata double vasque en",
      "sku": "200038_200038_72263",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/c\/f\/16cfe56c9da82a2789d3dbb4b7e2152bd0fa8602_200038_200038_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/5\/3\/dc5374096cbbc46c49c0be36b2cbd958b1c4998d_72263_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "65546_65546_66032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/4\/d\/744d21cf933a4455f8a5fefe4306ecd77ca0f948_65546_65546_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/b\/8\/01b8c0e1f8e2f7f35611b76788885297f2e8622c_66032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "65546_65546_72263",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/4\/d\/744d21cf933a4455f8a5fefe4306ecd77ca0f948_65546_65546_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/5\/3\/dc5374096cbbc46c49c0be36b2cbd958b1c4998d_72263_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "65538_65538_66032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/1\/2\/4d12a167a4e086edb37d771be31af6e173880368_65538_65538_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/b\/8\/01b8c0e1f8e2f7f35611b76788885297f2e8622c_66032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "65538_65538_72263",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/1\/2\/4d12a167a4e086edb37d771be31af6e173880368_65538_65538_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/5\/3\/dc5374096cbbc46c49c0be36b2cbd958b1c4998d_72263_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "65520_65520_66032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/7\/f\/917f11c20a9ae4403d4872e6efd1c9ba906ddcda_65520_65520_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/b\/8\/01b8c0e1f8e2f7f35611b76788885297f2e8622c_66032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "65520_65520_72263",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/7\/f\/917f11c20a9ae4403d4872e6efd1c9ba906ddcda_65520_65520_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/5\/3\/dc5374096cbbc46c49c0be36b2cbd958b1c4998d_72263_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "65496_65496_66032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/2\/7\/5b2786a98399fab01baa669a99ec0456709480b6_65496_65496_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/b\/8\/01b8c0e1f8e2f7f35611b76788885297f2e8622c_66032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "65496_65496_72263",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/2\/7\/5b2786a98399fab01baa669a99ec0456709480b6_65496_65496_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/5\/3\/dc5374096cbbc46c49c0be36b2cbd958b1c4998d_72263_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Mata double vasque en solid surface blanc mat",
      "sku": "201731_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Mata double vasque en solid surface blanc mat",
      "sku": "201724_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Mata double vasque en solid surface blanc mat",
      "sku": "201717_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91094_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91091_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface blanc mat",
      "sku": "91088_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Mata Double vasque im solid surface",
      "sku": "201301_201301_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Mata Double vasque im solid surface",
      "sku": "201247_201247_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Mata double vasque en solid surface blanc mat",
      "sku": "200251_200251_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200081_200081_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200062_200062_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200037_200037_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "65545_65545_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "65537_65537_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "65519_65519_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Mata double vasque en solid surface",
      "sku": "65511_65511_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "65495_65495_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "89211_89211_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "89202_89202_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "89193_89193_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata double vasque en solid surface",
      "sku": "88777_88777_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "88771_88771_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "83715_83715_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "83708_83708_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "83694_83694_66031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/2\/1\/1e219da9d7d9970fd842392d7f8e31abfdceb24a_66031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Mata double vasque en solid surface blanc mat",
      "sku": "201730_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Mata double vasque en solid surface blanc mat",
      "sku": "201723_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Mata double vasque en solid surface blanc mat",
      "sku": "201716_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91093_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91090_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface blanc mat",
      "sku": "91087_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata Double vasque im solid surface",
      "sku": "201300_201300_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata Double vasque im solid surface",
      "sku": "201246_201246_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Mata double vasque en solid surface blanc mat",
      "sku": "200250_200250_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/c\/0\/2ec01e2c5c4e406c81fbe103f1a472de777bf193_200250_200250_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Mata double vasque en solid surface",
      "sku": "200080_200080_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/b\/e\/cbbe5866bd8cf7dc7e68a341a1f1ecf8ded5fb0c_200080_200080_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200061_200061_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/e\/9\/97e99682c57d39a2ff7e1dca5cb74b0331528eaf_200061_200061_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200036_200036_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/8\/c\/818c22186377536c187c6baf315f2ad5f50f74db_200036_200036_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "65544_65544_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/f\/a36fc6f429a665acb2e46b0017edb4bb4b26fe84_65544_65544_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "65536_65536_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/a\/7\/6aa71c2d49e4bca474941a8d594f079ac07c399e_65536_65536_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "65518_65518_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/1\/3\/c913e08f0fb7f0df9b1ebfebb26f44523e9dde48_65518_65518_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "65494_65494_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/5\/7\/fe57e214b2f9d4d3e8cd18136019a39179941ea2_65494_65494_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "89210_89210_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/1\/5\/ce150d23dad09631d33578fb3b30b7714f56a6d0_89210_89210_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "89201_89201_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/e\/6\/57e6e725991627b53224f3ac477e7c0424ec364d_89201_89201_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "89192_89192_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/d\/6\/9bd62dc2fe25c3662e4641e9d2228507dc826f9b_89192_89192_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane naturel avec Mata double vasque en solid surface",
      "sku": "89183_89183_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/d\/5\/f0d5ddd2e88db6b11a4ff7ea3ed615cd5dca85bd_89183_89183_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata double vasque en solid surface",
      "sku": "88776_88776_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/9\/9\/0e9905041c386d732fd200f3dbc0c1968ed3b370_88776_88776_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "88770_88770_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/a\/0\/59a02504eaf6b87d503115461e43f9df5aac37fd_88770_88770_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "83714_83714_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/f\/6\/6\/1f66a83f29595b19bd8ad798f330d8c3edf48dfa_83714_83714_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "83707_83707_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/d\/1\/5ed1d67722c97a6106525598b5714cda0b149490_83707_83707_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "83700_83700_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/f\/f\/f9ff4e896d5ca2758dc79a79ead88165699b64ee_83700_83700_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "83693_83693_66030",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/2\/7\/3727705d589a411debef4449e5ab7c2353cf0a24_83693_83693_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/7\/4\/a\/174a7528a7ea537155c4eb70e28448aa9ac3fc02_66030_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Mata double vasque en solid surface blanc mat",
      "sku": "201729_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Mata double vasque en solid surface blanc mat",
      "sku": "201722_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Mata double vasque en solid surface blanc mat",
      "sku": "201715_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91092_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata double vasque en solid surface blanc mat",
      "sku": "91089_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface blanc mat",
      "sku": "91086_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Mata Double vasque im solid surface",
      "sku": "201741_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata Double vasque im solid surface",
      "sku": "201304_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata Double vasque im solid surface",
      "sku": "201251_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata double vasque en solid surface blanc mat",
      "sku": "200254_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/c\/d\/64cda44abd769a1f23845f562b65e9f6945b36ec_200254_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata double vasque en solid surface blanc mat",
      "sku": "200034_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/5\/c\/5\/d5c594cce623190d10d7bbeb8f800c484eb89afd_200034_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble noir mat 120cm",
      "sku": "65548_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/b\/9\/97b9d19293513c1f15109a85b9ee40e21eb7c793_65548_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble blanc mat 120cm",
      "sku": "65540_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/6\/83c683c928e14542c67ce81127d934280ac4105a_65540_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 120cm",
      "sku": "65522_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/7\/e\/867e202528bafd14a8587ea88583135ac7b0dfdf_65522_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble b\u00e9ton gris argent\u00e9 120cm",
      "sku": "65514_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/a\/a\/16aa0df738e55b135752761ff07bb6c6a4341120_65514_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble gris b\u00e9ton 120cm",
      "sku": "65498_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/8\/6\/f18691599bfcd0952c4bb698efbf090ed690507d_65498_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "65488_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/3\/9\/5c39be9f4b06691def373893b5cfab7a91221a23_65488_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "65481_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/a\/1\/74a1720634385f179bfe9cd1fff53521e18b7e62_65481_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "65426_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/2\/46f244e806de0299e171fb62075942ebb22d2326_65426_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris argent avec Mata double vasque en solid surface",
      "sku": "65417_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/b\/f\/eebfd99ddb1fb14a55c3601ae741cdab1bacb9b7_65417_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane naturel avec Mata double vasque en solid surface",
      "sku": "62102_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/0\/b\/d20b408e25db8ea6e5db74259bba8a0d0ba188f2_62102_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata double vasque en solid surface",
      "sku": "88781_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/b\/a\/67badeca73b946aa93bcb4027169c4597443540a_88781_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "88774_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/2\/4b5216726dc33041ea40a94f552a01506a2fd183_88774_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "83718_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/1\/3\/6713f6c79d2c057ad6b86981a9dc19f37d637eae_83718_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "83711_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/3\/d\/fd3df1b837e975785d4094b6a0ed1501420df4db_83711_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "83704_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "83697_66029",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/2\/b\/9d2b409f4e965999ddf445b74a219d8cdf0f4267_83697_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/9\/8\/859816659d02f05797d492e84ed57e8c58fcd4ad_66029_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201738_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201738_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201302_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201302_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201248_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201248_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Mata plan simple en solid surface blanc mat",
      "sku": "200252_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Mata plan simple en",
      "sku": "200252_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200082_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata plan simple en",
      "sku": "200082_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200063_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata plan simple en",
      "sku": "200063_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200038_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Mata plan simple en",
      "sku": "200038_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65546_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65546_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65538_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65538_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65520_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65520_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65496_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65496_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89212_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89212_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89203_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89203_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89194_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89194_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "89185_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "89185_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88778_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88778_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88772_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88772_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83716_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83716_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83709_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83709_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83695_66028",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/f\/0\/c2f059575b4130470562db8b1ffe7a23a20f9c70_66028_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83695_72258",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/0\/d3006ea350430a97fd0cddd63a4606b17d4f6180_72258_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201737_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Mata plan simple en solid surface blanc mat",
      "sku": "201727_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Mata plan simple en solid surface blanc mat",
      "sku": "201720_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Mata plan simple en solid surface blanc mat",
      "sku": "201713_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201301_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201247_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Mata plan simple en solid surface blanc mat",
      "sku": "200251_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200081_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200062_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200037_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata plan simple en solid surface blanc mat",
      "sku": "91078_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata plan simple en solid surface blanc mat",
      "sku": "91077_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface blanc mat",
      "sku": "91076_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface blanc mat",
      "sku": "91063_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65545_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65537_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65519_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Mata plan simple en solid surface",
      "sku": "65511_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65495_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89211_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89202_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89193_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88777_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88771_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83715_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83708_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83694_66027",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/c\/6\/78c661e5de4c109036eb991e4c3a34b2a5c2c077_66027_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201736_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201736_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201300_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201300_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201246_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201246_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm blanc brillant avec Mata plan simple en solid surface blanc mat",
      "sku": "200250_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm blanc brillant avec Mata plan simple en",
      "sku": "200250_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200080_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata plan simple en",
      "sku": "200080_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200061_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata plan simple en",
      "sku": "200061_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52 cm noyer avec Mata plan simple en solid surface blanc mat",
      "sku": "200036_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52 cm noyer avec Mata plan simple en",
      "sku": "200036_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65544_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65544_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65536_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65536_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65518_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65518_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65494_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65494_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89210_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89210_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89201_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89201_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89192_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89192_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "89183_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "89183_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88776_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88776_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88770_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88770_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83714_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83714_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83707_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83707_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "83700_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "83700_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83693_66026",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/f\/7\/39f73d8d62690f7e60f9032da998aff4eeedece0_66026_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83693_72256",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/a\/0\/fda0d1fa19a1aa8e91beabdf0297a8847724c83f_72256_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noyer avec Tavola tablette simple ou double en solid surface blanc mat",
      "sku": "200038_200038_65867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/c\/f\/16cfe56c9da82a2789d3dbb4b7e2152bd0fa8602_200038_200038_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/5\/6\/3256887f1f878d8e23436384f057c3e663c428d4_65867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "170cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noir mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65546_65546_65867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/4\/d\/744d21cf933a4455f8a5fefe4306ecd77ca0f948_65546_65546_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/5\/6\/3256887f1f878d8e23436384f057c3e663c428d4_65867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "170cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm blanc mat avec Tavola tablette simple ou double en solid surface",
      "sku": "65538_65538_65867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/1\/2\/4d12a167a4e086edb37d771be31af6e173880368_65538_65538_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/5\/6\/3256887f1f878d8e23436384f057c3e663c428d4_65867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "170cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm ch\u00eane brut avec Tavola tablette simple ou double en solid surface",
      "sku": "65520_65520_65867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/7\/f\/917f11c20a9ae4403d4872e6efd1c9ba906ddcda_65520_65520_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/5\/6\/3256887f1f878d8e23436384f057c3e663c428d4_65867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "170cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple ou double en solid surface",
      "sku": "65496_65496_65867",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/2\/7\/5b2786a98399fab01baa669a99ec0456709480b6_65496_65496_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/5\/6\/3256887f1f878d8e23436384f057c3e663c428d4_65867_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "170cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Tavola Tablette simple im solid surface",
      "sku": "201736_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Tavola Tablette simple im solid surface",
      "sku": "201300_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Tavola Tablette simple im solid surface",
      "sku": "201246_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm blanc brillant avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200250_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200080_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200061_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52 cm noyer avec Tavola tablette simple en solid surface blanc mat",
      "sku": "200036_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "65544_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "65536_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "65518_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "65494_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "89210_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "89201_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "89192_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Tavola tablette simple en solid surface",
      "sku": "89183_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Tavola tablette simple en solid surface",
      "sku": "88776_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "88770_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Tavola tablette simple en solid surface",
      "sku": "83714_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Tavola tablette simple en solid surface",
      "sku": "83707_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Tavola tablette simple en solid surface",
      "sku": "83700_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Tavola tablette simple en solid surface",
      "sku": "83693_65860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/2\/e\/d72e9180228022f6cd1d25ad433fd7dee46fb3e6_65860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "upmount",
      "width_t": "65cm",
      "series_t": "tavola"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 85cm",
      "sku": "65520_65821",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/9\/1\/609186f1abd895b00f724e4c109e080157bf01b1_65821_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mara",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201250_201245_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201208_201207_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200253_200249_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200084_200079_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200084_200078_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200064_200060_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200039_200041_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52,5  cm noir mat avec Mata vasque asym\u00e9trique gauche en Solid Surface mat",
      "sku": "65547_65549_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65539_65541_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65521_65523_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65505_65509_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65497_65499_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89209_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89208_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89200_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89199_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89191_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89190_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89178_89172_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_89218_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_88784_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_89168_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_88782_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83717_83713_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83710_83706_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83703_83699_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83696_83692_65799",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/e\/5\/9ce56726e3e528b2b82938ef645d6d858a9684c3_65799_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201302_201207_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201248_201245_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200252_200249_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200082_200079_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200082_200078_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200063_200060_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200038_200041_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65546_65549_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65538_65541_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65496_65499_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89209_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89208_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89200_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89199_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89191_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89190_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_89218_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_88784_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_89168_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_88782_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83716_83713_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83709_83706_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83695_83692_65798",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/a\/2e6acce8b35d168ee2e573c3df96db2e6cc6432a_65798_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201301_201207_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201247_201245_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200251_200249_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200081_200079_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200081_200078_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200062_200060_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200037_200041_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65545_65549_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65537_65541_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65519_65523_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65511_65515_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65495_65499_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89209_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89208_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89200_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89199_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89191_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89190_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_89218_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_88784_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_89168_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_88782_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83715_83713_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83708_83706_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83694_83692_65797",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/6645dbcdf0d953509c04b1828e29b14ff50fb28f_65797_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Mata Lavabo simple pour 2 trous de robinet im solid surface",
      "sku": "201740_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Mata Lavabo simple pour 2 trous de robinet im solid surface",
      "sku": "201250_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Mata Lavabo simple pour 2 trous de robinet im solid surface",
      "sku": "201208_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble noir mat 105cm",
      "sku": "65547_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge composition de meuble blanc mat 105cm",
      "sku": "65539_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 105cm",
      "sku": "65521_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane naturel 105cm",
      "sku": "65505_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble gris b\u00e9ton 105cm",
      "sku": "65497_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Mata lavabo simple pour 2 trous de robinet en solid surface blanc mat",
      "sku": "88779_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Mata lavabo simple pour 2 trous de robinet en solid surface blanc mat",
      "sku": "83717_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Mata lavabo simple pour 2 trous de robinet en solid surface blanc mat",
      "sku": "83710_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Mata lavabo simple pour 2 trous de robinet en solid surface blanc mat",
      "sku": "83703_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata lavabo simple pour 2 trous de robinet en solid surface blanc mat",
      "sku": "83696_65796",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/f\/6\/c\/5f6c667254911a3e8948f2fb5ce889231c4032e7_65796_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52 cm noyer avec Diva double vasque en",
      "sku": "200038_200038_87629",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/c\/f\/16cfe56c9da82a2789d3dbb4b7e2152bd0fa8602_200038_200038_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/7\/0587729d5ecfb90db0c8ab0d5abb687a4db3e0b3_87629_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "65546_65546_87629",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/4\/d\/744d21cf933a4455f8a5fefe4306ecd77ca0f948_65546_65546_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/7\/0587729d5ecfb90db0c8ab0d5abb687a4db3e0b3_87629_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "65538_65538_87629",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/1\/2\/4d12a167a4e086edb37d771be31af6e173880368_65538_65538_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/7\/0587729d5ecfb90db0c8ab0d5abb687a4db3e0b3_87629_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "65520_65520_87629",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/7\/f\/917f11c20a9ae4403d4872e6efd1c9ba906ddcda_65520_65520_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/7\/0587729d5ecfb90db0c8ab0d5abb687a4db3e0b3_87629_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 170 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "65496_65496_87629",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/f\/c\/47fcc7e864128e98d000adebed19a2506f6b6b41_1700_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/2\/7\/5b2786a98399fab01baa669a99ec0456709480b6_65496_65496_tw1700tc850_850tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/7\/0587729d5ecfb90db0c8ab0d5abb687a4db3e0b3_87629_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "170cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Diva double vasque en ",
      "sku": "201731_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Diva double vasque en ",
      "sku": "201724_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Diva double vasque en ",
      "sku": "201717_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva double vasque en ",
      "sku": "91094_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva double vasque en ",
      "sku": "91091_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva double vasque en ",
      "sku": "91088_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Diva Double vasque im marbre composite",
      "sku": "201301_201301_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Diva Double vasque im marbre composite",
      "sku": "201247_201247_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Diva double vasque en",
      "sku": "200251_200251_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva double vasque en",
      "sku": "200081_200081_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva double vasque en",
      "sku": "200062_200062_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Diva double vasque en",
      "sku": "200037_200037_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "65545_65545_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "65537_65537_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "65519_65519_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Diva double vasque en marbre composite",
      "sku": "65511_65511_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "65495_65495_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "89211_89211_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "89202_89202_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "89193_89193_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Diva double vasque en marbre composite",
      "sku": "88777_88777_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "88771_88771_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "83715_83715_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "83708_83708_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "83694_83694_87628",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/9\/5\/3595f9da6b6d1be2d49fd271bdc855a16e9ab505_87628_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Diva double vasque en ",
      "sku": "201730_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Diva double vasque en ",
      "sku": "201723_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Diva double vasque en ",
      "sku": "201716_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva double vasque en ",
      "sku": "91093_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva double vasque en ",
      "sku": "91090_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva double vasque en ",
      "sku": "91087_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Diva Double vasque im marbre composite",
      "sku": "201300_201300_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Diva Double vasque im marbre composite",
      "sku": "201246_201246_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Diva double vasque en",
      "sku": "200250_200250_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/c\/0\/2ec01e2c5c4e406c81fbe103f1a472de777bf193_200250_200250_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva double vasque en",
      "sku": "200080_200080_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/b\/e\/cbbe5866bd8cf7dc7e68a341a1f1ecf8ded5fb0c_200080_200080_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva double vasque en",
      "sku": "200061_200061_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/e\/9\/97e99682c57d39a2ff7e1dca5cb74b0331528eaf_200061_200061_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Diva double vasque en",
      "sku": "200036_200036_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/8\/c\/818c22186377536c187c6baf315f2ad5f50f74db_200036_200036_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "65544_65544_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/f\/a36fc6f429a665acb2e46b0017edb4bb4b26fe84_65544_65544_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "65536_65536_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/a\/7\/6aa71c2d49e4bca474941a8d594f079ac07c399e_65536_65536_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "65518_65518_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/1\/3\/c913e08f0fb7f0df9b1ebfebb26f44523e9dde48_65518_65518_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "65494_65494_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/5\/7\/fe57e214b2f9d4d3e8cd18136019a39179941ea2_65494_65494_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "89210_89210_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/1\/5\/ce150d23dad09631d33578fb3b30b7714f56a6d0_89210_89210_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "89201_89201_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/e\/6\/57e6e725991627b53224f3ac477e7c0424ec364d_89201_89201_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "89192_89192_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/d\/6\/9bd62dc2fe25c3662e4641e9d2228507dc826f9b_89192_89192_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane naturel avec Diva double vasque en marbre composite",
      "sku": "89183_89183_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/d\/5\/f0d5ddd2e88db6b11a4ff7ea3ed615cd5dca85bd_89183_89183_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva double vasque en marbre composite",
      "sku": "88776_88776_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/9\/9\/0e9905041c386d732fd200f3dbc0c1968ed3b370_88776_88776_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "88770_88770_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/a\/0\/59a02504eaf6b87d503115461e43f9df5aac37fd_88770_88770_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "83714_83714_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/f\/6\/6\/1f66a83f29595b19bd8ad798f330d8c3edf48dfa_83714_83714_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "83707_83707_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/d\/1\/5ed1d67722c97a6106525598b5714cda0b149490_83707_83707_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "83700_83700_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/f\/f\/f9ff4e896d5ca2758dc79a79ead88165699b64ee_83700_83700_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "83693_83693_87627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/2\/7\/3727705d589a411debef4449e5ab7c2353cf0a24_83693_83693_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/c\/2c5c5fcfc46ed6c57bdc8d0bdfb1a3f322a084f8_87627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Diva Double vasque im marbre composite",
      "sku": "201741_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Diva Double vasque im marbre composite",
      "sku": "201304_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Diva Double vasque im marbre composite",
      "sku": "201251_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Diva double vasque en",
      "sku": "200254_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/c\/d\/64cda44abd769a1f23845f562b65e9f6945b36ec_200254_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva double vasque en",
      "sku": "200034_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/5\/c\/5\/d5c594cce623190d10d7bbeb8f800c484eb89afd_200034_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noir mat avec Diva double vasque en",
      "sku": "65488_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/3\/9\/5c39be9f4b06691def373893b5cfab7a91221a23_65488_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc mat avec Diva double vasque en",
      "sku": "65481_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/a\/1\/74a1720634385f179bfe9cd1fff53521e18b7e62_65481_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Diva double vasque en",
      "sku": "65426_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/2\/46f244e806de0299e171fb62075942ebb22d2326_65426_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm b\u00e9ton gris argent avec Diva double vasque en",
      "sku": "65417_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/b\/f\/eebfd99ddb1fb14a55c3601ae741cdab1bacb9b7_65417_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane naturel avec Diva double vasque en",
      "sku": "62102_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/0\/b\/d20b408e25db8ea6e5db74259bba8a0d0ba188f2_62102_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva double vasque en marbre composite",
      "sku": "88781_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/b\/a\/67badeca73b946aa93bcb4027169c4597443540a_88781_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "88774_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/2\/4b5216726dc33041ea40a94f552a01506a2fd183_88774_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "83718_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/1\/3\/6713f6c79d2c057ad6b86981a9dc19f37d637eae_83718_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "83711_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/3\/d\/fd3df1b837e975785d4094b6a0ed1501420df4db_83711_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "83704_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "83697_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/2\/b\/9d2b409f4e965999ddf445b74a219d8cdf0f4267_83697_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm soie grise avec Diva double vasque en ",
      "sku": "201729_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/6\/5\/fb65afab66dee696772d93fbc16ed657af94db0b_201729_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm vert olive avec Diva double vasque en ",
      "sku": "201722_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/4\/cee4bb64b47f17fde4ece00c20a9b54886de0b37_201722_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm bleu nuit avec Diva double vasque en ",
      "sku": "201715_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/2\/d\/282db0178fb4fc399f1a45f82d1d169796b77c93_201715_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva double vasque en ",
      "sku": "91092_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/3\/8\/4338797b00320669fccabfada35b2314fb17e24c_91092_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva double vasque en ",
      "sku": "91089_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/7\/6\/6c7606a6a60072405488b51c6b38a98cb717d8a8_91089_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva double vasque en ",
      "sku": "91086_87626",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/2\/4\/e02484634c42c9388777dec0528d1483b6c0d471_91086_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/8\/1\/b8812f001cac9a732025a723422b28bc703ab0d4_87626_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Diva Plan simple im marbre composite",
      "sku": "201740_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201250_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201208_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm blanc brillant avec Diva plan simple en",
      "sku": "200253_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Diva plan simple en",
      "sku": "200084_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Diva plan simple en",
      "sku": "200064_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52 cm noyer avec Diva plan simple en",
      "sku": "200039_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "65547_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "65539_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "65521_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Diva plan simple en marbre composite",
      "sku": "65505_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "65497_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "89214_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "89205_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "89196_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Diva plan simple en marbre composite",
      "sku": "89187_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Diva plan simple en marbre composite",
      "sku": "89178_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "88779_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "88773_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "83717_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "83710_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "83703_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "83696_87625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/1\/9\/e4191a52bd882d74d3ff0180290b4e8cc485d74f_87625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Diva Plan simple im marbre composite",
      "sku": "201738_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201302_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201248_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Diva plan simple en",
      "sku": "200252_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Diva plan simple en",
      "sku": "200082_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Diva plan simple en",
      "sku": "200063_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Diva plan simple en",
      "sku": "200038_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "65546_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "65538_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "65520_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "65496_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "89212_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "89203_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "89194_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Diva plan simple en marbre composite",
      "sku": "89185_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "88778_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "88772_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "83716_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "83709_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "83695_87624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/c\/1\/9\/fc199e2c32d4d942e374bb8e2a95cf620b5f91df_87624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Diva Plan simple im marbre composite",
      "sku": "201737_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Diva plan simple en ",
      "sku": "201727_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Diva plan simple en ",
      "sku": "201720_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Diva plan simple en ",
      "sku": "201713_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201301_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201247_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Diva plan simple en",
      "sku": "200251_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Diva plan simple en",
      "sku": "200081_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Diva plan simple en",
      "sku": "200062_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Diva plan simple en",
      "sku": "200037_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Diva plan simple en ",
      "sku": "91078_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Diva plan simple en ",
      "sku": "91077_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en ",
      "sku": "91076_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Diva plan simple en ",
      "sku": "91063_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "65545_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "65537_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "65519_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Diva plan simple en marbre composite",
      "sku": "65511_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "65495_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "89211_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "89202_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "89193_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "88777_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "88771_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "83715_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "83708_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "83694_87623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/2\/c\/382c656c6b1813ce8bae118ab890da1984074c19_87623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Diva Plan simple im marbre composite",
      "sku": "201736_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201300_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201246_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm blanc brillant avec Diva plan simple en",
      "sku": "200250_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Diva plan simple en",
      "sku": "200080_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Diva plan simple en",
      "sku": "200061_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52 cm noyer avec Diva plan simple en",
      "sku": "200036_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "65544_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "65536_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "65518_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "65494_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "89210_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "89201_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "89192_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Diva plan simple en marbre composite",
      "sku": "89183_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "88776_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "88770_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "83714_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "83707_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "83700_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "83693_87622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/1\/a\/131a05e1629035711d2e9dabfdb0e49369dbfc1f_87622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201739_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201739_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Mata plan simple en ",
      "sku": "201728_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Mata plan simple en ",
      "sku": "201728_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Mata plan simple en ",
      "sku": "201721_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Mata plan simple en ",
      "sku": "201721_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Mata plan simple en ",
      "sku": "201714_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Mata plan simple en ",
      "sku": "201714_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201303_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201303_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201249_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201249_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Mata plan simple en",
      "sku": "200083_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Mata plan simple en",
      "sku": "200083_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Mata plan simple en",
      "sku": "200057_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Mata plan simple en",
      "sku": "200057_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Mata plan simple en ",
      "sku": "91082_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Mata plan simple en ",
      "sku": "91082_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata plan simple en ",
      "sku": "91081_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata plan simple en ",
      "sku": "91081_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata plan simple en ",
      "sku": "91080_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata plan simple en ",
      "sku": "91080_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "89219_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "89219_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89213_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89213_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89195_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89195_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "89169_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "89169_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83594_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83594_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83593_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83593_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "83592_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "83592_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "83590_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "83590_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83587_87230",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/a\/1\/e\/3a1e9161db8018bcde1fb0a0ec83d17cd9feb564_87230_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83587_83572",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/c\/9\/cfc9a11da7822420be50150a4e5bb3c4d5c85062_83572_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201250_201245_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201250_201245_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201208_201207_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201208_201207_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200253_200249_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200253_200249_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200084_200079_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200084_200079_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200084_200078_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200084_200078_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200064_200060_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200064_200060_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200039_200041_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200039_200041_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65547_65549_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65547_65549_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65539_65541_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65539_65541_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65521_65523_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65521_65523_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65505_65509_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65505_65509_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65497_65499_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65497_65499_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89209_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89209_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89208_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89208_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89200_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89200_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89199_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89199_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89191_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89191_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89190_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89190_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89178_89172_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89178_89172_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_89218_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_89218_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_88784_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_88784_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_89168_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_89168_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_88782_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_88782_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83717_83713_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83717_83713_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83710_83706_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83710_83706_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83703_83699_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83703_83699_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83696_83692_84555",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/6\/9\/606956bd4adbb60ef10ddeb278b291855daff07b_84555_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83696_83692_84013",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/4\/8\/f548203926f19ca9d9f05b456a6fb21f7d26962d_84013_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201303_201207_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/3\/8\/68381b2718ceafd55485bf340a38d35201cb3676_201303_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201303_201207_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/3\/8\/68381b2718ceafd55485bf340a38d35201cb3676_201303_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201249_201245_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/b\/1c7b6e89389af90876ade5bf9c027a5e43c40dd4_201249_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201249_201245_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/b\/1c7b6e89389af90876ade5bf9c027a5e43c40dd4_201249_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200083_200079_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/5\/41f5469f9bd6c6b61d7fa5941f9abde767a95c12_200083_200079_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200083_200079_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/5\/41f5469f9bd6c6b61d7fa5941f9abde767a95c12_200083_200079_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200083_200078_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/a\/2\/50a2a7f7e56bf5b03ee0c932edf0c3040f618a82_200083_200078_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200083_200078_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/a\/2\/50a2a7f7e56bf5b03ee0c932edf0c3040f618a82_200083_200078_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_89218_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/4\/0\/0f4029eacb978babc77763bd136020b886e18ea1_89219_89218_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_89218_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/4\/0\/0f4029eacb978babc77763bd136020b886e18ea1_89219_89218_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_88784_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/6\/f7f60e70e0bf0cf59f7713272b0a377a5b0a5930_89219_88784_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_88784_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/6\/f7f60e70e0bf0cf59f7713272b0a377a5b0a5930_89219_88784_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89209_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/0\/2e60999086a843a592d21b3f6d37fed8680b8add_89213_89209_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89209_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/0\/2e60999086a843a592d21b3f6d37fed8680b8add_89213_89209_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89208_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/d\/cc0d35373bcf1a566f7ea5f2067be5c6bc3cb27e_89213_89208_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89208_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/d\/cc0d35373bcf1a566f7ea5f2067be5c6bc3cb27e_89213_89208_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89191_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/f\/9f1f293a1208f8c1251338b787c40b1e577a1781_89195_89191_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89191_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/f\/9f1f293a1208f8c1251338b787c40b1e577a1781_89195_89191_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89190_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/3\/6\/d43628ea9ed3d4de282e32f1a57370b1cb95ada2_89195_89190_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89190_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/3\/6\/d43628ea9ed3d4de282e32f1a57370b1cb95ada2_89195_89190_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_89168_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/d\/c\/91dcf4f23234c708fff7a0d6b1dd3f664d4a7834_89169_89168_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_89168_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/d\/c\/91dcf4f23234c708fff7a0d6b1dd3f664d4a7834_89169_89168_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_88782_84554",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/d\/3\/34d3397dfd55dba078bfd05f35b8bc1e1980cc5c_89169_88782_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/0\/c\/c00c2f5968b2565c84b73e2e7be27715a3efc35c_84554_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_88782_84012",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/d\/3\/34d3397dfd55dba078bfd05f35b8bc1e1980cc5c_89169_88782_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/a\/a\/aeaaaa283da688a397b37f97972d2417cf17226f_84012_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201302_201207_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201302_201207_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201248_201245_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201248_201245_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200252_200249_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200252_200249_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Mata High vasque asym\u00e9trique gauche en Solid Surface mat",
      "sku": "200082_200079_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200082_200079_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200082_200078_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200082_200078_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200063_200060_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200063_200060_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200038_200041_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200038_200041_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65546_65549_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65546_65549_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65538_65541_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65538_65541_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65520_65523_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65520_65523_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65496_65499_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65496_65499_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89209_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89209_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89208_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89208_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89200_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89200_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89199_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89199_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89191_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89191_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89190_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89190_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_89218_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_89218_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_88784_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_88784_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_89168_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_89168_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_88782_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_88782_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83716_83713_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83716_83713_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83709_83706_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83709_83706_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83695_83692_84553",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/3\/8\/72380b6da11657ed56cc6660edf86e46966ee287_84553_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83695_83692_84011",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/1\/2\/f612b026edc1a0ae539063a16fa658c49aa5c493_84011_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201301_201207_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201301_201207_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201247_201245_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201247_201245_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200251_200249_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200251_200249_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200081_200079_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200081_200079_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200081_200078_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200081_200078_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200062_200060_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200062_200060_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200037_200041_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200037_200041_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65545_65549_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65545_65549_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65537_65541_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65537_65541_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65519_65523_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65519_65523_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65511_65515_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65511_65515_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65495_65499_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65495_65499_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89209_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89209_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89208_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89208_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89200_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89200_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89199_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89199_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89191_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89191_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89190_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89190_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_89218_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_89218_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_88784_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_88784_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_89168_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_89168_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_88782_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_88782_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83715_83713_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83715_83713_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83708_83706_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83708_83706_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83694_83692_84552",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/f\/d\/f8fd8742a110d27b8bba37cc802b32057ee057b0_84552_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83694_83692_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201300_201207_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201300_201207_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Mata High Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201246_201245_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201246_201245_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200250_200249_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200250_200249_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200080_200079_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200080_200079_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200080_200078_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200080_200078_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200061_200060_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200061_200060_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm noyer avec Mata High vasque asym\u00e9trique gauche en",
      "sku": "200036_200041_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noyer avec Scuro High vasque asym\u00e9trique gauche en",
      "sku": "200036_200041_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65544_65549_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65544_65549_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65536_65541_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65536_65541_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65518_65523_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65518_65523_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "65494_65499_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "65494_65499_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89209_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89209_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89208_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89208_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89200_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89200_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89199_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89199_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89191_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89191_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89190_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89190_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_89218_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_89218_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_88784_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_88784_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_89168_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_89168_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_88782_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_88782_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83714_83713_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83714_83713_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83707_83706_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83707_83706_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83700_83699_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83700_83699_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High vasque asym\u00e9trique gauche en solid surface",
      "sku": "83693_83692_84551",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/7\/2\/b4721a11ac6059917abc3f918be9a95a19103f9c_84551_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High vasque asym\u00e9trique gauche en quartz",
      "sku": "83693_83692_84009",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/b\/ceeb87ee255a8dcfde64559ef0d04056cb304e9d_84009_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Mata High double vasque en ",
      "sku": "201731_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm soie grise avec Scuro High double vasque en ",
      "sku": "201731_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/3\/4\/1434a941b92104b94a328ffccb16e7a0e563ada9_201731_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Mata High double vasque en ",
      "sku": "201724_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm vert olive avec Scuro High double vasque en ",
      "sku": "201724_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/7\/4\/47741b17af065f974cedfe04d96711464fafb918_201724_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Mata High double vasque en ",
      "sku": "201717_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm bleu nuit avec Scuro High double vasque en ",
      "sku": "201717_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/2\/4\/a224d8d175e0b4344f7efc5a428fadbb2b0b4697_201717_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High double vasque en ",
      "sku": "91094_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High double vasque en ",
      "sku": "91094_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/1\/e\/6c1e12c5c44a4dad8a494618ecd984723e900abd_91094_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High double vasque en ",
      "sku": "91091_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High double vasque en ",
      "sku": "91091_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/4\/d\/db4d2d0234f596013e7dab9364791fe73d695022_91091_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata High double vasque en ",
      "sku": "91088_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro High double vasque en ",
      "sku": "91088_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/5\/c\/d45c57436f836ac63ae8c3a6f64c83771813fe31_91088_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Mata High Double vasque im solid surface",
      "sku": "201301_201301_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Scuro High Double vasque im quartz",
      "sku": "201301_201301_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/e\/2\/cee231eb69b65aa0fb9bdbb5609596ae713aea04_201301_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Mata High Double vasque im solid surface",
      "sku": "201247_201247_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Scuro High Double vasque im quartz",
      "sku": "201247_201247_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/3\/f\/593f7fadd3b7081d378ea84b7e8213eb93549988_201247_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Mata High double vasque en",
      "sku": "200251_200251_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro High double vasque en",
      "sku": "200251_200251_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/3\/7\/e437e14c29d0448915d63546dfa0ad2b5b6c0c1f_200251_200251_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200081_200081_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200081_200081_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/2\/2602a4f872da72ec163d0e9a180ac0c4ee6b8b83_200081_200081_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200062_200062_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200062_200062_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/c\/b\/48cb59bc4a4ae18b66248911a5b690fa7a1aa3de_200062_200062_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200037_200037_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200037_200037_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/1\/e\/e\/21ee187b0d3b3a34819d0e180e1a37e78f1e32d3_200037_200037_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "65545_65545_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "65545_65545_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/9\/1\/9b914baa6c851faa7e0cce899b350e8ce792baa9_65545_65545_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "65537_65537_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "65537_65537_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/c\/3\/6\/6c36570ed4f7791c75974d313d40cb967d440b6c_65537_65537_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Mata High double vasque en solid surface",
      "sku": "65519_65519_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Scuro High double vasque en quartz",
      "sku": "65519_65519_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/5\/2\/69529beb7cbe57c6b5e15ccabb05850ac6f43399_65519_65519_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Mata High double vasque en solid surface",
      "sku": "65511_65511_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris argent avec Scuro High double vasque en quartz",
      "sku": "65511_65511_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/c\/1\/5\/dc151ddeb845f255770ccd252c95700e31c9e7be_65511_65511_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "65495_65495_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "65495_65495_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/9\/4129e6d38705e7c7a3f4971db370769431322b4d_65495_65495_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "89211_89211_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "89211_89211_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/4\/c\/5b4c1468313ecb9dcae9de14f6d42065c68a68fc_89211_89211_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "89202_89202_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "89202_89202_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/f\/89dff1b12acaf971fb3727452d549922dc2adbb4_89202_89202_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata High double vasque en solid surface",
      "sku": "89193_89193_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro High double vasque en quartz",
      "sku": "89193_89193_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/5\/5\/f65597abefcb735c985583d2ae0c5731e6e1f61f_89193_89193_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata High double vasque en solid surface",
      "sku": "88777_88777_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro High double vasque en quartz",
      "sku": "88777_88777_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/9\/6\/6796be423af3db2db528d5cd0a6f28832205af4b_88777_88777_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "88771_88771_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "88771_88771_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/9\/7\/af973044f5ad6968a3248eb007c1f8f83995e23c_88771_88771_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "83715_83715_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "83715_83715_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/7\/5\/c2756a8729618e4cca7c337cf8a7808a4dd7c789_83715_83715_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "83708_83708_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "83708_83708_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/6\/a0d6646ce898b5fca50268c05a2fa6385555a320_83708_83708_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "83694_83694_84550",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/1\/6\/441635670bf95f11d868bd5b30b92a8e0178eee9_84550_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "83694_83694_84008",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/c\/d88cbe7a5509ce332bbec6d347a5bda460e6d534_83694_83694_tw1500tc750_750tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/e\/b\/eeeb5f4d8193973db7ac07b444ebe6dca47ce508_84008_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Mata High double vasque en ",
      "sku": "201730_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm soie grise avec Scuro High double vasque en ",
      "sku": "201730_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/d\/6\/ced6150fdaa11cdf1f99d92c4cdd2e3026b44ba8_201730_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Mata High double vasque en ",
      "sku": "201723_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm vert olive avec Scuro High double vasque en ",
      "sku": "201723_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/c\/8\/d0c82c5d8d2ce14af76731b50142f81af19690f8_201723_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Mata High double vasque en ",
      "sku": "201716_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm bleu nuit avec Scuro High double vasque en ",
      "sku": "201716_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/b\/390b83341580b70d6ca99db2ad37b563f87fcb86_201716_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High double vasque en ",
      "sku": "91093_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High double vasque en ",
      "sku": "91093_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/b\/d\/a2bdaaa5cb8fae81731aaffe6d4544f4cfdcc33a_91093_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High double vasque en ",
      "sku": "91090_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High double vasque en ",
      "sku": "91090_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/6\/2\/776267f1814b2f0e16ff58fca2ea2415e2433c60_91090_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata High double vasque en ",
      "sku": "91087_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro High double vasque en ",
      "sku": "91087_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/1\/9\/801914a948acd50181411e4ab5ae2083b3087da2_91087_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata High Double vasque im solid surface",
      "sku": "201300_201300_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro High Double vasque im quartz",
      "sku": "201300_201300_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/8\/80a8d831daac401c718baffa7c2f77f8e209f68e_201300_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata High Double vasque im solid surface",
      "sku": "201246_201246_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro High Double vasque im quartz",
      "sku": "201246_201246_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/7\/c2979dca8e015363391bb76633c7b8990802fbb9_201246_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Mata High double vasque en",
      "sku": "200250_200250_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/c\/0\/2ec01e2c5c4e406c81fbe103f1a472de777bf193_200250_200250_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro High double vasque en",
      "sku": "200250_200250_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/c\/0\/2ec01e2c5c4e406c81fbe103f1a472de777bf193_200250_200250_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200080_200080_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/b\/e\/cbbe5866bd8cf7dc7e68a341a1f1ecf8ded5fb0c_200080_200080_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200080_200080_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/b\/e\/cbbe5866bd8cf7dc7e68a341a1f1ecf8ded5fb0c_200080_200080_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200061_200061_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/e\/9\/97e99682c57d39a2ff7e1dca5cb74b0331528eaf_200061_200061_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200061_200061_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/e\/9\/97e99682c57d39a2ff7e1dca5cb74b0331528eaf_200061_200061_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Mata High double vasque en",
      "sku": "200036_200036_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/8\/c\/818c22186377536c187c6baf315f2ad5f50f74db_200036_200036_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Scuro High double vasque en",
      "sku": "200036_200036_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/8\/c\/818c22186377536c187c6baf315f2ad5f50f74db_200036_200036_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "65544_65544_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/f\/a36fc6f429a665acb2e46b0017edb4bb4b26fe84_65544_65544_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "65544_65544_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/f\/a36fc6f429a665acb2e46b0017edb4bb4b26fe84_65544_65544_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "65536_65536_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/a\/7\/6aa71c2d49e4bca474941a8d594f079ac07c399e_65536_65536_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "65536_65536_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/a\/7\/6aa71c2d49e4bca474941a8d594f079ac07c399e_65536_65536_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Mata High double vasque en solid surface",
      "sku": "65518_65518_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/1\/3\/c913e08f0fb7f0df9b1ebfebb26f44523e9dde48_65518_65518_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Scuro High double vasque en quartz",
      "sku": "65518_65518_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/1\/3\/c913e08f0fb7f0df9b1ebfebb26f44523e9dde48_65518_65518_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "65494_65494_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/5\/7\/fe57e214b2f9d4d3e8cd18136019a39179941ea2_65494_65494_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "65494_65494_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/5\/7\/fe57e214b2f9d4d3e8cd18136019a39179941ea2_65494_65494_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "89210_89210_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/1\/5\/ce150d23dad09631d33578fb3b30b7714f56a6d0_89210_89210_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "89210_89210_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/1\/5\/ce150d23dad09631d33578fb3b30b7714f56a6d0_89210_89210_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "89201_89201_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/e\/6\/57e6e725991627b53224f3ac477e7c0424ec364d_89201_89201_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "89201_89201_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/e\/6\/57e6e725991627b53224f3ac477e7c0424ec364d_89201_89201_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata High double vasque en solid surface",
      "sku": "89192_89192_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/d\/6\/9bd62dc2fe25c3662e4641e9d2228507dc826f9b_89192_89192_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro High double vasque en quartz",
      "sku": "89192_89192_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/d\/6\/9bd62dc2fe25c3662e4641e9d2228507dc826f9b_89192_89192_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane naturel avec Mata High double vasque en solid surface",
      "sku": "89183_89183_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/d\/5\/f0d5ddd2e88db6b11a4ff7ea3ed615cd5dca85bd_89183_89183_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane naturel avec Scuro High double vasque en quartz",
      "sku": "89183_89183_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/d\/5\/f0d5ddd2e88db6b11a4ff7ea3ed615cd5dca85bd_89183_89183_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata High double vasque en solid surface",
      "sku": "88776_88776_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/9\/9\/0e9905041c386d732fd200f3dbc0c1968ed3b370_88776_88776_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro High double vasque en quartz",
      "sku": "88776_88776_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/9\/9\/0e9905041c386d732fd200f3dbc0c1968ed3b370_88776_88776_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "88770_88770_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/a\/0\/59a02504eaf6b87d503115461e43f9df5aac37fd_88770_88770_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "88770_88770_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/9\/a\/0\/59a02504eaf6b87d503115461e43f9df5aac37fd_88770_88770_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata High double vasque en solid surface",
      "sku": "83714_83714_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/f\/6\/6\/1f66a83f29595b19bd8ad798f330d8c3edf48dfa_83714_83714_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro High double vasque en quartz",
      "sku": "83714_83714_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/f\/6\/6\/1f66a83f29595b19bd8ad798f330d8c3edf48dfa_83714_83714_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata High double vasque en solid surface",
      "sku": "83707_83707_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/d\/1\/5ed1d67722c97a6106525598b5714cda0b149490_83707_83707_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro High double vasque en quartz",
      "sku": "83707_83707_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/d\/1\/5ed1d67722c97a6106525598b5714cda0b149490_83707_83707_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata High double vasque en solid surface",
      "sku": "83700_83700_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/f\/f\/f9ff4e896d5ca2758dc79a79ead88165699b64ee_83700_83700_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro High double vasque en quartz",
      "sku": "83700_83700_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/f\/f\/f9ff4e896d5ca2758dc79a79ead88165699b64ee_83700_83700_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High double vasque en solid surface",
      "sku": "83693_83693_84549",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/2\/7\/3727705d589a411debef4449e5ab7c2353cf0a24_83693_83693_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/d\/f\/90df3d910ef4c07dca72e2164680d3fda97ea1ef_84549_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High double vasque en quartz",
      "sku": "83693_83693_84007",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/2\/7\/3727705d589a411debef4449e5ab7c2353cf0a24_83693_83693_tw1300tc650_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/9\/64d9da75b0208295952e7d94a52c7e2640cb952f_84007_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201741_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201304_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201251_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200065_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/4\/9\/b2496d08ff68e890a6fd0cd375a31b7bd5031b9c_200065_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200040_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/f\/1\/33f1d91cf6c62897f51d6070c32db0e269d9a079_200040_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "65548_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/b\/9\/97b9d19293513c1f15109a85b9ee40e21eb7c793_65548_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "65540_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/6\/83c683c928e14542c67ce81127d934280ac4105a_65540_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "65522_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/7\/e\/867e202528bafd14a8587ea88583135ac7b0dfdf_65522_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Mata High plan simple en solid surface",
      "sku": "65514_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/a\/a\/16aa0df738e55b135752761ff07bb6c6a4341120_65514_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "65498_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/8\/6\/f18691599bfcd0952c4bb698efbf090ed690507d_65498_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata High plan simple en solid surface blanc mat",
      "sku": "88781_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/b\/a\/67badeca73b946aa93bcb4027169c4597443540a_88781_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface blanc mat",
      "sku": "88774_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/2\/4b5216726dc33041ea40a94f552a01506a2fd183_88774_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata High plan simple en solid surface blanc mat",
      "sku": "83718_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/1\/3\/6713f6c79d2c057ad6b86981a9dc19f37d637eae_83718_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata High plan simple en solid surface blanc mat",
      "sku": "83711_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/3\/d\/fd3df1b837e975785d4094b6a0ed1501420df4db_83711_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface blanc mat",
      "sku": "83704_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface blanc mat",
      "sku": "83697_84548",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/2\/b\/9d2b409f4e965999ddf445b74a219d8cdf0f4267_83697_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/6\/4\/f864e0e7ed248b968328c2a20d740a3208583ace_84548_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 106 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201740_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201740_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 106 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201250_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201250_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 106 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201208_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201208_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52 cm blanc brillant avec Mata High plan simple en",
      "sku": "200253_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Scuro High plan simple en",
      "sku": "200253_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200084_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200084_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200064_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200064_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200039_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200039_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "65547_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "65547_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "65539_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "65539_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "65521_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "65521_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52  cm ch\u00eane naturel avec Mata High plan simple en solid surface",
      "sku": "65505_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Scuro High plan simple en quartz",
      "sku": "65505_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 106 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "65497_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "65497_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "89214_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "89214_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "89205_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "89205_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "89196_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "89196_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm ch\u00eane naturel avec Mata High plan simple en solid surface",
      "sku": "89187_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Scuro High plan simple en quartz",
      "sku": "89187_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm b\u00e9ton gris argent avec Mata High plan simple en solid surface",
      "sku": "89178_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Scuro High plan simple en quartz",
      "sku": "89178_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "88779_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "88779_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "88773_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "88773_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "83717_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "83717_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "83710_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "83710_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "83703_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "83703_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 106 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "83696_84547",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/6\/e\/0f6e342537c7dba5a624db0a490f5a4770704048_84547_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "106cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "83696_84005",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/5\/8\/f85860ec9661b24ca65230ead5be6b2b3f84f253_84005_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201739_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201739_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Mata High plan simple en ",
      "sku": "201728_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Scuro High plan simple en ",
      "sku": "201728_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Mata High plan simple en ",
      "sku": "201721_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Scuro High plan simple en ",
      "sku": "201721_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Mata High plan simple en ",
      "sku": "201714_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Scuro High plan simple en ",
      "sku": "201714_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201303_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201303_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201249_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201249_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200083_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200083_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200057_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200057_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Mata High plan simple en ",
      "sku": "91082_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Scuro High plan simple en ",
      "sku": "91082_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata High plan simple en ",
      "sku": "91081_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Scuro High plan simple en ",
      "sku": "91081_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata High plan simple en ",
      "sku": "91080_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Scuro High plan simple en ",
      "sku": "91080_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "89219_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "89219_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "89213_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "89213_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "89195_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "89195_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "89169_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "89169_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "83594_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "83594_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "83593_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "83593_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "83592_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "83592_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "83590_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "83590_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "83587_84546",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/7\/3\/9d733807950bf127087a203b7ba62936eb9a9880_84546_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "83587_84004",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/8\/c5e892be1efe8372c9b928be54df5459735683d2_84004_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201738_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201738_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/5\/c\/e75cf31002e36a5c63c2866fdcb9bf9fea4bb435_201738_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201302_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201302_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/9\/9\/0499e216d6829237908590a35bd66fa450c65221_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201248_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 85 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201248_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0d7649dcf848ddecfc80d32f35000332b66cc_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm blanc brillant avec Mata High plan simple en",
      "sku": "200252_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Scuro High plan simple en",
      "sku": "200252_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/4\/1\/0d41c9e45cc918bba660fdb274c52e7c1d307395_200252_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200082_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200082_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/c\/ce4c750b9000740003675d4c989b70261279c2c6_200082_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200063_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200063_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/2\/5\/68254ed3d8a1df6cf1eda9264eb32234471d8c68_200063_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200038_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200038_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/9\/7\/7397fe7ec117f5bc4bf03f5e996276c33befaf73_200038_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "65546_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "65546_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "65538_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "65538_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "65520_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "65520_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "65496_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "65496_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "89212_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "89212_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/b\/57cb3748ac40b5f7b79f8bb9ffe8f5c410e8878e_89212_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "89203_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "89203_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/5\/7\/53576e07fff08637546a0ce2450e34163574b50c_89203_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "89194_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "89194_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/b\/6\/78b64848a905e905a9a022e1b1baa984d7bd18a1_89194_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Mata High plan simple en solid surface",
      "sku": "89185_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm ch\u00eane naturel avec Scuro High plan simple en quartz",
      "sku": "89185_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/3\/6\/9f3677fc54d2f43298fdb5ecdde380c66262850b_89185_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "88778_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "88778_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "88772_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "88772_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/8\/03e8c41066ad007d8e081e6242a1dba8bbab1465_88772_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "83716_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "83716_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/5\/9\/0659245d14e75ccf6a7d40ba1c7294a0d536d83d_83716_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "83709_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "83709_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/3\/c\/693c68d0e2627c4a0bd22a79d1aef4dac1fa892d_83709_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "83695_84545",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/4\/5\/ea45d040345a081a510dbc634db3064c33ac6615_84545_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "83695_84003",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/4\/2\/c542d2b7e8a64e33825d530b3d0fe37b975758a6_83695_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/7\/8\/32784f16f6ceff386816ffa6f6efadf3ad0d0df9_84003_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201737_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201737_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/5\/a\/d65a561756c78178ce65e4d4326e2ad0cb1dd64e_201737_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Mata High plan simple en ",
      "sku": "201727_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm soie grise avec Scuro High plan simple en ",
      "sku": "201727_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Mata High plan simple en ",
      "sku": "201720_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm vert olive avec Scuro High plan simple en ",
      "sku": "201720_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Mata High plan simple en ",
      "sku": "201713_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Scuro High plan simple en ",
      "sku": "201713_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201301_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201301_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/7\/4\/3f74e121f5a89476dab4d4b7b6cfff4d9b405e41_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201247_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 75 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201247_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/a\/691adf346b2fa34c49205cc98d54587a6e02d580_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm blanc brillant avec Mata High plan simple en",
      "sku": "200251_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Scuro High plan simple en",
      "sku": "200251_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/2\/5\/2\/8252247b32a40a57277a6e05c9ca00d1d6985acc_200251_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200081_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200081_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/9\/c\/719c08faa8c2271c8922fca149a756009a27a4e0_200081_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200062_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200062_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/2\/0\/8\/220882a14fc686ef1806c9cf1daa8d39758982cd_200062_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200037_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200037_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/7\/01d7b3dd5b9667674cf6a1f39da8276ad8835e24_200037_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata High plan simple en ",
      "sku": "91078_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro High plan simple en ",
      "sku": "91078_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata High plan simple en ",
      "sku": "91077_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro High plan simple en ",
      "sku": "91077_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en ",
      "sku": "91076_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en ",
      "sku": "91076_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Mata High plan simple en ",
      "sku": "91063_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Scuro High plan simple en ",
      "sku": "91063_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "65545_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "65545_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "65537_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "65537_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "65519_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "65519_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Mata High plan simple en solid surface",
      "sku": "65511_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris argent avec Scuro High plan simple en quartz",
      "sku": "65511_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/1\/12e111357044bb3311acbf6f04a49b2a8ab52ddc_65511_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "65495_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "65495_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "89211_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "89211_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/c\/1\/e4c14cb9b2727ad6de61abd02679f0938dcc19e0_89211_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "89202_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "89202_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/6\/e8b64d19ddb899b7c63ee874184737c75017ea15_89202_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "89193_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "89193_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/d\/077dce910badb0352b65569d581a824474abcad0_89193_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "88777_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "88777_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "88771_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "88771_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/7\/5\/aa75d75580f3b10989901a79846331833e9044e2_88771_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "83715_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "83715_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/b\/6\/c5b6ab37f5516d826bef2d5cc38737a9bbd93178_83715_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "83708_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "83708_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/e\/1\/3ce12a05e528128bc4ae0053a68589da173d8255_83708_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "83694_84544",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/8\/a\/978a117a647e7c56b00d13e05a7b4d5378fe61ea_84544_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "83694_84002",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/8\/4\/9f84cdce52b0187d38a8b42bf01d9a8376ce3191_83694_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/b\/7\/14b77d762f0e6ef676fe94dfcd545687f0deef34_84002_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Mata High Plan simple im solid surface",
      "sku": "201736_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201736_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/9\/7\/4e97ed6ad771cf1ee992c12d4604dde4904020e0_201736_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Mata High Plan simple im solid surface",
      "sku": "201300_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201300_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/b\/b90b861af69c61703437bff0ffca23d8c7e61bd6_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Mata High Plan simple im solid surface",
      "sku": "201246_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 65 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201246_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/3\/5\/39356af5432ffabfac2418d5c38b86d74fa67f03_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm blanc brillant avec Mata High plan simple en",
      "sku": "200250_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Scuro High plan simple en",
      "sku": "200250_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/5\/7\/50572d987e8f7ef28fd14914746486df0b9562bb_200250_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200080_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200080_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/f\/b\/cbfb83decdea463db8cc3786965b568789123f21_200080_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200061_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200061_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/a\/f\/a9affa59a3c09e1bcadea371ffacef825dd0eed0_200061_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52 cm noyer avec Mata High plan simple en",
      "sku": "200036_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noyer avec Scuro High plan simple en",
      "sku": "200036_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/c\/e\/2cce672616ca3441833d82c04dc0f543350287a9_200036_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "65544_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "65544_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/4\/c\/6a4c78cb9e034337f6040ca442b117e7cd1267ca_65544_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "65536_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "65536_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/1\/9ed1208e72631decacf88337836a8b316a64ae9c_65536_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "65518_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "65518_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/f\/0\/5cf0a6f0bf8b79a6665a522cbb4cdd196612a506_65518_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "65494_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "65494_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/d\/8\/08d833fd2a84df7c08d2b0ea20aee8191ed45c66_650_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/8\/a498121a4cb98e07690ba3bf9f5d88aa5f3c86ee_65494_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "89210_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "89210_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/1\/26014a66906e883e3187d67647000e6e76c221dc_89210_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "89201_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "89201_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/8\/2\/8a822565219e9cb9eb13440b1b12bb76390f0a35_89201_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "89192_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "89192_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/b\/e\/60be977f9810f75389a07845f252b1c36dc9daea_89192_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Mata High plan simple en solid surface",
      "sku": "89183_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane naturel avec Scuro High plan simple en quartz",
      "sku": "89183_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/d\/e\/3\/7de3e4efb21527a283976205df02e622505cb9d1_89183_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Mata High plan simple en solid surface",
      "sku": "88776_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Scuro High plan simple en quartz",
      "sku": "88776_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "88770_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "88770_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747a91edea12c64495df07bcd3eef43e9d0d149_88770_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Mata High plan simple en solid surface",
      "sku": "83714_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm noir mat avec Scuro High plan simple en quartz",
      "sku": "83714_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/1\/c\/111c3474385de06698c393084571f7f625fa5260_83714_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Mata High plan simple en solid surface",
      "sku": "83707_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc mat avec Scuro High plan simple en quartz",
      "sku": "83707_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/c\/6\/3fc61d34182e1920541e313547760c489a2164de_83707_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Mata High plan simple en solid surface",
      "sku": "83700_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz",
      "sku": "83700_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/8\/e\/ce8e178358bd410a232da830f6718815073a2325_83700_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata High plan simple en solid surface",
      "sku": "83693_84543",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/3\/e\/5a3e402485dde2c475079b6226621741c762c8e4_84543_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "mata_high"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz",
      "sku": "83693_84001",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/1\/4\/b814ff7647237b46455d5c5a724b3234d969f1e1_83693_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/2\/6\/a326876d833c1f7936bc31ba7827784d5d5eb436_84001_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  Meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65522_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/7\/e\/867e202528bafd14a8587ea88583135ac7b0dfdf_65522_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "65426_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/2\/46f244e806de0299e171fb62075942ebb22d2326_65426_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Edge tablette simple ou double en m\u00e9lamin\u00e9 ch\u00eane sauvage",
      "sku": "83704_71313",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/7\/a\/847a6c391e0ed2f6e75d9a29d8050e8ecf019c76_71313_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "rough_oak",
      "type_basin_t": "upmount",
      "width_t": "120cm",
      "series_t": "Edge",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201741_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201304_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro High Vasque asym\u00e9trique gauche im quartz",
      "sku": "201251_84010",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/a\/90fa376df9a683bdd595719d8538bc9906070d48_84010_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm soie grise mit Scuro High Plan simple im quartz",
      "sku": "201741_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/c\/8\/13c87539fddb2060b7f9a0efaee56503c3845af6_201741_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro High Plan simple im quartz",
      "sku": "201304_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/a\/9\/a\/5a9a3386c9926f013a68d543c693d0c79e634cce_201304_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro High Plan simple im quartz",
      "sku": "201251_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/0\/8\/b908c6afa924b7193f21f8d83f1ab46049d2c772_201251_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Scuro High plan simple en quartz mat",
      "sku": "65548_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/b\/9\/97b9d19293513c1f15109a85b9ee40e21eb7c793_65548_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Scuro High plan simple en quartz mat",
      "sku": "65540_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/6\/83c683c928e14542c67ce81127d934280ac4105a_65540_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Scuro High plan simple en quartz mat",
      "sku": "65522_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/7\/e\/867e202528bafd14a8587ea88583135ac7b0dfdf_65522_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Scuro High plan simple en quartz mat",
      "sku": "65514_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/a\/a\/16aa0df738e55b135752761ff07bb6c6a4341120_65514_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz mat",
      "sku": "65498_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/8\/6\/f18691599bfcd0952c4bb698efbf090ed690507d_65498_tw1200tc1200tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro High plan simple en quartz mat",
      "sku": "88781_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/b\/a\/67badeca73b946aa93bcb4027169c4597443540a_88781_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz mat",
      "sku": "88774_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/2\/4b5216726dc33041ea40a94f552a01506a2fd183_88774_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro High plan simple en quartz mat",
      "sku": "83718_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/1\/3\/6713f6c79d2c057ad6b86981a9dc19f37d637eae_83718_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro High plan simple en quartz mat",
      "sku": "83711_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/3\/d\/fd3df1b837e975785d4094b6a0ed1501420df4db_83711_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble de salle de bains suspendu 120 x 52,5 cm ch\u00eane brut avec Scuro High double vasque en quartz mat",
      "sku": "83704_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/6\/4\/78640039c712d321e380333621cd6e47234f8aec_83704_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro High plan simple en quartz mat",
      "sku": "83697_84006",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/2\/b\/9d2b409f4e965999ddf445b74a219d8cdf0f4267_83697_tw1200tc1200tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/c\/1\/7\/ac17b0e7ba78b4a294ce1b4d0d6d10dc98b98803_84006_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro_high",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Diva Double vasque im marbre composite",
      "sku": "201300_201299_201300_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/4\/8\/83482b676f0a8f84043da5ffae049c5e21f3c751_201300_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Diva Double vasque im marbre composite",
      "sku": "201246_201244_201246_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/6\/8\/16680c0f41cf8938abcd95a0507327cd2ce98dae_201246_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm blanc brillant avec Diva double vasque en",
      "sku": "200250_200248_200250_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/3\/83c3c699c90ab7feb8be968da560a9827b684cb1_200250_200248_200250_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Diva double vasque en",
      "sku": "200080_200077_200080_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/b\/b\/11bb8943cff6f7ec76f7c420296f70fb3eb3bde0_200080_200077_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Diva double vasque en",
      "sku": "200080_200076_200080_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/3\/0\/50308444aecfb9f8a295baa05a712c4b326b7204_200080_200076_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Diva double vasque en",
      "sku": "200061_200059_200061_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/e\/7\/0ae780af7ecfd146073e799114cdd4d2132f130e_200061_200059_200061_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "89210_89207_89210_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/7\/4\/b77422b68c28b49c9b32b6ee7509d623a12df137_89210_89207_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "89210_89206_89210_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/3\/6\/0636cef3cd9a179ae8ee172a96b7644e612a5da7_89210_89206_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "89201_89198_89201_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/0\/c\/c20c4409473ec9d97f590ea51a37c1b6814c6755_89201_89198_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "89201_89197_89201_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/b\/1\/f8b158265f284b854af257fae8ce74bca9a695d4_89201_89197_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "89192_89189_89192_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/7\/e\/4a7e8ad5b8e1af444dd7c40d58cc86a6d42a5f1c_89192_89189_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "89192_89188_89192_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/4\/f\/ff4f9b66184be75d0e7249d0e56c278dd7dac384_89192_89188_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Diva double vasque en marbre composite",
      "sku": "88776_89217_88776_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/6\/9\/8f6983cb3fdf75dfc4c41711102e1afa23777a85_88776_89217_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Diva double vasque en marbre composite",
      "sku": "88776_89216_88776_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/1\/8\/1d18d4d284a3013654cb1ca87765b356fc0f8a0c_88776_89216_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "88770_89167_88770_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/3\/90f327a8d6da96ec0186573210b6bf61525dfe0b_88770_89167_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "88770_89166_88770_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/b\/1\/f0b144eb141ada04c97d9b6dc265f8a8bf27c4f4_88770_89166_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Diva double vasque en marbre composite",
      "sku": "83714_83712_83714_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/9\/4\/ec9420abee31145c4718a961b2e2b1fb4a975d34_83714_83712_83714_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Diva double vasque en marbre composite",
      "sku": "83707_83705_83707_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/2\/d252ab47fc1ce3f508d77feb3a8298baf4a210b5_83707_83705_83707_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Diva double vasque en marbre composite",
      "sku": "83700_83698_83700_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/2\/4\/7\/4247d93b2e827ef16c81ff9db0bcb7cfdc44cab6_83700_83698_83700_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva double vasque en marbre composite",
      "sku": "83693_83691_83693_83868",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/8\/4b3888959df9b87c37a7466b8b5541e9397586fb_83693_83691_83693_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/5\/b\/415badf27a37da98389d324e98909fb154bdcb40_83868_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201299_201208_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/f\/a\/4bfaa15fac24ae40019c3b9afb0e63aa551b1c65_201299_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201244_201250_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/8\/e\/0c8e5391945ab3d4a1755f36a811458ccf09ef1c_201244_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200248_200253_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/0\/cc001d491f1a3b61eb5a816e38470016fb93c7c0_200248_200253_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200077_200084_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/d\/2\/1bd22b3122fdf8486314cfb7012210317b4993a6_200077_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200076_200084_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/d\/d\/8eddddcd1f09783a96e5b30ec7af7614c2be5424_200076_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200059_200064_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/0\/b\/8c0bd021dde4fa3109ba058b4c97c76d9ae6f2d8_200059_200064_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89217_88779_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/b\/0\/a4b0b304e2d272a4aa8d67755765528fb016414e_89217_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89216_88779_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/b\/1e0bda70c073165a859284e6d97945c97960ad9c_89216_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89207_89214_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/3\/6\/5e36aa6a26e7467a0f888e9515ece5431e92421a_89207_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89206_89214_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/2\/d77248a5fd4cae9bd939eddb80254ad275b0810e_89206_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89198_89205_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/0\/6640fcace08104eb24b61ccad77b63e4005cb020_89198_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89197_89205_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/1\/c9d13cd5a563071322643259498955d7ff359ee6_89197_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89189_89196_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/a\/e\/a7ae23a381149876eb4d4297b986a1a016d518ec_89189_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89188_89196_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/6\/9\/6769ea58d12b67c4592bf288c2a3b40f2d5e3ac1_89188_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89170_89178_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/c\/3\/cdc3baf1272bf3ca45ae26f0e03e341a484047b0_89170_89178_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89167_88773_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/1\/c\/271c2f8eb4a8fb01ae4e64f30a5f517fd1e6bf39_89167_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89166_88773_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/b\/3\/eab37aa5f1a5006a4a9d88a5dc2c4940cd2b3202_89166_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83712_83717_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/6\/9\/4e697b78446e037700f8f0417c51c277ee06e861_83712_83717_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83705_83710_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/f\/aaefe601d35ae60a89162891fd6ff8764dc37cf5_83705_83710_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83698_83703_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/f\/6\/e3f63275a2017f896625c45bb357c40162f12367_83698_83703_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83691_83696_83863",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/0\/c4b090ba920b0feccad23d14ba0534bab105bebe_83691_83696_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/6\/0\/5060694fece811068bb9faf4e0dd1f047a3f568a_83863_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201250_201244_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/2\/75b21f65605a0effcdf12a10c52950416eb27bdd_201250_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201208_201299_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/2\/5\/e325b3769ea0bd42871e3165b1f145d01a403b5e_201208_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200253_200248_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/0\/e\/1\/10e1541cd8d8408839dfd3c80e0d7515f51e56d1_200253_200248_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200084_200077_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/5\/4\/fa548b1e8eabfa5c9d4c53dfcedbd20e2fb8b8e2_200084_200077_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200084_200076_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/b\/64dbb8b471bde4564662f465affa866be43ce457_200084_200076_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200064_200059_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/2\/3\/3e233947e07c626d28ed8d3340007bc150efebac_200064_200059_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89207_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/9\/c\/849c6589c337e12d6015a890497b6380ee89210c_89214_89207_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89206_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/c\/c\/90cc9f0d7d1e8f9958116146d49ee91af6d0f96d_89214_89206_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89198_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/1\/4b316f0520b097247dad9473528a5d4d3f6762c2_89205_89198_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89197_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/a\/0\/c3a0622f33e13adcdfa68a9bdd34c0208dda11ec_89205_89197_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89189_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/6\/2\/f9629dee1ca72bf827e4eafd3be0586abd423b07_89196_89189_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89188_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/c\/41fc39ea98df749e1bdc53b4a2d0544eae6065a9_89196_89188_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89178_89170_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/e\/9\/cae9c5141571fa91e3f9afef133b12ca02364d86_89178_89170_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_89217_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/6\/f\/006fe865a4832c769a7efbee904bc0dc559bbc1a_88779_89217_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_89216_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/c\/819c60fd262ce4e08040c2daad38b747920f7e92_88779_89216_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_89167_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/b\/a79b03be0a4bec4d1fb23f956f676ced5d5ff2f0_88773_89167_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_89166_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/8\/9f1819710fd5fe52824644b219f1c236da736d15_88773_89166_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83717_83712_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/9\/d8894ad63bd4098accb4f7fb778442f68a87c24e_83717_83712_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83710_83705_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/9\/6\/589692f791af641ea7b66f16a1871f9e1a1e337a_83710_83705_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83703_83698_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/9\/0\/b490474d462123a53a5ef71eb3d34b483cbbb01c_83703_83698_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83696_83691_83862",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/c\/b61c9944016574b3a58ce8f53e52af553c8b9ff5_83696_83691_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/2\/a\/ee2aa84daad56e5c7024e87ae2d1f76279b8b38b_83862_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201299_201303_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201244_201249_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200077_200083_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200076_200083_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89217_89219_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89216_89219_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89207_89213_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89206_89213_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89189_89195_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89188_89195_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89167_89169_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89166_89169_83861",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/5\/3145c81670ea81de62918c1af65ab43ba85f5bc7_83861_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201303_201299_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201249_201244_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200083_200077_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200083_200076_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_89217_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_89216_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89207_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89206_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89189_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89188_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_89167_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_89166_83860",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/e\/d\/34ed19d6e09d88fca135390302b4c9ff207e2ac7_83860_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201299_201302_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/e\/1\/bae1157a18b665e8a44b4d7ee8f5514d6a206bc3_201299_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201244_201248_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/c\/1\/67c1e79ea3cf96103f76aa7c934d8e4d60029c97_201244_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200248_200252_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/9\/f\/1b9f89d3093630b23e9a9eeb8e83824cd27ce063_200248_200252_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200077_200082_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c16fc8909bdb0ca0dee55967c63802d52ffc4_200077_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200076_200082_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/7\/b\/967bfd1b6ed4ef6db7d034369d193f8a9dcd6020_200076_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200059_200063_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/5\/a\/6d5a1f2c5eed5d32f10ac4adc9aa40d88b2d6536_200059_200063_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89217_88778_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/f\/f\/36ff7779f923b9ce23f9d4856c28a27d078ce646_89217_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89216_88778_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/e\/e\/a7eea02b2d949673e60a081b23c761d602e87f40_89216_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89207_89212_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/e\/2\/fde2941a3d429e6d5a28d0b970dd49f071c76326_89207_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89206_89212_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/3\/6\/d036755c28735b3043f653830bfcb0df37ad571b_89206_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89198_89203_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/9\/adb96f308c6e8838cccf326de882d2fff70a7ccd_89198_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89197_89203_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/1\/d3015f2d3084c89890871b5e1a13047c3623e119_89197_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89189_89194_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/f\/9b5fd09e98bf8a735b8f42bd22feea432ae99247_89189_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89188_89194_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/c\/668ca7f5c6b5f56c366b07729af8132d07420828_89188_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89167_88772_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/e\/a\/0ceaecf390d6fb5e2d1f3d4eec198799b513f6b5_89167_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89166_88772_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/8\/5\/73852532f5065c1a77d5943b56edcfcf020d6437_89166_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83712_83716_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/d\/8\/99d8af7c8978c461dc191f3b519b047b6c2162d4_83712_83716_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83705_83709_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/0\/d770143be08577f153c499a4568026b7dacafca2_83705_83709_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83698_83702_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/5\/1\/79511076498d29aa28cf4a4cf734191a2017e0ec_83698_83702_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83691_83695_83859",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/7\/b\/4d7b38e07a26746a6ff93c197a311ef28d1e8647_83691_83695_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/3\/a\/533a2757bdbb901752e326af8f3aa66069dbbdff_83859_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201302_201299_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201248_201244_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200252_200248_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200082_200077_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200082_200076_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200063_200059_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89207_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89206_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89198_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89197_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89189_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89188_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_89217_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_89216_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_89167_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_89166_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83716_83712_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83709_83705_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83695_83691_83858",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/7\/2\/90722bd890c5e0f168f03aae3077ffa35d1aa208_83858_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201299_201301_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/7\/8\/9f78585b31ee941f4ae2f02fe3120b48ed2ad3d6_201299_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201244_201247_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/8\/8\/94883ee602d473e77c8587078863bcf8d0869348_201244_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200248_200251_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/2\/5162c8c3335aae95debc4f51258c6ebfb2e94377_200248_200251_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200077_200081_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/2\/9ed2aa8c501ef24bdfa84155c167e368a16af6ac_200077_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200076_200081_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/b\/7\/6db7b1c5c0465b531ad5d811fea5bc374b5558f5_200076_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200059_200062_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/3\/9\/a739587676250e5cc2220d563745dc01890e61ab_200059_200062_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89217_88777_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/5\/5\/eb55e5290656bb4756a060541e49572215a96d9e_89217_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89216_88777_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/e\/6\/2\/de62c52dcc32f13c8a31ce3c6bcd56d073f0ceab_89216_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89207_89211_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/d\/2\/e5d28fe05b048f5ae60358b7ff825c9b6c6a3492_89207_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89206_89211_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/1\/e\/711e4587323d85ec01212a4ee6fe6c003b13baa5_89206_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89198_89202_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/f\/1\/67f1302c73f3c9de5fd86d490d44d9de867f3cef_89198_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89197_89202_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/3\/2\/8e32e611e68aaa424f2366ab5bdd39ede12fca11_89197_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89189_89193_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/0\/b\/7b0b915eb4fbdb4408851d652be4e1b293425f35_89189_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89188_89193_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/3\/5\/00352dbd8a04c904226eb5b9758e08d2fc12dabc_89188_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89167_88771_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/7\/c96754caeff4a52bd18aed4e097d51fe61d1d8d6_89167_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89166_88771_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/1\/9\/8\/d198489bd8be4a3ec682d726d61c5a36c0f43e80_89166_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83712_83715_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/d\/3\/a5d336df9d4bb8517dd1a7aae0c36bcfa9cb9f05_83712_83715_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83705_83708_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/3\/c\/f13c8dd17a75f3b9bb4952f51f7e2589aaff693d_83705_83708_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83698_83701_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/d\/2\/62d2a84ece6db17652b9f9cc9fa1cb5e9dd4198c_83698_83701_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83691_83694_83857",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/e\/8d3e0c198c08b7c53cbf4a33b4597412bf8c0d4c_83691_83694_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/4\/d2548141d72d786583984ff24f1e7d0ffa568b11_83857_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201301_201299_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/5\/e\/f55ee343058d9af2e7d02ffa077c1eed0612bf4c_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201247_201244_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/4\/f\/df4f03520540b740cdf5c0c7b1b9f812be9b6133_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200251_200248_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/a\/7\/75a7f1d1d92e5d2292b043d072b8518d85432499_200251_200248_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200081_200077_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/3\/2\/35320320f81160b02572e3981292abc2fcb620f2_200081_200077_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200081_200076_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/4\/5\/8845d22b8429e2ccef487449773d93d81629d7a5_200081_200076_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200062_200059_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/7\/f\/937f629c34f492fc2ad34d8818e50989c67b2aa0_200062_200059_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89207_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c03b07acfdd0903ef6ab3d9befc7782c8a166_89211_89207_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89206_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/c\/d\/61cda3c0081bd7289e241086f87754cae6f4793a_89211_89206_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89198_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/0\/a\/b80ae82e403b02c2af95bbebb06c5fb3b2606e00_89202_89198_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89197_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/b\/c\/bcbc4b141a7b8920fcde46ec265988020f30e507_89202_89197_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89189_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/f\/7\/06f7a7d8c1a8a4206997756a7b75e0f95bfd9cde_89193_89189_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89188_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/3\/46f34d3e7b895fec28c36fc3f66e08b2280d54a0_89193_89188_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_89217_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/b\/4\/72b47435eb12a80e36135b26a7f87e55ab9392c1_88777_89217_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_89216_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/2\/ddb285284a7bf154f26e2e93105fdcad01f98255_88777_89216_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_89167_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/2\/6\/98262a25a1942061a4e504b1f5f12d2fa5c0046d_88771_89167_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_89166_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/e\/5\/14e5c4368dc23734d2456bcf0375fc740bf3cdf0_88771_89166_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83715_83712_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/2\/d642c1a3d848be9df06e7351ee5b8bde6414e4f4_83715_83712_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83708_83705_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/2\/0a420ef1a77fbf5974c2e465faff86605f1e3e9e_83708_83705_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83694_83691_83856",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/4\/0\/e640dbe51a233ae693fa544ad7e714aef64be01f_83694_83691_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/2\/d\/532d8d5b6af005767d7f111e3acd1cc8289abaeb_83856_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201299_201300_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/c\/8\/60c8b568cabb83005a042df2a26926c933daf0f5_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201244_201246_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/0\/1\/8501b375db62e4238b823fcd8a4c8cd941c5165b_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200248_200250_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/9\/7\/1\/49711bf482bd4f024ab8c3e8103deb3d4f0cd71e_200248_200250_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200077_200080_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/b\/b10bd3cadfff8acc953c436ad6ef374fc1eabe70_200077_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200076_200080_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/e\/0\/93e050355772a2572d374b2e4eed0da90a33525c_200076_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200059_200061_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/f\/f\/b3ffe351e86a88fb2e77ed7bac704dfed44b9ff0_200059_200061_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89217_88776_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/e\/ddbe430502c7d936a275599097a49da815618ce1_89217_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89216_88776_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/e\/2\/eae22decc498c46aeb65d0e59447fc0c63bc1144_89216_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89206_89210_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/9\/f\/c89fc70f8b1f3024768aa6938e7978408dec119e_89206_89210_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89198_89201_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/1\/d\/a41d2b54a81e02443f9a80d2a029aa73d9e78b2b_89198_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89197_89201_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/e\/f36ecb4b524508068b92073f9994587b6d9cbf3f_89197_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89189_89192_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/9\/8\/8a98e7dbec16dbd36342cd5cdfa2965887b3d24d_89189_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89188_89192_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/f\/1\/eef106d0c31a045b50bd4c965c0a4f4b1d6d6447_89188_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89167_88770_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/7\/5287ced70a6f0a96e5db3ab78b9fdbd69711e9d1_89167_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89166_88770_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/f\/5\/16f5403217733e30da585271070878fc7bc7943c_89166_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83712_83714_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/0\/0\/8600c05216071bd31015bc459573e65fb072a2ed_83712_83714_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83705_83707_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/5\/e\/d95eeb83256ff7b3228cfe0c87c9a756f6accb94_83705_83707_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83698_83700_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/c\/2\/0fc20293af0566a0b0f6b798e7afc57d25e6ffde_83698_83700_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83691_83693_83855",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/d\/b\/b4db4726839a5d08f9e1218be318608d72cc73e6_83691_83693_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/9\/6\/c79673770dd52fdf697122d271a7cf4ef06066ab_83855_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201300_201299_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/0\/18503f9f0ceb721779d958c0fc36cde3d10d5bf9_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201246_201244_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/c\/6\/28c66862bfe84cd2aff1c53f3bfa9247e4c99824_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200250_200248_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/9\/4\/f694a833810f1e224089213ae5043d8bd147e3d9_200250_200248_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200080_200077_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/f\/1\/faf1307c37f2752fcd895a54de494a0504d84fa8_200080_200077_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200080_200076_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/a\/819a23093b71e8740db85b9f87979da37395af1a_200080_200076_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200061_200059_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/7\/a36770d4415c0c4a463ca30fd14ddfa6c64b6531_200061_200059_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89207_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/e\/2\/c7e2bfec7f91b613b59f14ccbb74c60d20bba7ff_89210_89207_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89206_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/7\/2\/0a722b841ae53a7ff8a70192ad8c869b99ac647a_89210_89206_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89198_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/3\/c\/ce3cbbb4428fbf5ded54d6a1e5bf108faabdc275_89201_89198_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89197_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/8\/30c882cea76abdb9806dbf7a227bd6bb9e808441_89201_89197_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89189_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/9\/7\/a297d0be7adb3e650c12f01040fcf2094e28ef7e_89192_89189_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89188_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/1\/0\/1b1068afaf142dacaf7ad964cbd350257642eb96_89192_89188_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_89217_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/8\/5\/24850be38f793e7294c0c687c98a2aa8b6b668f8_88776_89217_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_89216_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/8\/2\/2882fd706340ec5be23072e42c0e0507f390a95e_88776_89216_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_89167_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/c\/7\/b3c7987dd04be9d59c5984acb7c807c7475c6051_88770_89167_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_89166_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/d\/6\/e0d6b66fbef4aff52ba9259ed661299f7a29f2f1_88770_89166_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83714_83712_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/3\/1\/7431b769c2cec24647afd3a7e396716bdbb4ed10_83714_83712_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83707_83705_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/1\/e\/321e7376a8cfbbe96454a76de8a5990ad1979564_83707_83705_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83700_83698_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/b\/a\/9\/aba9e50e67873ec7eea7b2b5e30eec968385009d_83700_83698_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83693_83691_83854",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/5\/5\/6655fdff1acbf082c2cecc81516f760bc86f2dd6_83693_83691_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/0\/6\/4e0633af6a3b3ff7312931c4001a7bc4bd1a8d13_83854_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201245_201249_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/8\/8\/cc88395a6abc66afbafae0ce4d0565527f601d4d_201245_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201207_201303_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/9\/2\/e392d3592b8f9ee702ecb883f6b6bdc800531d8f_201207_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200079_200083_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/3\/0\/b9300dcde1d70d5319178f5302d729f1d77a7d1b_200079_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200078_200083_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/1\/1\/e\/c11e0ab083f5ec3aa241d366ac0f1d1789e13016_200078_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89218_89219_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/6\/9\/ba69d0efae2496cb814706801712ee43ffa1bec8_89218_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89209_89213_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/7\/0\/5b7079605f214fe8e737439a8edce286c41650bb_89209_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89208_89213_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/d\/8\/7ad8552839faf76426432b3d7b3c87f73e17d2dc_89208_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89191_89195_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/5\/2\/5b5246ae63e921b54cfc193e0959a1a57da6b478_89191_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89190_89195_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/9\/9\/029964d60222045c01c0f39abc7135c84ad544ef_89190_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89168_89169_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/2\/b\/ea2b4cd57ab1ab4acc2ad2ed99a15284daec458e_89168_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88784_89219_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/b\/b\/1dbb3ae76a839c91ad39e3e30d624206277117d2_88784_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88782_89169_83853",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/1\/d\/a91d121f5b00c45a7ef0c879398e8e52a7af0f95_88782_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/7\/8\/27786fbc15d6038973aa57d890092d7cc767aa56_83853_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201303_201207_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/3\/8\/68381b2718ceafd55485bf340a38d35201cb3676_201303_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201249_201245_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/b\/1c7b6e89389af90876ade5bf9c027a5e43c40dd4_201249_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200083_200079_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/5\/41f5469f9bd6c6b61d7fa5941f9abde767a95c12_200083_200079_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200083_200078_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/a\/2\/50a2a7f7e56bf5b03ee0c932edf0c3040f618a82_200083_200078_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_89218_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/4\/0\/0f4029eacb978babc77763bd136020b886e18ea1_89219_89218_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89219_88784_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/6\/f7f60e70e0bf0cf59f7713272b0a377a5b0a5930_89219_88784_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89209_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/0\/2e60999086a843a592d21b3f6d37fed8680b8add_89213_89209_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89213_89208_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/d\/cc0d35373bcf1a566f7ea5f2067be5c6bc3cb27e_89213_89208_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89191_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/f\/9f1f293a1208f8c1251338b787c40b1e577a1781_89195_89191_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89195_89190_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/3\/6\/d43628ea9ed3d4de282e32f1a57370b1cb95ada2_89195_89190_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_89168_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/d\/c\/91dcf4f23234c708fff7a0d6b1dd3f664d4a7834_89169_89168_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89169_88782_83852",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/d\/3\/34d3397dfd55dba078bfd05f35b8bc1e1980cc5c_89169_88782_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/2\/8\/e828f04670e6c552fe03262d57ac174e8af8904a_83852_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201245_201246_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/1\/3\/da1380971aed640cb314a1a42f02f8de2504d053_201245_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201207_201300_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/8\/d\/ae8d5012a52197ec5b956db63c5cb5c7f8594596_201207_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200249_200250_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/1\/d641333dec1034e75b2835abec1b380bce61d567_200249_200250_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200079_200080_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/b\/3\/37b3b23fc6d7ae8890b34d08a4ccf64345751522_200079_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200078_200080_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/7\/2\/707291a6ac0379823d49de403ec8535b5a0a6033_200078_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200060_200061_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/6\/1\/7e61419f522e9e4e4c9c3b940d68a7ef56e91233_200060_200061_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200041_200036_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/e\/f\/d8efe3ed0ab73131518a663ccbf9c9854870afd5_200041_200036_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65549_65544_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/3\/f\/a53f3df5e3ac0fe5144f9e6c0cfe7ad294e9f66e_65549_65544_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65541_65536_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/d\/f7fd48fca4f5305d0a22bf8600d2229e7bf2daad_65541_65536_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65523_65518_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/6\/7\/11678ed19b1d7954ca64b93ccb361608d1062d7f_65523_65518_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65499_65494_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/0\/9b501afac9c756c8849ad7b4b66c829e0f301a8d_65499_65494_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89218_88776_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/5\/4c856130551b98e25c70e6c15bb8cf5d600602f3_89218_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89209_89210_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/7\/d\/6a7d48ba90ddb3c724fda027a33874bb70453b9c_89209_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89208_89210_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/f\/8\/e2f82314b2e476c062ec4671f2d700b67a47a1eb_89208_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89200_89201_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/0\/2\/930253d4ea38f0b58a0d4b6ae8698de8b0bfbf47_89200_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89199_89201_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/8\/f\/0a8f360ad0fa502670efd27770a64ee0f38e1322_89199_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89191_89192_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/7\/6\/897671e7d97ad4833f354b14953e9cb9a86fd765_89191_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89190_89192_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/4\/6\/c846b12ce269b5d911f9aefa0cd6be6cb7c5fd3c_89190_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89168_88770_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747049617a5af185b8b951b3c4195b7f682b587_89168_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88784_88776_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/4\/c9d46c89a0576dd348e9929e8692bcf89135ce33_88784_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88782_88770_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/f\/6\/e8f66534664bd497fe4bf65565de1a742a0912bf_88782_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83713_83714_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/9\/afc98fd712744419e3b8c435d1c27756ccbdd037_83713_83714_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83706_83707_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/0\/f\/320f935a08ad033f6c925994a69f20ae547f03c2_83706_83707_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83699_83700_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/d\/3\/12d3f9587550204fa79c1043733fd69f0956be08_83699_83700_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83692_83693_83851",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/f\/819fbe9b15d62eb0ba3d1b0071cb98c17fc1679e_83692_83693_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/8\/d\/358d8cc1b83e980b96bdc4adb762b467d718aee0_83851_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201300_201207_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201246_201245_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200250_200249_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200080_200079_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200080_200078_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200061_200060_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200036_200041_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65544_65549_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65536_65541_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65518_65523_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65494_65499_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89209_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89210_89208_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89200_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89201_89199_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89191_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89192_89190_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_89218_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88776_88784_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_89168_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88770_88782_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83714_83713_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83707_83706_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83700_83699_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83693_83692_83850",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/8\/77788efc4b0fffe68f0043d4fe034303b9fb4b4b_83850_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201299_201303_201299_83848",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/9\/8d8988a286a29012f459d2de7887ed5671efe161_201299_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/b\/6\/98b6c9fef1487e01ae5eee2f80bb9d038d1e5c39_83848_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201244_201249_201244_83848",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/9\/d\/fd9d15334529715e31c4a6be96631aa5cca49624_201244_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/b\/6\/98b6c9fef1487e01ae5eee2f80bb9d038d1e5c39_83848_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201299_201302_201299_83847",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/b\/185bb4d22ece273f4a872459f848252f4e965cf4_201299_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/8\/2\/fe823a6e2069a4d2767e41a3df95e588cb5e3597_83847_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201244_201248_201244_83847",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/d\/5\/26d5125a24ee4b5aef4db1041849060e12ba5147_201244_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/8\/2\/fe823a6e2069a4d2767e41a3df95e588cb5e3597_83847_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201299_201301_201299_83846",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/8\/5\/788597dbe1123a4692a91834312283d34ee42af9_201299_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/2\/5\/db2567e2eb6a72909f5e909bf843a238f36e0f68_83846_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201244_201247_201244_83846",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/e\/2\/d4e235511ef46a5471ee425ef05562f4d600dce6_201244_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/b\/2\/5\/db2567e2eb6a72909f5e909bf843a238f36e0f68_83846_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201299_201300_201299_83845",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/d\/b\/3fdb5c9730781388e8584eb575c410d6af9e4a16_201299_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/0\/e\/e20e3a834944ed0d9b68de75ffce7a91784f1601_83845_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201244_201246_201244_83845",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/4\/5\/b345dc7d3fc6b5d24755fec39d708062a2719a99_201244_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/0\/e\/e20e3a834944ed0d9b68de75ffce7a91784f1601_83845_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble de salle de bains suspendu 135 x 52,5 cm ch\u00eane brut avec Diva vasque centr\u00e9e en marbre composite brillant",
      "sku": "89189_89192_89189_83845",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/d\/0\/f3d0d07db7cf1347bf2ce341b5df2c55858d34dc_89189_89192_89189_tw1350tc350_650_350tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/0\/e\/e20e3a834944ed0d9b68de75ffce7a91784f1601_83845_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Scuro Double vasque im quartz",
      "sku": "201300_201299_201300_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/4\/8\/83482b676f0a8f84043da5ffae049c5e21f3c751_201300_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Scuro Double vasque im quartz",
      "sku": "201246_201244_201246_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/6\/8\/16680c0f41cf8938abcd95a0507327cd2ce98dae_201246_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Scuro double vasque en",
      "sku": "200250_200248_200250_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/3\/83c3c699c90ab7feb8be968da560a9827b684cb1_200250_200248_200250_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noyer avec Scuro double vasque en",
      "sku": "200080_200077_200080_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/b\/b\/11bb8943cff6f7ec76f7c420296f70fb3eb3bde0_200080_200077_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noyer avec Scuro double vasque en",
      "sku": "200080_200076_200080_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/3\/0\/50308444aecfb9f8a295baa05a712c4b326b7204_200080_200076_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noyer avec Scuro double vasque en",
      "sku": "200061_200059_200061_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/e\/7\/0ae780af7ecfd146073e799114cdd4d2132f130e_200061_200059_200061_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble de salle de bains suspendu 165 x 52,5 cm blanc mat avec Scuro double vasque en quartz mat",
      "sku": "89210_89207_89210_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/7\/4\/b77422b68c28b49c9b32b6ee7509d623a12df137_89210_89207_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "89210_89206_89210_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/3\/6\/0636cef3cd9a179ae8ee172a96b7644e612a5da7_89210_89206_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "89201_89198_89201_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/0\/c\/c20c4409473ec9d97f590ea51a37c1b6814c6755_89201_89198_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "89201_89197_89201_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/b\/1\/f8b158265f284b854af257fae8ce74bca9a695d4_89201_89197_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "89192_89189_89192_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/7\/e\/4a7e8ad5b8e1af444dd7c40d58cc86a6d42a5f1c_89192_89189_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "89192_89188_89192_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/4\/f\/ff4f9b66184be75d0e7249d0e56c278dd7dac384_89192_89188_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Scuro double vasque en quartz",
      "sku": "88776_89217_88776_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/6\/9\/8f6983cb3fdf75dfc4c41711102e1afa23777a85_88776_89217_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Scuro double vasque en quartz",
      "sku": "88776_89216_88776_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/1\/8\/1d18d4d284a3013654cb1ca87765b356fc0f8a0c_88776_89216_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "88770_89167_88770_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/3\/90f327a8d6da96ec0186573210b6bf61525dfe0b_88770_89167_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "88770_89166_88770_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/b\/1\/f0b144eb141ada04c97d9b6dc265f8a8bf27c4f4_88770_89166_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Scuro double vasque en quartz",
      "sku": "83714_83712_83714_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/9\/4\/ec9420abee31145c4718a961b2e2b1fb4a975d34_83714_83712_83714_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Scuro double vasque en quartz",
      "sku": "83707_83705_83707_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/2\/d252ab47fc1ce3f508d77feb3a8298baf4a210b5_83707_83705_83707_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Scuro double vasque en quartz",
      "sku": "83700_83698_83700_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/2\/4\/7\/4247d93b2e827ef16c81ff9db0bcb7cfdc44cab6_83700_83698_83700_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro double vasque en quartz",
      "sku": "83693_83691_83693_83743",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/8\/4b3888959df9b87c37a7466b8b5541e9397586fb_83693_83691_83693_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/5\/1\/445153eb1098390813ae3f8130bc15914e3240e4_83743_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201299_201303_201299_83742",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/9\/8d8988a286a29012f459d2de7887ed5671efe161_201299_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/2\/3\/552325295cd765a15c5159bee16f3cdb1eeafe18_83742_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201244_201249_201244_83742",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/9\/d\/fd9d15334529715e31c4a6be96631aa5cca49624_201244_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/2\/3\/552325295cd765a15c5159bee16f3cdb1eeafe18_83742_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201299_201302_201299_83741",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/b\/185bb4d22ece273f4a872459f848252f4e965cf4_201299_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/2\/5\/5\/4255225409339919f578bf70f1f38b6faba6fce9_83741_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201244_201248_201244_83741",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/d\/5\/26d5125a24ee4b5aef4db1041849060e12ba5147_201244_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/2\/5\/5\/4255225409339919f578bf70f1f38b6faba6fce9_83741_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201299_201301_201299_83740",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/8\/5\/788597dbe1123a4692a91834312283d34ee42af9_201299_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/c\/b0bce6406aad1f05423994e1ef526699bcc6bf55_83740_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201244_201247_201244_83740",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/e\/2\/d4e235511ef46a5471ee425ef05562f4d600dce6_201244_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/c\/b0bce6406aad1f05423994e1ef526699bcc6bf55_83740_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201299_201300_201299_83739",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/d\/b\/3fdb5c9730781388e8584eb575c410d6af9e4a16_201299_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/8\/f408e39d1184bb2b173a955a525d28a7913b7af9_83739_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201244_201246_201244_83739",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/4\/5\/b345dc7d3fc6b5d24755fec39d708062a2719a99_201244_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/8\/f408e39d1184bb2b173a955a525d28a7913b7af9_83739_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201299_201208_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/f\/a\/4bfaa15fac24ae40019c3b9afb0e63aa551b1c65_201299_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201244_201250_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/8\/e\/0c8e5391945ab3d4a1755f36a811458ccf09ef1c_201244_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200248_200253_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/0\/cc001d491f1a3b61eb5a816e38470016fb93c7c0_200248_200253_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200077_200084_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/d\/2\/1bd22b3122fdf8486314cfb7012210317b4993a6_200077_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200076_200084_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/d\/d\/8eddddcd1f09783a96e5b30ec7af7614c2be5424_200076_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200059_200064_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/0\/b\/8c0bd021dde4fa3109ba058b4c97c76d9ae6f2d8_200059_200064_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89217_88779_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/b\/0\/a4b0b304e2d272a4aa8d67755765528fb016414e_89217_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89216_88779_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/b\/1e0bda70c073165a859284e6d97945c97960ad9c_89216_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89207_89214_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/3\/6\/5e36aa6a26e7467a0f888e9515ece5431e92421a_89207_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89206_89214_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/2\/d77248a5fd4cae9bd939eddb80254ad275b0810e_89206_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89198_89205_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/0\/6640fcace08104eb24b61ccad77b63e4005cb020_89198_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89197_89205_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/1\/c9d13cd5a563071322643259498955d7ff359ee6_89197_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89189_89196_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/a\/e\/a7ae23a381149876eb4d4297b986a1a016d518ec_89189_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89188_89196_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/6\/9\/6769ea58d12b67c4592bf288c2a3b40f2d5e3ac1_89188_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89170_89178_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/c\/3\/cdc3baf1272bf3ca45ae26f0e03e341a484047b0_89170_89178_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89167_88773_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/1\/c\/271c2f8eb4a8fb01ae4e64f30a5f517fd1e6bf39_89167_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89166_88773_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/b\/3\/eab37aa5f1a5006a4a9d88a5dc2c4940cd2b3202_89166_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83712_83717_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/6\/9\/4e697b78446e037700f8f0417c51c277ee06e861_83712_83717_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83705_83710_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/f\/aaefe601d35ae60a89162891fd6ff8764dc37cf5_83705_83710_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83698_83703_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/f\/6\/e3f63275a2017f896625c45bb357c40162f12367_83698_83703_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83691_83696_83738",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/0\/c4b090ba920b0feccad23d14ba0534bab105bebe_83691_83696_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/a\/a\/6eaa596576346aba41a6542a6723a25216e124bc_83738_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201250_201244_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/2\/75b21f65605a0effcdf12a10c52950416eb27bdd_201250_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201208_201299_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/2\/5\/e325b3769ea0bd42871e3165b1f145d01a403b5e_201208_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200253_200248_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/0\/e\/1\/10e1541cd8d8408839dfd3c80e0d7515f51e56d1_200253_200248_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200084_200077_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/5\/4\/fa548b1e8eabfa5c9d4c53dfcedbd20e2fb8b8e2_200084_200077_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200084_200076_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/b\/64dbb8b471bde4564662f465affa866be43ce457_200084_200076_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200064_200059_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/2\/3\/3e233947e07c626d28ed8d3340007bc150efebac_200064_200059_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89214_89207_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/9\/c\/849c6589c337e12d6015a890497b6380ee89210c_89214_89207_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89214_89206_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/c\/c\/90cc9f0d7d1e8f9958116146d49ee91af6d0f96d_89214_89206_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89205_89198_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/1\/4b316f0520b097247dad9473528a5d4d3f6762c2_89205_89198_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89205_89197_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/a\/0\/c3a0622f33e13adcdfa68a9bdd34c0208dda11ec_89205_89197_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89196_89189_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/6\/2\/f9629dee1ca72bf827e4eafd3be0586abd423b07_89196_89189_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89196_89188_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/c\/41fc39ea98df749e1bdc53b4a2d0544eae6065a9_89196_89188_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89178_89170_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/e\/9\/cae9c5141571fa91e3f9afef133b12ca02364d86_89178_89170_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88779_89217_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/6\/f\/006fe865a4832c769a7efbee904bc0dc559bbc1a_88779_89217_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88779_89216_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/c\/819c60fd262ce4e08040c2daad38b747920f7e92_88779_89216_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88773_89167_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/b\/a79b03be0a4bec4d1fb23f956f676ced5d5ff2f0_88773_89167_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88773_89166_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/8\/9f1819710fd5fe52824644b219f1c236da736d15_88773_89166_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83717_83712_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/9\/d8894ad63bd4098accb4f7fb778442f68a87c24e_83717_83712_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83710_83705_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/9\/6\/589692f791af641ea7b66f16a1871f9e1a1e337a_83710_83705_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83703_83698_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/9\/0\/b490474d462123a53a5ef71eb3d34b483cbbb01c_83703_83698_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83696_83691_83737",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/c\/b61c9944016574b3a58ce8f53e52af553c8b9ff5_83696_83691_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/2\/0\/bc20073b9efba658ffd8e54c90480ba99cdd7bf7_83737_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201299_201303_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201244_201249_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200077_200083_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200076_200083_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89217_89219_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89216_89219_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89207_89213_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89206_89213_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89189_89195_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89188_89195_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89167_89169_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89166_89169_83736",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/0\/e\/760e72aedfd1798299101bf241c3c4bc195b96cb_83736_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201303_201299_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201249_201244_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200083_200077_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200083_200076_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89219_89217_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89219_89216_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89213_89207_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89213_89206_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89195_89189_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89195_89188_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89169_89167_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89169_89166_83735",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/6\/3\/8363a2131d2f26c4670546a1f8e18d9429ee7dde_83735_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201299_201302_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/e\/1\/bae1157a18b665e8a44b4d7ee8f5514d6a206bc3_201299_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201244_201248_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/c\/1\/67c1e79ea3cf96103f76aa7c934d8e4d60029c97_201244_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200248_200252_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/9\/f\/1b9f89d3093630b23e9a9eeb8e83824cd27ce063_200248_200252_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200077_200082_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c16fc8909bdb0ca0dee55967c63802d52ffc4_200077_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200076_200082_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/7\/b\/967bfd1b6ed4ef6db7d034369d193f8a9dcd6020_200076_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200059_200063_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/5\/a\/6d5a1f2c5eed5d32f10ac4adc9aa40d88b2d6536_200059_200063_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89217_88778_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/f\/f\/36ff7779f923b9ce23f9d4856c28a27d078ce646_89217_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89216_88778_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/e\/e\/a7eea02b2d949673e60a081b23c761d602e87f40_89216_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89207_89212_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/e\/2\/fde2941a3d429e6d5a28d0b970dd49f071c76326_89207_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89206_89212_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/3\/6\/d036755c28735b3043f653830bfcb0df37ad571b_89206_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89198_89203_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/9\/adb96f308c6e8838cccf326de882d2fff70a7ccd_89198_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89197_89203_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/1\/d3015f2d3084c89890871b5e1a13047c3623e119_89197_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89189_89194_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/f\/9b5fd09e98bf8a735b8f42bd22feea432ae99247_89189_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89188_89194_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/c\/668ca7f5c6b5f56c366b07729af8132d07420828_89188_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89167_88772_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/e\/a\/0ceaecf390d6fb5e2d1f3d4eec198799b513f6b5_89167_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89166_88772_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/8\/5\/73852532f5065c1a77d5943b56edcfcf020d6437_89166_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83712_83716_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/d\/8\/99d8af7c8978c461dc191f3b519b047b6c2162d4_83712_83716_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83705_83709_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/0\/d770143be08577f153c499a4568026b7dacafca2_83705_83709_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83698_83702_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/5\/1\/79511076498d29aa28cf4a4cf734191a2017e0ec_83698_83702_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83691_83695_83734",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/7\/b\/4d7b38e07a26746a6ff93c197a311ef28d1e8647_83691_83695_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/5\/aae514f59205dacad596e198f0069621e1491b19_83734_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201302_201299_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201248_201244_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200252_200248_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200082_200077_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200082_200076_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200063_200059_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89212_89207_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89212_89206_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89203_89198_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89203_89197_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89194_89189_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89194_89188_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88778_89217_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88778_89216_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88772_89167_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88772_89166_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83716_83712_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83709_83705_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83695_83691_83733",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/c\/d\/2dcd18548a7b926fdd6f56738c632d93f9a25883_83733_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201299_201301_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/7\/8\/9f78585b31ee941f4ae2f02fe3120b48ed2ad3d6_201299_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201244_201247_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/8\/8\/94883ee602d473e77c8587078863bcf8d0869348_201244_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200248_200251_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/2\/5162c8c3335aae95debc4f51258c6ebfb2e94377_200248_200251_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200077_200081_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/2\/9ed2aa8c501ef24bdfa84155c167e368a16af6ac_200077_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200076_200081_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/b\/7\/6db7b1c5c0465b531ad5d811fea5bc374b5558f5_200076_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200059_200062_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/3\/9\/a739587676250e5cc2220d563745dc01890e61ab_200059_200062_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89217_88777_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/5\/5\/eb55e5290656bb4756a060541e49572215a96d9e_89217_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89216_88777_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/e\/6\/2\/de62c52dcc32f13c8a31ce3c6bcd56d073f0ceab_89216_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89207_89211_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/d\/2\/e5d28fe05b048f5ae60358b7ff825c9b6c6a3492_89207_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89206_89211_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/1\/e\/711e4587323d85ec01212a4ee6fe6c003b13baa5_89206_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89198_89202_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/f\/1\/67f1302c73f3c9de5fd86d490d44d9de867f3cef_89198_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89197_89202_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/3\/2\/8e32e611e68aaa424f2366ab5bdd39ede12fca11_89197_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89189_89193_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/0\/b\/7b0b915eb4fbdb4408851d652be4e1b293425f35_89189_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89188_89193_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/3\/5\/00352dbd8a04c904226eb5b9758e08d2fc12dabc_89188_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89167_88771_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/7\/c96754caeff4a52bd18aed4e097d51fe61d1d8d6_89167_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89166_88771_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/1\/9\/8\/d198489bd8be4a3ec682d726d61c5a36c0f43e80_89166_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83712_83715_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/d\/3\/a5d336df9d4bb8517dd1a7aae0c36bcfa9cb9f05_83712_83715_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83705_83708_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/3\/c\/f13c8dd17a75f3b9bb4952f51f7e2589aaff693d_83705_83708_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83698_83701_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/d\/2\/62d2a84ece6db17652b9f9cc9fa1cb5e9dd4198c_83698_83701_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83691_83694_83732",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/e\/8d3e0c198c08b7c53cbf4a33b4597412bf8c0d4c_83691_83694_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/7\/9\/7279ade1a56f18f20e0bc83ef72397637e3c20f1_83732_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201301_201299_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/5\/e\/f55ee343058d9af2e7d02ffa077c1eed0612bf4c_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201247_201244_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/4\/f\/df4f03520540b740cdf5c0c7b1b9f812be9b6133_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200251_200248_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/a\/7\/75a7f1d1d92e5d2292b043d072b8518d85432499_200251_200248_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200081_200077_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/3\/2\/35320320f81160b02572e3981292abc2fcb620f2_200081_200077_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200081_200076_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/4\/5\/8845d22b8429e2ccef487449773d93d81629d7a5_200081_200076_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200062_200059_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/7\/f\/937f629c34f492fc2ad34d8818e50989c67b2aa0_200062_200059_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89211_89207_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c03b07acfdd0903ef6ab3d9befc7782c8a166_89211_89207_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89211_89206_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/c\/d\/61cda3c0081bd7289e241086f87754cae6f4793a_89211_89206_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89202_89198_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/0\/a\/b80ae82e403b02c2af95bbebb06c5fb3b2606e00_89202_89198_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89202_89197_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/b\/c\/bcbc4b141a7b8920fcde46ec265988020f30e507_89202_89197_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89193_89189_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/f\/7\/06f7a7d8c1a8a4206997756a7b75e0f95bfd9cde_89193_89189_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89193_89188_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/3\/46f34d3e7b895fec28c36fc3f66e08b2280d54a0_89193_89188_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88777_89217_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/b\/4\/72b47435eb12a80e36135b26a7f87e55ab9392c1_88777_89217_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88777_89216_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/2\/ddb285284a7bf154f26e2e93105fdcad01f98255_88777_89216_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88771_89167_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/2\/6\/98262a25a1942061a4e504b1f5f12d2fa5c0046d_88771_89167_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88771_89166_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/e\/5\/14e5c4368dc23734d2456bcf0375fc740bf3cdf0_88771_89166_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83715_83712_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/2\/d642c1a3d848be9df06e7351ee5b8bde6414e4f4_83715_83712_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83708_83705_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/2\/0a420ef1a77fbf5974c2e465faff86605f1e3e9e_83708_83705_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83694_83691_83731",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/4\/0\/e640dbe51a233ae693fa544ad7e714aef64be01f_83694_83691_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/6\/f\/ae6f06d63e9688f1a5aa4ad9acf6fa8403a802d7_83731_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201299_201300_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/c\/8\/60c8b568cabb83005a042df2a26926c933daf0f5_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201244_201246_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/0\/1\/8501b375db62e4238b823fcd8a4c8cd941c5165b_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200248_200250_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/9\/7\/1\/49711bf482bd4f024ab8c3e8103deb3d4f0cd71e_200248_200250_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noyer avec Diva vasque asym\u00e9trique droite en marbre composite brillant",
      "sku": "200077_200080_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/b\/b10bd3cadfff8acc953c436ad6ef374fc1eabe70_200077_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200076_200080_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/e\/0\/93e050355772a2572d374b2e4eed0da90a33525c_200076_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200059_200061_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/f\/f\/b3ffe351e86a88fb2e77ed7bac704dfed44b9ff0_200059_200061_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89217_88776_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/e\/ddbe430502c7d936a275599097a49da815618ce1_89217_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89216_88776_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/e\/2\/eae22decc498c46aeb65d0e59447fc0c63bc1144_89216_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89206_89210_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/9\/f\/c89fc70f8b1f3024768aa6938e7978408dec119e_89206_89210_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89198_89201_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/1\/d\/a41d2b54a81e02443f9a80d2a029aa73d9e78b2b_89198_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89197_89201_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/e\/f36ecb4b524508068b92073f9994587b6d9cbf3f_89197_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89189_89192_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/9\/8\/8a98e7dbec16dbd36342cd5cdfa2965887b3d24d_89189_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89188_89192_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/f\/1\/eef106d0c31a045b50bd4c965c0a4f4b1d6d6447_89188_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89167_88770_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/7\/5287ced70a6f0a96e5db3ab78b9fdbd69711e9d1_89167_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89166_88770_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/f\/5\/16f5403217733e30da585271070878fc7bc7943c_89166_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83712_83714_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/0\/0\/8600c05216071bd31015bc459573e65fb072a2ed_83712_83714_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83705_83707_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/5\/e\/d95eeb83256ff7b3228cfe0c87c9a756f6accb94_83705_83707_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83698_83700_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/c\/2\/0fc20293af0566a0b0f6b798e7afc57d25e6ffde_83698_83700_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83691_83693_83730",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/d\/b\/b4db4726839a5d08f9e1218be318608d72cc73e6_83691_83693_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/2\/a\/fd2a87d53016c305a5cfbe971963b9b349841a05_83730_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201300_201299_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/0\/18503f9f0ceb721779d958c0fc36cde3d10d5bf9_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201246_201244_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/c\/6\/28c66862bfe84cd2aff1c53f3bfa9247e4c99824_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200250_200248_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/9\/4\/f694a833810f1e224089213ae5043d8bd147e3d9_200250_200248_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200080_200077_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/f\/1\/faf1307c37f2752fcd895a54de494a0504d84fa8_200080_200077_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200080_200076_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/a\/819a23093b71e8740db85b9f87979da37395af1a_200080_200076_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200061_200059_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/7\/a36770d4415c0c4a463ca30fd14ddfa6c64b6531_200061_200059_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89210_89207_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/e\/2\/c7e2bfec7f91b613b59f14ccbb74c60d20bba7ff_89210_89207_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89210_89206_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/7\/2\/0a722b841ae53a7ff8a70192ad8c869b99ac647a_89210_89206_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89201_89198_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/3\/c\/ce3cbbb4428fbf5ded54d6a1e5bf108faabdc275_89201_89198_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89201_89197_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/8\/30c882cea76abdb9806dbf7a227bd6bb9e808441_89201_89197_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89192_89189_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/9\/7\/a297d0be7adb3e650c12f01040fcf2094e28ef7e_89192_89189_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89192_89188_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/1\/0\/1b1068afaf142dacaf7ad964cbd350257642eb96_89192_89188_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88776_89217_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/8\/5\/24850be38f793e7294c0c687c98a2aa8b6b668f8_88776_89217_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88776_89216_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/8\/2\/2882fd706340ec5be23072e42c0e0507f390a95e_88776_89216_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88770_89167_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/c\/7\/b3c7987dd04be9d59c5984acb7c807c7475c6051_88770_89167_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88770_89166_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/d\/6\/e0d6b66fbef4aff52ba9259ed661299f7a29f2f1_88770_89166_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83714_83712_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/3\/1\/7431b769c2cec24647afd3a7e396716bdbb4ed10_83714_83712_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83707_83705_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/1\/e\/321e7376a8cfbbe96454a76de8a5990ad1979564_83707_83705_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83700_83698_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/b\/a\/9\/aba9e50e67873ec7eea7b2b5e30eec968385009d_83700_83698_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83693_83691_83729",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/5\/5\/6655fdff1acbf082c2cecc81516f760bc86f2dd6_83693_83691_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/8\/6\/a286cb27db7506f969cc110ab9c2e13c0116cf1e_83729_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201245_201250_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201207_201208_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200249_200253_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200079_200084_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200078_200084_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200060_200064_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200041_200039_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65549_65547_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65541_65539_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65523_65521_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65509_65505_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65499_65497_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89218_88779_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89209_89214_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89208_89214_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89200_89205_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89199_89205_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89191_89196_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89190_89196_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89172_89178_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89168_88773_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88784_88779_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88782_88773_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83713_83717_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83706_83710_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83699_83703_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83692_83696_83728",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/e\/3\/65e3c4f4c41186e57324756d9a40876f789e5557_83728_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201250_201245_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201208_201207_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200253_200249_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200084_200079_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200084_200078_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200064_200060_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200039_200041_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65547_65549_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65539_65541_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65521_65523_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65505_65509_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65497_65499_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89214_89209_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89214_89208_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89205_89200_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89205_89199_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89196_89191_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89196_89190_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89178_89172_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88779_89218_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88779_88784_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88773_89168_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88773_88782_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83717_83713_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83710_83706_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83703_83699_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83696_83692_83727",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/2\/d\/742d782da568f0957aca43a7e653d4fff3229e6a_83727_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201245_201249_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/8\/8\/cc88395a6abc66afbafae0ce4d0565527f601d4d_201245_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201207_201303_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/9\/2\/e392d3592b8f9ee702ecb883f6b6bdc800531d8f_201207_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200079_200083_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/3\/0\/b9300dcde1d70d5319178f5302d729f1d77a7d1b_200079_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200078_200083_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/1\/1\/e\/c11e0ab083f5ec3aa241d366ac0f1d1789e13016_200078_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89218_89219_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/6\/9\/ba69d0efae2496cb814706801712ee43ffa1bec8_89218_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89209_89213_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/7\/0\/5b7079605f214fe8e737439a8edce286c41650bb_89209_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89208_89213_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/d\/8\/7ad8552839faf76426432b3d7b3c87f73e17d2dc_89208_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89191_89195_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/5\/2\/5b5246ae63e921b54cfc193e0959a1a57da6b478_89191_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89190_89195_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/9\/9\/029964d60222045c01c0f39abc7135c84ad544ef_89190_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89168_89169_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/2\/b\/ea2b4cd57ab1ab4acc2ad2ed99a15284daec458e_89168_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88784_89219_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/b\/b\/1dbb3ae76a839c91ad39e3e30d624206277117d2_88784_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88782_89169_83726",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/1\/d\/a91d121f5b00c45a7ef0c879398e8e52a7af0f95_88782_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/9\/5\/5295d870a257d0e82807201c1631fea83cbc2226_83726_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201303_201207_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/3\/8\/68381b2718ceafd55485bf340a38d35201cb3676_201303_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201249_201245_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/b\/1c7b6e89389af90876ade5bf9c027a5e43c40dd4_201249_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200083_200079_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/5\/41f5469f9bd6c6b61d7fa5941f9abde767a95c12_200083_200079_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200083_200078_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/a\/2\/50a2a7f7e56bf5b03ee0c932edf0c3040f618a82_200083_200078_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89219_89218_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/4\/0\/0f4029eacb978babc77763bd136020b886e18ea1_89219_89218_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89219_88784_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/6\/f7f60e70e0bf0cf59f7713272b0a377a5b0a5930_89219_88784_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89213_89209_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/0\/2e60999086a843a592d21b3f6d37fed8680b8add_89213_89209_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89213_89208_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/d\/cc0d35373bcf1a566f7ea5f2067be5c6bc3cb27e_89213_89208_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89195_89191_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/f\/9f1f293a1208f8c1251338b787c40b1e577a1781_89195_89191_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89195_89190_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/3\/6\/d43628ea9ed3d4de282e32f1a57370b1cb95ada2_89195_89190_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89169_89168_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/d\/c\/91dcf4f23234c708fff7a0d6b1dd3f664d4a7834_89169_89168_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89169_88782_83725",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/d\/3\/34d3397dfd55dba078bfd05f35b8bc1e1980cc5c_89169_88782_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/b\/2\/03b2c70ccf95de4c71ab1fe26bfe880dc84bb1bd_83725_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201245_201248_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201207_201302_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200249_200252_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200079_200082_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200078_200082_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200060_200063_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200041_200038_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65549_65546_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65541_65538_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65523_65520_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65499_65496_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89218_88778_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89209_89212_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89208_89212_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89200_89203_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89199_89203_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89191_89194_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89190_89194_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89168_88772_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88784_88778_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88782_88772_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83713_83716_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83706_83709_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83699_83702_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83692_83695_83724",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/2\/9\/3129d4d6322a03eb0998dff60d40047a1671d931_83724_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201302_201207_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201248_201245_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200252_200249_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200082_200079_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200082_200078_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200063_200060_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200038_200041_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65546_65549_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65538_65541_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65520_65523_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65496_65499_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89212_89209_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89212_89208_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89203_89200_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89203_89199_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89194_89191_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89194_89190_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88778_89218_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88778_88784_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88772_89168_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88772_88782_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83716_83713_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83709_83706_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83695_83692_83723",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/b\/3d5b4f1bfa85c70ed0ea5e6946c09850dd64b548_83723_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201245_201247_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201207_201301_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200249_200251_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200079_200081_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200078_200081_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200060_200062_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200041_200037_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65549_65545_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65541_65537_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65523_65519_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65515_65511_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65499_65495_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89218_88777_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89209_89211_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89208_89211_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89200_89202_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89199_89202_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89191_89193_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89190_89193_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89168_88771_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88784_88777_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88782_88771_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83713_83715_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83706_83708_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83699_83701_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83692_83694_83722",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/6\/6\/8166f6bc77fe9d7f4290300153d50424bae0a8fc_83722_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201301_201207_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201247_201245_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200251_200249_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200081_200079_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200081_200078_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200062_200060_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200037_200041_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65545_65549_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65537_65541_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65519_65523_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65511_65515_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65495_65499_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89211_89209_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89211_89208_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89202_89200_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89202_89199_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89193_89191_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89193_89190_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88777_89218_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88777_88784_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88771_89168_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88771_88782_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83715_83713_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83708_83706_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83694_83692_83721",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/5\/a\/585a5428be4d9134926b8671737fe75b54ae2104_83721_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201245_201246_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/1\/3\/da1380971aed640cb314a1a42f02f8de2504d053_201245_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Diva Vasque asym\u00e9trique droite im marbre composite",
      "sku": "201207_201300_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/8\/d\/ae8d5012a52197ec5b956db63c5cb5c7f8594596_201207_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique droite en",
      "sku": "200249_200250_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/1\/d641333dec1034e75b2835abec1b380bce61d567_200249_200250_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200079_200080_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/b\/3\/37b3b23fc6d7ae8890b34d08a4ccf64345751522_200079_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200078_200080_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/7\/2\/707291a6ac0379823d49de403ec8535b5a0a6033_200078_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200060_200061_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/6\/1\/7e61419f522e9e4e4c9c3b940d68a7ef56e91233_200060_200061_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm noyer avec Diva vasque asym\u00e9trique droite en",
      "sku": "200041_200036_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/e\/f\/d8efe3ed0ab73131518a663ccbf9c9854870afd5_200041_200036_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65549_65544_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/3\/f\/a53f3df5e3ac0fe5144f9e6c0cfe7ad294e9f66e_65549_65544_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65541_65536_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/d\/f7fd48fca4f5305d0a22bf8600d2229e7bf2daad_65541_65536_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65523_65518_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/6\/7\/11678ed19b1d7954ca64b93ccb361608d1062d7f_65523_65518_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "65499_65494_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/0\/9b501afac9c756c8849ad7b4b66c829e0f301a8d_65499_65494_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89218_88776_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/5\/4c856130551b98e25c70e6c15bb8cf5d600602f3_89218_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89209_89210_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/7\/d\/6a7d48ba90ddb3c724fda027a33874bb70453b9c_89209_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89208_89210_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/f\/8\/e2f82314b2e476c062ec4671f2d700b67a47a1eb_89208_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89200_89201_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/0\/2\/930253d4ea38f0b58a0d4b6ae8698de8b0bfbf47_89200_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89199_89201_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/8\/f\/0a8f360ad0fa502670efd27770a64ee0f38e1322_89199_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89191_89192_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/7\/6\/897671e7d97ad4833f354b14953e9cb9a86fd765_89191_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89190_89192_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/4\/6\/c846b12ce269b5d911f9aefa0cd6be6cb7c5fd3c_89190_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "89168_88770_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747049617a5af185b8b951b3c4195b7f682b587_89168_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88784_88776_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/4\/c9d46c89a0576dd348e9929e8692bcf89135ce33_88784_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "88782_88770_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/f\/6\/e8f66534664bd497fe4bf65565de1a742a0912bf_88782_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83713_83714_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/9\/afc98fd712744419e3b8c435d1c27756ccbdd037_83713_83714_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83706_83707_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/0\/f\/320f935a08ad033f6c925994a69f20ae547f03c2_83706_83707_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83699_83700_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/d\/3\/12d3f9587550204fa79c1043733fd69f0956be08_83699_83700_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique droite en marbre composite",
      "sku": "83692_83693_83720",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/f\/819fbe9b15d62eb0ba3d1b0071cb98c17fc1679e_83692_83693_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/c\/c\/e\/5cce2195b447837ba100bbde9f72b15c7dad219f_83720_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201300_201207_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Diva Vasque asym\u00e9trique gauche im marbre composite",
      "sku": "201246_201245_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200250_200249_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200080_200079_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200080_200078_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200061_200060_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm noyer avec Diva vasque asym\u00e9trique gauche en",
      "sku": "200036_200041_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65544_65549_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65536_65541_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65518_65523_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "65494_65499_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89210_89209_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89210_89208_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89201_89200_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89201_89199_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89192_89191_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "89192_89190_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88776_89218_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88776_88784_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88770_89168_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "88770_88782_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83714_83713_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83707_83706_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83700_83699_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva vasque asym\u00e9trique gauche en marbre composite",
      "sku": "83693_83692_83719",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/b\/a\/71ba7343f2c1bc1e2290046d5d0969ca1de61991_83719_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201245_201250_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201207_201208_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200249_200253_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200079_200084_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200078_200084_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200060_200064_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200041_200039_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65549_65547_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65541_65539_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65523_65521_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65509_65505_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65499_65497_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89218_88779_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89209_89214_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89208_89214_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89200_89205_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89199_89205_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89191_89196_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89190_89196_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89172_89178_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89168_88773_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88784_88779_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88782_88773_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83713_83717_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83706_83710_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83699_83703_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83692_83696_83689",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/6\/6\/086604a9e36c4a3636d3dcaf5f4ae520a2b02256_83689_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201245_201248_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201207_201302_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200249_200252_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200079_200082_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200078_200082_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200060_200063_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200041_200038_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65549_65546_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65541_65538_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65523_65520_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65499_65496_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89218_88778_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89209_89212_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89208_89212_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89200_89203_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89199_89203_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89191_89194_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89190_89194_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89168_88772_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88784_88778_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88782_88772_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83713_83716_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83706_83709_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83699_83702_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83692_83695_83688",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/4\/a\/c74ad9792f72e3d3bd9e7d0f0fc6db84275161db_83688_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201245_201247_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro Vasque asym\u00e9trique droite im quartz",
      "sku": "201207_201301_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200249_200251_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200079_200081_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200078_200081_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200060_200062_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique droite en",
      "sku": "200041_200037_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65549_65545_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65541_65537_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65523_65519_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65515_65511_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "65499_65495_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89218_88777_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89209_89211_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89208_89211_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89200_89202_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89199_89202_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89191_89193_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89190_89193_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "89168_88771_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88784_88777_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "88782_88771_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83713_83715_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83706_83708_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83699_83701_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique droite en quartz",
      "sku": "83692_83694_83687",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/8\/c\/758c2e4473972a1c6efc66460b6f2068eac209de_83687_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Mata Double vasque im solid surface",
      "sku": "201300_201299_201300_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/4\/8\/83482b676f0a8f84043da5ffae049c5e21f3c751_201300_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Mata Double vasque im solid surface",
      "sku": "201246_201244_201246_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/6\/8\/16680c0f41cf8938abcd95a0507327cd2ce98dae_201246_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm blanc brillant avec Mata double vasque en",
      "sku": "200250_200248_200250_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/c\/3\/83c3c699c90ab7feb8be968da560a9827b684cb1_200250_200248_200250_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Mata double vasque en",
      "sku": "200080_200077_200080_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/b\/b\/11bb8943cff6f7ec76f7c420296f70fb3eb3bde0_200080_200077_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Mata double vasque en",
      "sku": "200080_200076_200080_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/3\/0\/50308444aecfb9f8a295baa05a712c4b326b7204_200080_200076_200080_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52 cm noyer avec Mata double vasque en",
      "sku": "200061_200059_200061_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/e\/7\/0ae780af7ecfd146073e799114cdd4d2132f130e_200061_200059_200061_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "89210_89207_89210_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/7\/4\/b77422b68c28b49c9b32b6ee7509d623a12df137_89210_89207_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "89210_89206_89210_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/3\/6\/0636cef3cd9a179ae8ee172a96b7644e612a5da7_89210_89206_89210_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "89201_89198_89201_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/0\/c\/c20c4409473ec9d97f590ea51a37c1b6814c6755_89201_89198_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "89201_89197_89201_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/b\/1\/f8b158265f284b854af257fae8ce74bca9a695d4_89201_89197_89201_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "89192_89189_89192_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/7\/e\/4a7e8ad5b8e1af444dd7c40d58cc86a6d42a5f1c_89192_89189_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "89192_89188_89192_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/4\/f\/ff4f9b66184be75d0e7249d0e56c278dd7dac384_89192_89188_89192_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Mata double vasque en solid surface",
      "sku": "88776_89217_88776_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/6\/9\/8f6983cb3fdf75dfc4c41711102e1afa23777a85_88776_89217_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc brillant avec Mata double vasque en solid surface",
      "sku": "88776_89216_88776_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/1\/8\/1d18d4d284a3013654cb1ca87765b356fc0f8a0c_88776_89216_88776_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "88770_89167_88770_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/f\/3\/90f327a8d6da96ec0186573210b6bf61525dfe0b_88770_89167_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "88770_89166_88770_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/b\/1\/f0b144eb141ada04c97d9b6dc265f8a8bf27c4f4_88770_89166_88770_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm noir mat avec Mata double vasque en solid surface",
      "sku": "83714_83712_83714_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/9\/4\/ec9420abee31145c4718a961b2e2b1fb4a975d34_83714_83712_83714_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm blanc mat avec Mata double vasque en solid surface",
      "sku": "83707_83705_83707_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/2\/d252ab47fc1ce3f508d77feb3a8298baf4a210b5_83707_83705_83707_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm ch\u00eane brut avec Mata double vasque en solid surface",
      "sku": "83700_83698_83700_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/2\/4\/7\/4247d93b2e827ef16c81ff9db0bcb7cfdc44cab6_83700_83698_83700_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 165 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata double vasque en solid surface",
      "sku": "83693_83691_83693_83627",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/8\/4b3888959df9b87c37a7466b8b5541e9397586fb_83693_83691_83693_tw1650tc650_350_650tbpd_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/4\/4\/a84401dbc8c3893bff7c480e1fe49a71edf0b9b0_83627_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201299_201303_201299_83625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/9\/8d8988a286a29012f459d2de7887ed5671efe161_201299_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/7\/3\/2f7347208878a84baa78273bbb70660a6cb9392d_83625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 165 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201244_201249_201244_83625",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/0\/b\/710b24a76e73f25d932a93848d3ed37517adf59f_1650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/9\/d\/fd9d15334529715e31c4a6be96631aa5cca49624_201244_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/7\/3\/2f7347208878a84baa78273bbb70660a6cb9392d_83625_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "165cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201299_201302_201299_83624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/b\/185bb4d22ece273f4a872459f848252f4e965cf4_201299_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/d\/8\/f4d8e6f13a94361da06a424439083080218c04ee_83624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 155 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201244_201248_201244_83624",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/c\/2\/44c217d50612b98720ac2df37df99c8fcd2b6fb8_1550_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/d\/5\/26d5125a24ee4b5aef4db1041849060e12ba5147_201244_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/d\/8\/f4d8e6f13a94361da06a424439083080218c04ee_83624_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "155cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201299_201301_201299_83623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/8\/5\/788597dbe1123a4692a91834312283d34ee42af9_201299_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/d\/1\/e9d17d77d2a4eac53756ff8e03263ffe091df53b_83623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 145 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201244_201247_201244_83623",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/5\/6\/3c569e5e46edba927c1c1510ff105781985824db_1450_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/e\/2\/d4e235511ef46a5471ee425ef05562f4d600dce6_201244_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/d\/1\/e9d17d77d2a4eac53756ff8e03263ffe091df53b_83623_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "145cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201299_201300_201299_83622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/d\/b\/3fdb5c9730781388e8584eb575c410d6af9e4a16_201299_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/8\/94b84242547a5e75dc75d0e2b2fdefd71e0eba29_83622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 135 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201244_201246_201244_83622",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/6\/1\/f0618bff616ec852944b0aaf310d8c2ebf6299b8_1350_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/4\/5\/b345dc7d3fc6b5d24755fec39d708062a2719a99_201244_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/8\/94b84242547a5e75dc75d0e2b2fdefd71e0eba29_83622_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "135cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Diva Plan simple im marbre composite",
      "sku": "201739_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Diva plan simple en ",
      "sku": "201728_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Diva plan simple en ",
      "sku": "201721_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Diva plan simple en",
      "sku": "201714_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Diva Plan simple im marbre composite",
      "sku": "201303_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Diva Plan simple im marbre composite",
      "sku": "201249_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52 cm noyer avec Diva plan simple en",
      "sku": "200083_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52 cm noyer avec Diva plan simple en",
      "sku": "200057_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Diva plan simple en ",
      "sku": "91082_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Diva plan simple en ",
      "sku": "91081_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Diva plan simple en ",
      "sku": "91080_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "89219_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "89213_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "89195_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "89169_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Diva plan simple en marbre composite",
      "sku": "83594_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Diva plan simple en marbre composite",
      "sku": "83593_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Diva plan simple en marbre composite",
      "sku": "83592_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Diva plan simple en marbre composite",
      "sku": "83590_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Diva plan simple en marbre composite",
      "sku": "83587_83609",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/9\/f\/169fcbd153f8457ec4304f35e8576cc77adf43ca_83609_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "diva",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm soie grise mit Scuro Plan simple im quartz",
      "sku": "201739_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/c\/b\/8\/3cb8f6bde8d1d323930785af3cf6d02c6f1a4944_201739_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm soie grise avec Scuro plan simple en ",
      "sku": "201728_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/3\/5\/a\/435a1c6ba34fa5d989fcfe7349b0dbfdfbb865c5_201728_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm vert olive avec Scuro plan simple en ",
      "sku": "201721_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/d\/e\/e\/2dee1573c2b35c7a7a78ad1011425475aaef7e2a_201721_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm bleu nuit avec Scuro plan simple en ",
      "sku": "201714_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/b\/b\/30bb82b2a3a8fad65ae6ccfea65f8587196e152c_201714_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm noyer mit Scuro Plan simple im quartz",
      "sku": "201303_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/9\/6\/18960ada0875cc9f6c6c4677fd786aa16e0ab596_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 95 x 52  cm ch\u00eane noir mit Scuro Plan simple im quartz",
      "sku": "201249_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/6\/a\/6e6ae857d1978112cdaf5b97a4bc3289cbf69f76_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm noyer avec Scuro plan simple en",
      "sku": "200083_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/4\/4\/9\/44492960303b59ebed9450b39e542d9822a2b113_200083_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noyer avec Scuro plan simple en",
      "sku": "200057_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/e\/6\/ebe64864f5971fd7c4041c43b9a2f2c33f75539d_200057_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm noir mat avec Scuro plan simple en ",
      "sku": "91082_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/8\/e6a807d158c3d10f9045c8528b5b81f0611dba8f_91082_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm blanc mat avec Scuro plan simple en ",
      "sku": "91081_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/a\/3\/bba3fc6a7ad55829bd161f4cdf233a22b8a686f0_91081_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  Meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Scuro plan simple en ",
      "sku": "91080_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/1\/19f1d6b9fe294f5f7aa9253700c26c31a7bbaadd_91080_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "89219_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/e\/0\/b\/0e0b52fa73285d05fff9eb4eac3be3532d5fa929_89219_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "89213_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/4\/a\/e24ab4767521f52e3516dbfc71eb5dc9ffd39875_89213_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "89195_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/a\/66ca2368ea87ec6ad0b1f9deae58208e77d5f45c_89195_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "89169_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/a\/8\/3fa8be1954f837f37b14746c5a3311bdc6617eea_950_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/9\/2\/ef92ed57c8b11a70a76a8730e22a848a09a4d143_89169_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm noir mat avec Scuro plan simple en quartz",
      "sku": "83594_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/6\/2\/2\/562246ef96f4165abf23b1f649f76996d1d90f36_83594_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc mat avec Scuro plan simple en quartz",
      "sku": "83593_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/d\/7\/b6d77cf0961e78c00460650353b34edb5526345a_83593_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm blanc brillant avec Scuro plan simple en quartz",
      "sku": "83592_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/8\/b\/9c8bf87c22798882877feee931b15f7bf188ada5_83592_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm ch\u00eane brut avec Scuro plan simple en quartz",
      "sku": "83590_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/7\/d\/747d8e731ee0953f9302e8f65959bf7ee4739296_83590_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 95 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro plan simple en quartz",
      "sku": "83587_83608",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/4\/d\/324dc609ae606e24c99b7e4c2b2e240244db6f63_950_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/5\/0a452d9299e9bbc48aebcbe14061bef909f12a0f_83587_tw950tc950tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/2\/c\/1c2c81e41dd381e5bdb70f6ba76c9b210fc6ea27_83608_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "95cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201245_201250_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/9\/3\/da93ab8ff6ea9897d6dc2b675a6c1d40ebf8bfcb_201245_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201207_201208_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/0\/3\/c9031226e3b09e348973684a914ba4510cf71eab_201207_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200249_200253_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/8\/2\/d3825f451881c8fb1d7c06fa7fc9ed7d77dea3c3_200249_200253_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200079_200084_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/5\/d\/d25d7639a046857cc824ebd68c9e4f2ede7c8cb2_200079_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200078_200084_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/1\/5\/8e154a79aab28074aa65f1b788bf8fe7f07818bf_200078_200084_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200060_200064_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/7\/9\/06798914a8a61493fc4eaa24ce8a84587eeaca4d_200060_200064_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200041_200039_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/7\/8\/9278fcdcee1e015afa2a64ecc3957c778d2ff4f1_200041_200039_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65549_65547_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/8\/c\/a78c0207371bd032a36bed1c9ca66c5224bfb3c5_65549_65547_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65541_65539_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/9\/3be9f2284dca61bd4803818532156377f156f564_65541_65539_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65523_65521_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/f\/e\/81fe1e69de9105bf858d2e613e943226bc9cbf10_65523_65521_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65509_65505_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/7\/7\/73770a60a8ec8079f1da3d38b84e1fb9e8b43f81_65509_65505_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65499_65497_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/f\/3\/3ef3d12c407b9b40c6dff75535e1b01f1f28ea9d_65499_65497_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89218_88779_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/5\/a\/c65adc342b03cbcbdf97328e9293fe7c88a7b8ab_89218_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89209_89214_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/6\/b\/2\/76b2da1f34c45bb01e6e4fce98a1dbeea631207a_89209_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89208_89214_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/5\/b\/be5bc3f7aa3c0debc4e04d92b4adcb8a3ceb4ae8_89208_89214_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89200_89205_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/1\/9\/69199311bee1c17d4acd3cba384f2973e28b5723_89200_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89199_89205_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/8\/a\/9\/f8a909619717f1e4450382488a233b2c3cfc2c1e_89199_89205_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89191_89196_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/2\/9\/6729c3479ad8756841c63a8ff3557dbe6d12da86_89191_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89190_89196_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/8\/7\/2\/0872d489a711eec13ddab9add0caad21e34462ff_89190_89196_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89172_89178_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/e\/a\/46ea04aff1bd9458cc7512fe9b0064493956eb18_89172_89178_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89168_88773_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/c\/9\/81c9d562142e49faefc21b94bc56467e8e70f541_89168_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88784_88779_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/b\/9\/d6b9f27f0c7733de7947aa506a6388d8ab339ec2_88784_88779_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88782_88773_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/1\/a791fcb30851e50954af6cb6b83d0a2d1c294d81_88782_88773_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83713_83717_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/3\/6\/0436f6fc6346a79f11c8a9d535f3d58b1cff0439_83713_83717_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83706_83710_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/b\/d\/38bd9bb386baeabec484dcaf3ded2c60717cd100_83706_83710_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83699_83703_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/4\/8\/8d48fbc586103f20c82f3d3e81dc52ed67e83a1b_83699_83703_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83692_83696_83569",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/f\/6\/e1f69a282cc0093cfa8066b13c3f8906c88119bc_83692_83696_tw1500tc450_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/5\/3\/e153f992dac31d798597520c5b46da3ad9f881aa_83569_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201245_201249_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/8\/8\/cc88395a6abc66afbafae0ce4d0565527f601d4d_201245_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201207_201303_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/9\/2\/e392d3592b8f9ee702ecb883f6b6bdc800531d8f_201207_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200079_200083_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/9\/3\/0\/b9300dcde1d70d5319178f5302d729f1d77a7d1b_200079_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200078_200083_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/1\/1\/e\/c11e0ab083f5ec3aa241d366ac0f1d1789e13016_200078_200083_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89218_89219_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/6\/9\/ba69d0efae2496cb814706801712ee43ffa1bec8_89218_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89209_89213_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/7\/0\/5b7079605f214fe8e737439a8edce286c41650bb_89209_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89208_89213_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/d\/8\/7ad8552839faf76426432b3d7b3c87f73e17d2dc_89208_89213_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89191_89195_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/b\/5\/2\/5b5246ae63e921b54cfc193e0959a1a57da6b478_89191_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89190_89195_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/9\/9\/029964d60222045c01c0f39abc7135c84ad544ef_89190_89195_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89168_89169_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/2\/b\/ea2b4cd57ab1ab4acc2ad2ed99a15284daec458e_89168_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88784_89219_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/b\/b\/1dbb3ae76a839c91ad39e3e30d624206277117d2_88784_89219_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88782_89169_83568",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/1\/d\/a91d121f5b00c45a7ef0c879398e8e52a7af0f95_88782_89169_tw1400tc450_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/4\/6\/8f466bf4d4f6c2b6b316d60348dc9d00a7ada66c_83568_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201303_201207_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/3\/8\/68381b2718ceafd55485bf340a38d35201cb3676_201303_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201249_201245_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/b\/1c7b6e89389af90876ade5bf9c027a5e43c40dd4_201249_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200083_200079_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/5\/41f5469f9bd6c6b61d7fa5941f9abde767a95c12_200083_200079_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200083_200078_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/0\/a\/2\/50a2a7f7e56bf5b03ee0c932edf0c3040f618a82_200083_200078_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_89218_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/4\/0\/0f4029eacb978babc77763bd136020b886e18ea1_89219_89218_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_88784_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/6\/f7f60e70e0bf0cf59f7713272b0a377a5b0a5930_89219_88784_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89209_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/6\/0\/2e60999086a843a592d21b3f6d37fed8680b8add_89213_89209_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89208_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/d\/cc0d35373bcf1a566f7ea5f2067be5c6bc3cb27e_89213_89208_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89191_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/f\/9f1f293a1208f8c1251338b787c40b1e577a1781_89195_89191_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89190_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/3\/6\/d43628ea9ed3d4de282e32f1a57370b1cb95ada2_89195_89190_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_89168_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/d\/c\/91dcf4f23234c708fff7a0d6b1dd3f664d4a7834_89169_89168_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_88782_83567",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/d\/3\/34d3397dfd55dba078bfd05f35b8bc1e1980cc5c_89169_88782_tw1400tc950_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/d\/a\/c8da62c8e3c1fd597d2b7c46dcf9a26539d23afb_83567_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201245_201248_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/8\/c\/a48c7a56567b0dd505a469ecc8453b84a0f7afb4_201245_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201207_201302_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/1\/4\/b\/914b380f19d958b3d158c0b457081ddae2b7ca2e_201207_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200249_200252_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/f\/e\/9efe7b53f06f3754cd182cb25805488ea58abebd_200249_200252_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200079_200082_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/0\/d\/9f0d7568530622a3bb0e9566611ad90a332338dd_200079_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200078_200082_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/7\/e\/7a7efb952dd729d1fc1085ed266b12463349dd5a_200078_200082_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200060_200063_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/6\/f\/c\/c6fc571c0c9f5adabf837694b3c3307945043a30_200060_200063_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200041_200038_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/f\/c4bf094615cc7051be37faafb7d21735a54b42e2_200041_200038_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65549_65546_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/9\/f\/a\/19fa9fd702693dc5148688f5f3388c820b0c4017_65549_65546_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65541_65538_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/7\/b\/027b7dadaf3a27fa8fc5c532d7a8970e9734071d_65541_65538_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65523_65520_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/d\/ed5dfa4bb1be45b3bd87b512c0dcc355cbabb651_65523_65520_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65499_65496_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/7\/c\/b57c382bb09fa8087233cace32370e4c5726b5d1_65499_65496_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89218_88778_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/2\/e\/342efb3b40a871582472e4b1b8494ec34d4462f1_89218_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89209_89212_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/c\/5\/5\/2c5524346707f78496ae4ee2515608afce381fa6_89209_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89208_89212_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/b\/6\/c7b63c6903c389028e22f6a8a6b61eade09e5953_89208_89212_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89200_89203_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/2\/6\/c4263d4186713339f2fd3be5618395777fd406ae_89200_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89199_89203_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/d\/4\/edd430d816ba1f39eea0721c6aca47ddb7805ce3_89199_89203_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89191_89194_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/4\/6\/f3463c1e9da995d012bc1e027f190308a35a45ed_89191_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89190_89194_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/e\/4c8e9c6289fe0749fd5512e39fc7ed603b291582_89190_89194_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89168_88772_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/d\/d\/badd8ca3e65be8f9268d94758de9fbeed34d8f1b_89168_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88784_88778_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/5\/c\/e25c377f442f5b119cc4970efab97a4e0a44268c_88784_88778_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88782_88772_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/0\/a\/0c0a8274b608d6639ca3425f8577feb046c12227_88782_88772_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83713_83716_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/f\/6\/b6f699df475525a39b076680e4727fa9d255cb1b_83713_83716_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83706_83709_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/b\/c\/86bcf4334bbb0445230877ad2cb43b4438057362_83706_83709_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83699_83702_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/d\/4\/dad420463e728c1643059e785377a0e8853a3e2c_83699_83702_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83692_83695_83566",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/8\/5\/5785b65f5eecf493976e3f53f16f0c0b1db8bbd0_83692_83695_tw1300tc450_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/3\/2\/7b322b1e18d787fa446d525de544743797ebb6cd_83566_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201245_201247_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/3\/5\/96352aade106e2eec78799524b2c174c84e0d0d4_201245_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201207_201301_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/2\/5\/ef25c3e8bf05666d137d60bdba752bcf9861ad4c_201207_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200249_200251_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/2\/7\/bf27189d3d2690808794e7c0880d4919a3e19447_200249_200251_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200079_200081_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/d\/d\/2\/1dd295ac746b0cf8b53b1e74cd3a06881845b9df_200079_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200078_200081_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/c\/2\/c2c268b71011a9bc0783eb27e60420ec966154a2_200078_200081_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200060_200062_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/d\/c\/ecdc5d24bd01e4b55b41c4c069f1400fa0905a12_200060_200062_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200041_200037_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/a\/2\/e9a2946356ad7d8b8ef3794d0a2ab9bc2f0a5a37_200041_200037_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65549_65545_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/4\/1\/dd4187ce2edacd7d95066528f5894f8bc5399dfa_65549_65545_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65541_65537_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/1\/7\/141711a1271e1ed1332492221425c2862e1017b3_65541_65537_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65523_65519_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/b\/a\/f\/2bafb248c11039ce97de37d7bfe3eae4fde9a8a6_65523_65519_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65515_65511_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/c\/6\/b5c6b9687f06d47107d9d7711c7073cdef431b8c_65515_65511_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65499_65495_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/3\/c\/b\/33cb9b1421e4a3de83542f67ac66bbe5c3e949f4_65499_65495_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89218_88777_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/5\/4\/a45457c2e56e42350844ca9c3b0d1daf91237636_89218_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89209_89211_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/e\/e6ae8ea1d270bbb7fedc768a835b2ff71cd58ec8_89209_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89208_89211_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/7\/c\/c\/57cc6d473a1745fec7be9bbcc608e6fea4c39a4f_89208_89211_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89200_89202_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/b\/c\/48bc5bbb1f8144525931768f1c5bfcdc10f53734_89200_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89199_89202_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/d\/668d155486671d18abe567c85470fd60a302bf22_89199_89202_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89191_89193_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/7\/c\/e07cc00b679aff6a631adb28fdc892544782ceb5_89191_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89190_89193_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/1\/2\/d912033e9081fcf16de4f5c9b411d7768764cac1_89190_89193_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89168_88771_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/1\/1\/a51182373354d3495e48595558a1033c07eced6d_89168_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88784_88777_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/d\/0\/28d0859496780263a434c17c4647a562c5f3d2a5_88784_88777_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88782_88771_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/5\/ee05cfe02e00d5310f670fe7bf46360486f481a5_88782_88771_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83713_83715_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/2\/e\/7a2e3d6a46a4454d8f141406c73bbbeb3c10b77f_83713_83715_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83706_83708_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/e\/3\/e6e383b6445add667f5fd691a392f8825ba9bc4b_83706_83708_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83699_83701_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/9\/a\/069a8eef3adc864f6d924fc1ae6e03b3b7eac8e8_83699_83701_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83692_83694_83565",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/f\/8\/d4f8f33eceb0f855dc4ff7ec0b3414ea1dbd331c_83692_83694_tw1200tc450_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/5\/0\/c850c25b7a1af331333d85d387f1906f09ce94d0_83565_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201245_201246_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/a\/1\/3\/da1380971aed640cb314a1a42f02f8de2504d053_201245_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201207_201300_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/e\/8\/d\/ae8d5012a52197ec5b956db63c5cb5c7f8594596_201207_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200249_200250_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/1\/d641333dec1034e75b2835abec1b380bce61d567_200249_200250_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200079_200080_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/b\/3\/37b3b23fc6d7ae8890b34d08a4ccf64345751522_200079_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200078_200080_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/7\/2\/707291a6ac0379823d49de403ec8535b5a0a6033_200078_200080_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200060_200061_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/6\/1\/7e61419f522e9e4e4c9c3b940d68a7ef56e91233_200060_200061_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200041_200036_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/e\/f\/d8efe3ed0ab73131518a663ccbf9c9854870afd5_200041_200036_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65549_65544_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/3\/f\/a53f3df5e3ac0fe5144f9e6c0cfe7ad294e9f66e_65549_65544_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65541_65536_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/f\/d\/f7fd48fca4f5305d0a22bf8600d2229e7bf2daad_65541_65536_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65523_65518_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/6\/7\/11678ed19b1d7954ca64b93ccb361608d1062d7f_65523_65518_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "65499_65494_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/0\/9b501afac9c756c8849ad7b4b66c829e0f301a8d_65499_65494_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89218_88776_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/c\/8\/5\/4c856130551b98e25c70e6c15bb8cf5d600602f3_89218_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89209_89210_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/7\/d\/6a7d48ba90ddb3c724fda027a33874bb70453b9c_89209_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89208_89210_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/2\/f\/8\/e2f82314b2e476c062ec4671f2d700b67a47a1eb_89208_89210_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89200_89201_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/0\/2\/930253d4ea38f0b58a0d4b6ae8698de8b0bfbf47_89200_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89199_89201_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/8\/f\/0a8f360ad0fa502670efd27770a64ee0f38e1322_89199_89201_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89191_89192_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/7\/6\/897671e7d97ad4833f354b14953e9cb9a86fd765_89191_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89190_89192_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/4\/6\/c846b12ce269b5d911f9aefa0cd6be6cb7c5fd3c_89190_89192_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89168_88770_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/4\/7\/a747049617a5af185b8b951b3c4195b7f682b587_89168_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88784_88776_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/4\/c9d46c89a0576dd348e9929e8692bcf89135ce33_88784_88776_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "88782_88770_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/f\/6\/e8f66534664bd497fe4bf65565de1a742a0912bf_88782_88770_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83713_83714_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/9\/afc98fd712744419e3b8c435d1c27756ccbdd037_83713_83714_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83706_83707_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/0\/f\/320f935a08ad033f6c925994a69f20ae547f03c2_83706_83707_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83699_83700_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/d\/3\/12d3f9587550204fa79c1043733fd69f0956be08_83699_83700_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83692_83693_83564",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/f\/819fbe9b15d62eb0ba3d1b0071cb98c17fc1679e_83692_83693_tw1100tc450_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/7\/6\/b\/f76bd51c4c4e9c6f2c23915a031a2f1eb063eb36_83564_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201300_201207_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/5\/0\/ed50bdb5fdb1b90fbb205c43b43348fc15438c62_201300_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201246_201245_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/0\/e\/8a0e0703488f8497ad64eeccd8be9502edc20ef2_201246_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200250_200249_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/a\/5\/a\/1a5a8476f6ad98788436219b0ebec917cd72e0a9_200250_200249_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200080_200079_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/0\/b\/8\/b0b8e590fe357cca7a95b4cdc2abd595f8e91839_200080_200079_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200080_200078_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/1\/7\/301794549a4e112a21a8ce65d2adede0432cef5a_200080_200078_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200061_200060_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/4\/b\/f\/34bfbdb2626ad50386ae13f24b92ebc253334f03_200061_200060_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200036_200041_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/b\/6\/1eb63a8eecb8f662135e8b38a511a46d59af9894_200036_200041_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65544_65549_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/b\/3\/9\/6b392c4c7743a4d7f9b62fb13e7d455c0b0fb4d2_65544_65549_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65536_65541_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/1\/7\/4\/a17414d458225b1d4bfa8cd50f4227461dbaa418_65536_65541_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65518_65523_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/f\/7\/eff7de41ae4a733f69c0d8f5c9299dd89d21358e_65518_65523_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "65494_65499_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/b\/9\/2\/fb92d2ed5ad448016b9dd2f3159547c2ab92b51b_1100_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/2\/b\/df2b852e2af5c11cd7a7bbc178d7bd4a7d3e5ba2_65494_65499_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89209_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/3\/1\/3b315ceb0167128158f5bf2f4c3243ffb0040ced_89210_89209_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89208_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/7\/3\/29738718e83a9a93522d9186c238ef33bc476802_89210_89208_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89200_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/7\/4\/d\/b74d3979b8efc612bc4264d8efec1c579e37829a_89201_89200_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89199_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/b\/e\/f\/3bef97cb17bd2b3e2c688b620dbaf36e293c55e9_89201_89199_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89191_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/3\/1\/5531b375a41d84ea48632bd13701850dbca7e31c_89192_89191_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89190_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/2\/0\/99207c5230bb128d0e86fa51100713ba53dae8aa_89192_89190_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_89218_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/8\/0\/18807b5026f07a5d3acbcd2c2e711ae817f9b3fd_88776_89218_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_88784_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/f\/f\/e\/cffed89c066ad75116b2006935b4a77309ce4ae3_88776_88784_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_89168_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/9\/d\/559d7c97f49ab3f9c9f036ef64b7a1f78cfc8f18_88770_89168_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_88782_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/6\/7\/3d67d0660f9301ca40862b906a1b5ec3b6b0cbfc_88770_88782_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83714_83713_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/4\/a\/844a16ab5627172b7bdebc77b10bb880cbdb835b_83714_83713_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83707_83706_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/6\/0086efdd6cdd31f52e30801448f4648bc06197ba_83707_83706_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83700_83699_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/7\/7\/6\/0776f9324df6cf5d2f32817d9b864aa94f1d8988_83700_83699_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83693_83692_83563",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/5\/4\/3d5401e97296962cd21d2905c75984bf64953a09_83693_83692_tw1100tc650_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/9\/a\/d\/69ad5f8d120962bcc8680aa1b43eb60843093d4b_83563_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201299_201208_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/f\/a\/4bfaa15fac24ae40019c3b9afb0e63aa551b1c65_201299_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201244_201250_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/8\/e\/0c8e5391945ab3d4a1755f36a811458ccf09ef1c_201244_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200248_200253_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/c\/0\/0\/cc001d491f1a3b61eb5a816e38470016fb93c7c0_200248_200253_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200077_200084_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/d\/2\/1bd22b3122fdf8486314cfb7012210317b4993a6_200077_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200076_200084_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/d\/d\/8eddddcd1f09783a96e5b30ec7af7614c2be5424_200076_200084_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200059_200064_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/0\/b\/8c0bd021dde4fa3109ba058b4c97c76d9ae6f2d8_200059_200064_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89217_88779_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/b\/0\/a4b0b304e2d272a4aa8d67755765528fb016414e_89217_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89216_88779_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/b\/1e0bda70c073165a859284e6d97945c97960ad9c_89216_88779_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89207_89214_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/3\/6\/5e36aa6a26e7467a0f888e9515ece5431e92421a_89207_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89206_89214_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/2\/d77248a5fd4cae9bd939eddb80254ad275b0810e_89206_89214_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89198_89205_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/0\/6640fcace08104eb24b61ccad77b63e4005cb020_89198_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89197_89205_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/d\/1\/c9d13cd5a563071322643259498955d7ff359ee6_89197_89205_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89189_89196_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/a\/e\/a7ae23a381149876eb4d4297b986a1a016d518ec_89189_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89188_89196_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/6\/9\/6769ea58d12b67c4592bf288c2a3b40f2d5e3ac1_89188_89196_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89170_89178_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/c\/3\/cdc3baf1272bf3ca45ae26f0e03e341a484047b0_89170_89178_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89167_88773_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/1\/c\/271c2f8eb4a8fb01ae4e64f30a5f517fd1e6bf39_89167_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89166_88773_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/b\/3\/eab37aa5f1a5006a4a9d88a5dc2c4940cd2b3202_89166_88773_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83712_83717_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/e\/6\/9\/4e697b78446e037700f8f0417c51c277ee06e861_83712_83717_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83705_83710_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/a\/e\/f\/aaefe601d35ae60a89162891fd6ff8764dc37cf5_83705_83710_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83698_83703_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/f\/6\/e3f63275a2017f896625c45bb357c40162f12367_83698_83703_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83691_83696_83136",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/4\/b\/0\/c4b090ba920b0feccad23d14ba0534bab105bebe_83691_83696_tw1400tc350_1050tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/5\/2\/7852edb525a3f6bc436dbd9973a9e288d053ea1d_83136_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201250_201244_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/2\/75b21f65605a0effcdf12a10c52950416eb27bdd_201250_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 140 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201208_201299_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/3\/2\/5\/e325b3769ea0bd42871e3165b1f145d01a403b5e_201208_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200253_200248_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/0\/e\/1\/10e1541cd8d8408839dfd3c80e0d7515f51e56d1_200253_200248_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200084_200077_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/5\/4\/fa548b1e8eabfa5c9d4c53dfcedbd20e2fb8b8e2_200084_200077_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200084_200076_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/d\/b\/64dbb8b471bde4564662f465affa866be43ce457_200084_200076_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200064_200059_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/2\/3\/3e233947e07c626d28ed8d3340007bc150efebac_200064_200059_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89207_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/9\/c\/849c6589c337e12d6015a890497b6380ee89210c_89214_89207_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89214_89206_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/0\/c\/c\/90cc9f0d7d1e8f9958116146d49ee91af6d0f96d_89214_89206_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89198_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/3\/1\/4b316f0520b097247dad9473528a5d4d3f6762c2_89205_89198_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89205_89197_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/3\/a\/0\/c3a0622f33e13adcdfa68a9bdd34c0208dda11ec_89205_89197_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89189_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/6\/2\/f9629dee1ca72bf827e4eafd3be0586abd423b07_89196_89189_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89196_89188_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/f\/c\/41fc39ea98df749e1bdc53b4a2d0544eae6065a9_89196_89188_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris argent avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89178_89170_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/e\/9\/cae9c5141571fa91e3f9afef133b12ca02364d86_89178_89170_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_89217_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/6\/f\/006fe865a4832c769a7efbee904bc0dc559bbc1a_88779_89217_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88779_89216_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/c\/819c60fd262ce4e08040c2daad38b747920f7e92_88779_89216_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_89167_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/b\/a79b03be0a4bec4d1fb23f956f676ced5d5ff2f0_88773_89167_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88773_89166_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/1\/8\/9f1819710fd5fe52824644b219f1c236da736d15_88773_89166_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83717_83712_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/8\/9\/d8894ad63bd4098accb4f7fb778442f68a87c24e_83717_83712_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83710_83705_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/8\/9\/6\/589692f791af641ea7b66f16a1871f9e1a1e337a_83710_83705_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83703_83698_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/9\/0\/b490474d462123a53a5ef71eb3d34b483cbbb01c_83703_83698_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 140 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83696_83691_83135",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/c\/9\/e5c9bef120d5a605db032c52f144545ef560fdd6_1400_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/1\/c\/b61c9944016574b3a58ce8f53e52af553c8b9ff5_83696_83691_tw1400tc1050_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/0\/a\/0\/80a08b03ca66c8919a0b88109da7f8fd148451ff_83135_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "140cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201299_201303_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/d\/8\/ead8f1cb29ad109ab481e612a031fa0862c60d08_201299_201303_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201244_201249_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/a\/7\/a\/2a7a975410981f8a03edff6735e4d55f1ee3be3a_201244_201249_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200077_200083_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/e\/4\/1ee45e8e82c73ddd22f055d90c0187f69dec86ef_200077_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200076_200083_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/2\/7\/f\/b27f523b24447136ba3ba2db45914cce46bd5f5a_200076_200083_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89217_89219_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/e\/adbea03b7c640d01e1ffce8fe79c8f9572f5e6cc_89217_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89216_89219_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/e\/6\/b4e68cd903e59e98f290575dda8d3dad973e0c29_89216_89219_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89207_89213_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/a\/4\/0ca49510a7dd0c7bb5f105a74a77b3d6b95901a3_89207_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89206_89213_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/4\/9\/6749cf2b567bc562db4f67cbea0f995a343c24b6_89206_89213_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89189_89195_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/8\/d\/8d8df4be12802f283ab5d5538d55ea7e0b6a2ab5_89189_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89188_89195_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/6\/5\/b\/b65bb0429e42624856bda46ae0abc73b1f62c639_89188_89195_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89167_89169_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/0\/6\/ee063b6c718eb1d69a64650369c65003ef8997a9_89167_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89166_89169_83134",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/0\/9\/a\/c09a9b452f0a452ac8ff50150c0451157c34257d_89166_89169_tw1300tc350_950tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/5\/4\/5\/b545d15dc61c55bbbc43836e4f8deb9196f558f1_83134_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201303_201299_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/0\/8\/7\/f0873c1674836eb94e7c7cfff9136af43b15900f_201303_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201249_201244_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/3\/5\/9\/1359475e25979a68d3d3af672a5cd1130a65c6b7_201249_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200083_200077_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/3\/8403d3e16039a0e78cbc110d3cdb137fd04c9b46_200083_200077_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200083_200076_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/c\/6\/ddc680cab4c6ab74fd97cc49345cb214cf1bbf6f_200083_200076_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_89217_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/8\/0\/1280c5d7f24770d313b142888e15a284cab88ddc_89219_89217_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89219_89216_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/2\/a\/812a68efced6da741b080e3361fe3f8528b80975_89219_89216_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89207_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/1\/2\/a3124fb2c4c115e61c0b9a71a62087fa72c1e504_89213_89207_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89213_89206_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/8\/a\/008a8dc6891994eb0ca0ca312184715f5a16e049_89213_89206_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89189_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/5\/6\/9e56f1337525d135e6962ddfe186caff9f9a29cc_89195_89189_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89195_89188_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/c\/b\/a7cb4fe08378a5b1c631e1fb97b34543e3052974_89195_89188_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_89167_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/8\/9\/4\/78948c20e5317aef8d58c81378d2ae4e432a32cd_89169_89167_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89169_89166_83133",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/5\/8\/a758ff3e2c04080ea17b3934c51be9835661da55_89169_89166_tw1300tc950_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/a\/b\/0\/6ab069fde81aa099096104a98e86dba046a67f83_83133_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201299_201302_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/e\/1\/bae1157a18b665e8a44b4d7ee8f5514d6a206bc3_201299_201302_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201244_201248_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/c\/1\/67c1e79ea3cf96103f76aa7c934d8e4d60029c97_201244_201248_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200248_200252_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/9\/f\/1b9f89d3093630b23e9a9eeb8e83824cd27ce063_200248_200252_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200077_200082_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c16fc8909bdb0ca0dee55967c63802d52ffc4_200077_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200076_200082_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/7\/b\/967bfd1b6ed4ef6db7d034369d193f8a9dcd6020_200076_200082_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200059_200063_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/5\/a\/6d5a1f2c5eed5d32f10ac4adc9aa40d88b2d6536_200059_200063_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89217_88778_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/f\/f\/36ff7779f923b9ce23f9d4856c28a27d078ce646_89217_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89216_88778_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/e\/e\/a7eea02b2d949673e60a081b23c761d602e87f40_89216_88778_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89207_89212_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/d\/e\/2\/fde2941a3d429e6d5a28d0b970dd49f071c76326_89207_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89206_89212_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/0\/3\/6\/d036755c28735b3043f653830bfcb0df37ad571b_89206_89212_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89198_89203_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/b\/9\/adb96f308c6e8838cccf326de882d2fff70a7ccd_89198_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89197_89203_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/3\/0\/1\/d3015f2d3084c89890871b5e1a13047c3623e119_89197_89203_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89189_89194_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/b\/5\/f\/9b5fd09e98bf8a735b8f42bd22feea432ae99247_89189_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89188_89194_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/8\/c\/668ca7f5c6b5f56c366b07729af8132d07420828_89188_89194_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89167_88772_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/c\/e\/a\/0ceaecf390d6fb5e2d1f3d4eec198799b513f6b5_89167_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89166_88772_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/3\/8\/5\/73852532f5065c1a77d5943b56edcfcf020d6437_89166_88772_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83712_83716_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/d\/8\/99d8af7c8978c461dc191f3b519b047b6c2162d4_83712_83716_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83705_83709_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/7\/7\/0\/d770143be08577f153c499a4568026b7dacafca2_83705_83709_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83698_83702_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/5\/1\/79511076498d29aa28cf4a4cf734191a2017e0ec_83698_83702_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83691_83695_83132",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/7\/b\/4d7b38e07a26746a6ff93c197a311ef28d1e8647_83691_83695_tw1200tc350_850tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/9\/3\/a493461684bfa4e90bb83b64d228a13b5a3f861d_83132_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201302_201299_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/5\/8\/e858a351853b8b73ace2938d319f390ad4af9f4c_201302_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201248_201244_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/a\/8\/cda8b72f8dc2d73065cef4d5ed78c72e974f1e64_201248_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200252_200248_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/4\/6\/ca461f317c700b6c158c3130b0d8bd23e8a8cec6_200252_200248_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200082_200077_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/a\/a\/c9aa6475c1d9e7775ff40cfb0104d199e56b9406_200082_200077_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200082_200076_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/a\/b\/c8ab8bd7741ff64b94ecd6918fd0b5a1cabbd87e_200082_200076_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200063_200059_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/f\/3\/88f362c36cbeb5b13ca85587bbffc3d0a7f8631d_200063_200059_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89207_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/2\/0\/cb20f97430f2d875e6fc63e669314288eb915b0f_89212_89207_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89212_89206_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/e\/4\/61e4eda2b766b4620c057033a7c261d8bebb88b8_89212_89206_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89198_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/e\/1\/e5e176f22fa746e1aa4b8c193043221ebccd1076_89203_89198_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89203_89197_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/4\/f\/b\/64fbfcdba113f7d19b516403ef969c6e9c95a879_89203_89197_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89189_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/0\/5\/3005c34ee38fb6ba058ea48c04c3250155b4d0e9_89194_89189_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89194_89188_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/1\/a\/6d1aa0344b0c64d8c8ba9f00addff282522ddc30_89194_89188_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_89217_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/f\/b\/66fbcfbc9e16a6889b71044744c2aa556c2d9773_88778_89217_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88778_89216_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/f\/1\/7\/3f17a199a39ee3ebd7671e58eec2662dd83f0230_88778_89216_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_89167_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/c\/d\/2\/9cd2d9b7b363ceacd59df6bf0907bf18c6c9e6e3_88772_89167_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88772_89166_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/7\/0\/d270761388aa7eb827509b9be2d064c250a4aef0_88772_89166_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83716_83712_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/e\/8\/f9e8d4b73c693d809af236aabef562306ed9346c_83716_83712_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83709_83705_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/2\/3\/9\/f239fc36e8343c2e4ecef9ccfbdac930032dfa5f_83709_83705_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83695_83691_83131",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/d\/2\/3dd2d422f1ebd0c3c8d5287c2fd2921327340654_83695_83691_tw1200tc850_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/f\/e\/96fe2651d7a228ff546033d535aebfc21e837d4a_83131_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201299_201301_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/7\/8\/9f78585b31ee941f4ae2f02fe3120b48ed2ad3d6_201299_201301_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201244_201247_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/8\/8\/94883ee602d473e77c8587078863bcf8d0869348_201244_201247_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200248_200251_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/1\/6\/2\/5162c8c3335aae95debc4f51258c6ebfb2e94377_200248_200251_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200077_200081_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/d\/2\/9ed2aa8c501ef24bdfa84155c167e368a16af6ac_200077_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200076_200081_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/d\/b\/7\/6db7b1c5c0465b531ad5d811fea5bc374b5558f5_200076_200081_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200059_200062_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/3\/9\/a739587676250e5cc2220d563745dc01890e61ab_200059_200062_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89217_88777_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/b\/5\/5\/eb55e5290656bb4756a060541e49572215a96d9e_89217_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89216_88777_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/e\/6\/2\/de62c52dcc32f13c8a31ce3c6bcd56d073f0ceab_89216_88777_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89207_89211_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/5\/d\/2\/e5d28fe05b048f5ae60358b7ff825c9b6c6a3492_89207_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89206_89211_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/1\/1\/e\/711e4587323d85ec01212a4ee6fe6c003b13baa5_89206_89211_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89198_89202_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/7\/f\/1\/67f1302c73f3c9de5fd86d490d44d9de867f3cef_89198_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89197_89202_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/e\/3\/2\/8e32e611e68aaa424f2366ab5bdd39ede12fca11_89197_89202_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89189_89193_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/b\/0\/b\/7b0b915eb4fbdb4408851d652be4e1b293425f35_89189_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89188_89193_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/0\/3\/5\/00352dbd8a04c904226eb5b9758e08d2fc12dabc_89188_89193_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89167_88771_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/7\/c96754caeff4a52bd18aed4e097d51fe61d1d8d6_89167_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89166_88771_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/1\/9\/8\/d198489bd8be4a3ec682d726d61c5a36c0f43e80_89166_88771_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83712_83715_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/5\/d\/3\/a5d336df9d4bb8517dd1a7aae0c36bcfa9cb9f05_83712_83715_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83705_83708_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/3\/c\/f13c8dd17a75f3b9bb4952f51f7e2589aaff693d_83705_83708_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83698_83701_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/2\/d\/2\/62d2a84ece6db17652b9f9cc9fa1cb5e9dd4198c_83698_83701_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83691_83694_83130",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/d\/3\/e\/8d3e0c198c08b7c53cbf4a33b4597412bf8c0d4c_83691_83694_tw1100tc350_750tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/2\/8\/b128727a2246c9116a6b56b53e75038fe7ef6359_83130_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201301_201299_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/5\/e\/f55ee343058d9af2e7d02ffa077c1eed0612bf4c_201301_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 110 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201247_201244_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/f\/4\/f\/df4f03520540b740cdf5c0c7b1b9f812be9b6133_201247_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200251_200248_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/a\/7\/75a7f1d1d92e5d2292b043d072b8518d85432499_200251_200248_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200081_200077_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/3\/2\/35320320f81160b02572e3981292abc2fcb620f2_200081_200077_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200081_200076_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/8\/4\/5\/8845d22b8429e2ccef487449773d93d81629d7a5_200081_200076_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200062_200059_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/7\/f\/937f629c34f492fc2ad34d8818e50989c67b2aa0_200062_200059_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89207_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/3\/c\/3d3c03b07acfdd0903ef6ab3d9befc7782c8a166_89211_89207_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89211_89206_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/1\/c\/d\/61cda3c0081bd7289e241086f87754cae6f4793a_89211_89206_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89198_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/0\/a\/b80ae82e403b02c2af95bbebb06c5fb3b2606e00_89202_89198_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89202_89197_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/b\/c\/bcbc4b141a7b8920fcde46ec265988020f30e507_89202_89197_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89189_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/6\/f\/7\/06f7a7d8c1a8a4206997756a7b75e0f95bfd9cde_89193_89189_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89193_89188_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/f\/3\/46f34d3e7b895fec28c36fc3f66e08b2280d54a0_89193_89188_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_89217_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/b\/4\/72b47435eb12a80e36135b26a7f87e55ab9392c1_88777_89217_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88777_89216_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/2\/ddb285284a7bf154f26e2e93105fdcad01f98255_88777_89216_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_89167_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/8\/2\/6\/98262a25a1942061a4e504b1f5f12d2fa5c0046d_88771_89167_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88771_89166_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/4\/e\/5\/14e5c4368dc23734d2456bcf0375fc740bf3cdf0_88771_89166_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83715_83712_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/4\/2\/d642c1a3d848be9df06e7351ee5b8bde6414e4f4_83715_83712_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83708_83705_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/4\/2\/0a420ef1a77fbf5974c2e465faff86605f1e3e9e_83708_83705_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 110 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83694_83691_83129",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/4\/0\/5\/f40561270a162e8fa6933bb5b4f10dcd9678ec7a_1100_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/4\/0\/e640dbe51a233ae693fa544ad7e714aef64be01f_83694_83691_tw1100tc750_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/8\/1\/2781ef7d21d4c75ceaff4a876d11c10425636f13_83129_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "110cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201299_201300_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/0\/c\/8\/60c8b568cabb83005a042df2a26926c933daf0f5_201299_201300_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique droite im solid surface",
      "sku": "201244_201246_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/5\/0\/1\/8501b375db62e4238b823fcd8a4c8cd941c5165b_201244_201246_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique droite en",
      "sku": "200248_200250_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/9\/7\/1\/49711bf482bd4f024ab8c3e8103deb3d4f0cd71e_200248_200250_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200077_200080_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/1\/0\/b\/b10bd3cadfff8acc953c436ad6ef374fc1eabe70_200077_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200076_200080_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/3\/e\/0\/93e050355772a2572d374b2e4eed0da90a33525c_200076_200080_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique droite en",
      "sku": "200059_200061_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/f\/f\/b3ffe351e86a88fb2e77ed7bac704dfed44b9ff0_200059_200061_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89217_88776_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/b\/e\/ddbe430502c7d936a275599097a49da815618ce1_89217_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89216_88776_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/a\/e\/2\/eae22decc498c46aeb65d0e59447fc0c63bc1144_89216_88776_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89206_89210_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/8\/9\/f\/c89fc70f8b1f3024768aa6938e7978408dec119e_89206_89210_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89198_89201_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/4\/1\/d\/a41d2b54a81e02443f9a80d2a029aa73d9e78b2b_89198_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89197_89201_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/e\/f36ecb4b524508068b92073f9994587b6d9cbf3f_89197_89201_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89189_89192_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/a\/9\/8\/8a98e7dbec16dbd36342cd5cdfa2965887b3d24d_89189_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89188_89192_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/e\/f\/1\/eef106d0c31a045b50bd4c965c0a4f4b1d6d6447_89188_89192_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89167_88770_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/8\/7\/5287ced70a6f0a96e5db3ab78b9fdbd69711e9d1_89167_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "89166_88770_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/6\/f\/5\/16f5403217733e30da585271070878fc7bc7943c_89166_88770_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83712_83714_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/6\/0\/0\/8600c05216071bd31015bc459573e65fb072a2ed_83712_83714_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83705_83707_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/9\/5\/e\/d95eeb83256ff7b3228cfe0c87c9a756f6accb94_83705_83707_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83698_83700_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/c\/2\/0fc20293af0566a0b0f6b798e7afc57d25e6ffde_83698_83700_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique droite en solid surface",
      "sku": "83691_83693_83128",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/4\/d\/b\/b4db4726839a5d08f9e1218be318608d72cc73e6_83691_83693_tw1000tc350_650tbpar_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/2\/2\/4\/0224509d20372e4f2bbbbe5c44f203a81279ca91_83128_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm noyer mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201300_201299_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/5\/0\/18503f9f0ceb721779d958c0fc36cde3d10d5bf9_201300_201299_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 100 x 52  cm ch\u00eane noir mit Mata Vasque asym\u00e9trique gauche im solid surface",
      "sku": "201246_201244_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/c\/6\/28c66862bfe84cd2aff1c53f3bfa9247e4c99824_201246_201244_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm blanc brillant avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200250_200248_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/6\/9\/4\/f694a833810f1e224089213ae5043d8bd147e3d9_200250_200248_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200080_200077_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/f\/1\/faf1307c37f2752fcd895a54de494a0504d84fa8_200080_200077_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200080_200076_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/9\/a\/819a23093b71e8740db85b9f87979da37395af1a_200080_200076_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52 cm noyer avec Mata vasque asym\u00e9trique gauche en",
      "sku": "200061_200059_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/6\/7\/a36770d4415c0c4a463ca30fd14ddfa6c64b6531_200061_200059_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89207_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/7\/e\/2\/c7e2bfec7f91b613b59f14ccbb74c60d20bba7ff_89210_89207_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89210_89206_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/a\/7\/2\/0a722b841ae53a7ff8a70192ad8c869b99ac647a_89210_89206_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89198_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/3\/c\/ce3cbbb4428fbf5ded54d6a1e5bf108faabdc275_89201_89198_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89201_89197_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/8\/30c882cea76abdb9806dbf7a227bd6bb9e808441_89201_89197_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89189_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/2\/9\/7\/a297d0be7adb3e650c12f01040fcf2094e28ef7e_89192_89189_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "89192_89188_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/b\/1\/0\/1b1068afaf142dacaf7ad964cbd350257642eb96_89192_89188_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_89217_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/8\/5\/24850be38f793e7294c0c687c98a2aa8b6b668f8_88776_89217_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc brillant avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88776_89216_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/8\/2\/2882fd706340ec5be23072e42c0e0507f390a95e_88776_89216_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_89167_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/3\/c\/7\/b3c7987dd04be9d59c5984acb7c807c7475c6051_88770_89167_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "88770_89166_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/d\/6\/e0d6b66fbef4aff52ba9259ed661299f7a29f2f1_88770_89166_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm noir mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83714_83712_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/3\/1\/7431b769c2cec24647afd3a7e396716bdbb4ed10_83714_83712_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm blanc mat avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83707_83705_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/2\/1\/e\/321e7376a8cfbbe96454a76de8a5990ad1979564_83707_83705_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm ch\u00eane brut avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83700_83698_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/b\/a\/9\/aba9e50e67873ec7eea7b2b5e30eec968385009d_83700_83698_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 100 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata vasque asym\u00e9trique gauche en solid surface",
      "sku": "83693_83691_83127",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/e\/2\/74e2d50212fca033ae8852bc9080134adafd87a7_1000_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/5\/5\/6655fdff1acbf082c2cecc81516f760bc86f2dd6_83693_83691_tw1000tc650_350tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/a\/1\/8\/4a180ccd22cfe4c47dcade4752ac97051f12a183_83127_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "100cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201250_201245_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/c\/6\/7ac6794289b8277c86a044d0f585bccec134a505_201250_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 150 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201208_201207_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/9\/d\/c29d2f3b9e904d6125c6982637b53d5c0e7a8843_201208_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200253_200249_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/9\/7\/9\/7979b94fec1411df5bdc78b6d964df31f97a77f2_200253_200249_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200084_200079_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/5\/f\/5\/95f53c4d4cc4447e7ff8e8b7d6fefe9f589f4b1a_200084_200079_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200084_200078_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/d\/b\/3\/3db3f36be8219b14c60d8f08c099ff71c540ced2_200084_200078_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200064_200060_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/2\/3\/4d2330cfa1a6c692cf44ec4d74b9b980913f6170_200064_200060_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200039_200041_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/6\/e\/f56e9c7a688513b8d20f01bff37b71a2fb4d3342_200039_200041_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65547_65549_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/a\/0\/6\/ba06d12e23e1c3b9944ce52729a6c6aa70852d64_65547_65549_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65539_65541_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/5\/f\/c\/65fcfe8f95a58692a973dd928cfa5923927123d0_65539_65541_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65521_65523_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/d\/a\/2\/9da23b9a046fd7deed158781798249dd9a035d86_65521_65523_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm ch\u00eane naturel avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65505_65509_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/0\/6\/f\/e06faf624a4a1f1100f4016b8276bcedbefe0cad_65505_65509_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65497_65499_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/e\/8\/52e8b23152101464612edd3dc65e4ef8aa2e9877_1500_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/7\/7\/e\/777e064302869a90b50e1a15b97d1889cb4799de_65497_65499_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89209_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/f\/9\/f\/9f9f9fa0b896065acba21183bd3501497969b758_89214_89209_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89214_89208_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/e\/8\/a\/2e8a653767db4d651111775a29af857e2da7af1c_89214_89208_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89200_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/9\/3\/c\/093c37362d05a662ed155029ea3dfc3c785d5ee1_89205_89200_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89205_89199_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/8\/5\/7\/3857f02691789c40cf60422c914841d3df0b55cf_89205_89199_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89191_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/3\/1\/7\/8317af26d4ab1a9ea4f2b036b727ce2429063b8a_89196_89191_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89196_89190_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/3\/e\/b\/03eb6bd696afdacedd948b87d3f01fa24855dc7f_89196_89190_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89178_89172_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/c\/6\/c9c67f4d463d0a6937950824bae9b3599cfbed4d_89178_89172_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_89218_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/4\/4\/8\/2448f123cc20651c3245ec6455f74a37afc31630_88779_89218_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88779_88784_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/9\/9\/d\/f99d26136392183d6e41ea3b91840c4a68af354a_88779_88784_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_89168_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/5\/2\/0\/f5202a2ace7587f4dc153e4278971be29dab72f7_88773_89168_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88773_88782_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/d\/8\/25d89fc80e24527053b6167f3b26a86d39ffa91d_88773_88782_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83717_83713_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/a\/f\/2\/7af2f1c4762b4f74e9dbc707abf5c19e2ed2f47d_83717_83713_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83710_83706_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/5\/f\/5\/25f5ed8ae7b0d0cdf0c07fe5fbc6bbfa16741b61_83710_83706_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83703_83699_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/2\/e\/472eaf71d0cccced96dfc3e4d6ba154c599dcec8_83703_83699_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 150 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83696_83692_73033",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/8\/7\/d\/d87d86956875aa213bc409de8ea31c8934f20e9b_1500_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/8\/c\/9\/a8c9260e10fb8cf715dfa61343a68df9b67ced31_83696_83692_tw1500tc1050_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/b\/e\/2\/bbe2b42fb6a5e475ae45653358990273ea17b5b0_73033_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "150cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201302_201207_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/5\/d\/e\/35de0648d0dd9a7949e618e78f3eb469fcfa77d2_201302_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 130 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201248_201245_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/1\/d\/d\/01dd0dc6ba65c329da8408d6152ce9efbffee90f_201248_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200252_200249_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/6\/c\/5\/d6c5124d36aa04ac48bf32923713100963ff0935_200252_200249_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200082_200079_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/0\/7\/0\/4070a89e51820e74d71359088698b8e6d764906a_200082_200079_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200082_200078_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/7\/5\/c\/975c9cbef1b4e7e186bcd85d39b3e5520fd1c00d_200082_200078_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200063_200060_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/2\/e\/7\/12e75f1f85349da45ccbcaf2dd7d8497682f8a4c_200063_200060_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200038_200041_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/1\/0\/3\/f1032ba618c8a296802eb45167b79b5f2a74b63c_200038_200041_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65546_65549_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/c\/728cbc65e732cd03f374eb6f1b40e32fa7cf3d1d_65546_65549_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65538_65541_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/d\/d\/89ddc9cae55bf4d38f2cf0ac790d9dddc32f2200_65538_65541_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65520_65523_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/4\/6\/4\/746453fc7dd0268d0bbb19c14aa28ecc850dabac_65520_65523_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65496_65499_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/4\/3\/8143692b5c615fcc298898e18a973d1c6fcc6dc2_1300_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/8\/2\/e\/b82e9b5ff48df05a71c31d4e1c8613446a65a1c0_65496_65499_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89209_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/1\/3\/f\/113fc798499953c88940173a02209f7883fed715_89212_89209_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89212_89208_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/6\/0\/5\/2605cbb550d636612cb5efc01a9b7dd66076837e_89212_89208_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89200_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/e\/f\/7\/6ef740b0136af951a32aca54fe162a0368382b77_89203_89200_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89203_89199_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/7\/9\/d\/a79d9f30b491ec2f6a9b29b4d38995e84891c331_89203_89199_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89191_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/8\/b\/7\/e8b7a068473b73459c31a7149ac875536913ddc2_89194_89191_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89194_89190_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/0\/d\/450ddfc30f15726867c6be0f7bf267a5beee18d0_89194_89190_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_89218_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/c\/1\/6\/ec16ca586b00489d51b6cdd99ab6380fb28dc4e1_88778_89218_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88778_88784_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/2\/a\/f\/c2af6629f7d6a78d36adc07a77ceada0b9317abe_88778_88784_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_89168_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/9\/0\/c\/390ccb885e6584018734056cc5e7ea702c20bcff_88772_89168_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88772_88782_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/0\/c\/b\/30cbf382efd74bcc83c5e10e781d7bb5ad63e2ae_88772_88782_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83716_83713_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/1\/f\/e61f91798901e5416806c9fd5de206ce9d9dfa8d_83716_83713_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83709_83706_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/6\/c966afb93bca197faafa57ed41db468c5497b1bf_83709_83706_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 130 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83695_83692_73032",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/9\/3\/b\/993b3c2e0c42b21fd66038e293b7abf1320acad9_1300_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/0\/1\/4\/70145075179d557140f3ea692381d6c654509b4c_83695_83692_tw1300tc850_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/e\/7\/1ce7bdb0e13e029faffff9d62b87fad97bdfff8a_73032_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "130cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm noyer mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201301_201207_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/5\/8\/3\/0583616d36848ed153034c516f748f24ec7bfd69_201301_201207_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 120 x 52  cm ch\u00eane noir mit Scuro Vasque asym\u00e9trique gauche im quartz",
      "sku": "201247_201245_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/f\/d\/3\/4fd3844dab7c6b640e182e94f59bd5c21b0b48f3_201247_201245_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200251_200249_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/b\/0f5bc7278e4be9525ffd7350e7462dc79f4687a4_200251_200249_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200081_200079_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/a\/b\/ddab6d080422471822d93482bce03915aee9ed74_200081_200079_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200081_200078_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/c\/a\/f\/bcaf34c3f51d3790235ede0efbf4699cc9eed579_200081_200078_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200062_200060_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/3\/3\/8\/a338e4eee0046c54be8cabc5708e19583bfc1a26_200062_200060_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noyer avec Scuro vasque asym\u00e9trique gauche en",
      "sku": "200037_200041_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/6\/c\/fa6c45fcc0bad40ef03224422f9d686986c70b05_200037_200041_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65545_65549_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/9\/6\/3\/c96364f5e357931319c3b4be231e3480e1c9835e_65545_65549_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65537_65541_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/f\/2\/5\/ff25c12bb2fa73a543895f8fcd13b8ef10e28e51_65537_65541_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65519_65523_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/9\/d\/1\/29d14264a3f41313f4366288fdd0b3a425d1b4f3_65519_65523_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris argent avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65511_65515_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/e\/f\/a\/7efa5b8d3149a60b07103b3810f644cca63a6171_65511_65515_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "65495_65499_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/1\/0\/9\/81091dd99905616b5ccbdd6cafbcc1a382c435aa_1200_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/f\/c\/3\/afc3ee3277fa9978d4e21524f03a9ae713143bed_65495_65499_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89209_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/d\/3\/e4d32d3ef9ae6200c600e17cab190413bff72cd6_89211_89209_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89211_89208_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/d\/8\/f\/4d8f4fd3b362608da97b718e2736e6fb15c79156_89211_89208_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89200_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/4\/5\/6\/e4565858ddbf98b4eb2d74c208048066cd720c58_89202_89200_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89202_89199_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/7\/e\/e\/47ee480d7c5dccb47cbc043f0c69e7413e8d073c_89202_89199_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89191_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/4\/d\/184d0de7e45c93beb8ddfd495a95894a58f2372a_89193_89191_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm ch\u00eane brut avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "89193_89190_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/1\/b\/5\/e1b598a333a82a8979c822e10e4aef504cf4b6f9_89193_89190_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_89218_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/1\/8\/6618447ad4ab570b76a70fa645b6863fb9b89e68_88777_89218_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc brillant avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88777_88784_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/6\/8\/7\/36872b9575106b6571ad20498a90b72364788e6e_88777_88784_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_89168_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/8\/b\/a\/68ba25bb8335a1d40fa22802b3ad2b86f6a1ce1d_88771_89168_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "88771_88782_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/2\/f\/8\/92f8788fe85d73a21629b72c61b4d0ce5b56d58b_88771_88782_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm noir mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83715_83713_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/4\/b\/6\/94b6b3507d5ce33a59007ff8a3cf238753834080_83715_83713_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm blanc mat avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83708_83706_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/8\/3\/6\/18366d6fd1caf5ccc6dec450a52953ff66566c39_83708_83706_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 120 x 52  cm b\u00e9ton gris fonc\u00e9 avec Scuro vasque asym\u00e9trique gauche en quartz",
      "sku": "83694_83692_73031",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/e\/7\/e\/fe7e6598465ca9902b8d5226b90a8d2dc8de1ddc_1200_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/f\/0\/4\/8f042b1817a37fbcd7a46cadb22f8399c0608b02_83694_83692_tw1200tc750_450tbpal_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/4\/0\/d\/840dfc4b9adf6303674181b2f865649bdf7db142_73031_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_black",
      "type_basin_t": "integrated",
      "width_t": "120cm",
      "series_t": "scuro"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm soie grise mit Mata Plan simple im solid surface",
      "sku": "201740_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/7\/3\/d\/273d4e062864a7076e353d48adfad906e5bad191_201740_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm ch\u00eane noir mit Mata Plan simple im solid surface",
      "sku": "201250_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/e\/9\/89e98b5f9310e2fe16a36fce48900dd503ef9cd3_201250_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "black_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke  suspendu Meuble salle de bains 105 x 52  cm noyer mit Mata Plan simple im solid surface",
      "sku": "201208_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/a\/caca351b5cef0c18b0fef0cee674989b92e9aeba_storke_ribs_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/d\/8\/f\/ed8f288a4481805245265445c90cd81511911c99_201208_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "ribs",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm blanc brillant avec Mata plan simple en",
      "sku": "200253_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/2\/8\/4\/7284b84bbfdec8cc1b4c3f1f28fdf14b71b54468_200253_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Mata plan simple en",
      "sku": "200084_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/0\/d\/3\/a0d3dc7a1590b09c03061ef90b37ca6f3e57ab71_200084_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52 cm noyer avec Mata plan simple en",
      "sku": "200064_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/f\/5\/c\/2f5c0f06cbb180ff6948019f1dc67970664299f3_200064_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "walnut",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52 cm noyer avec Mata plan simple en",
      "sku": "200039_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/9\/7\/0\/a97019b4ddf8f61ee26c8eb8a5b9c092f13cddef_200039_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "walnut",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "65547_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/f\/4\/7\/bf4717038745f246a4eb05d10bfaea14a62cbd1b_65547_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "65539_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/c\/7\/7\/1c772102b8752c503611ca52d4453fe70f73bd83_65539_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "65521_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/a\/4\/3ea48f24f0334acffd9fb425f3e17b26b34ebed3_65521_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "65505_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/0\/3\/3\/2033534a8e751270615e55a3ffaf0298058aa039_65505_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains a poser 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "65497_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/7\/4\/6\/3746fb807179f2124f87944b7ccb72e576ab5a7c_1050_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/3\/c\/e\/53ce69dc256c634946883e5b81efa92fdd2e66bd_65497_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "89214_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/1\/4\/d\/314d72d9021cf6bb370784dbea78efc7c07ca4ea_89214_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "89205_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/c\/f\/d\/8cfd1a72a27826af12be5e89ca84eb46556ebb2c_89205_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "89196_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/2\/a\/3\/d2a36b41f2c22a91d5f8630f79bde645e46eda44_89196_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane naturel avec Mata plan simple en solid surface",
      "sku": "89187_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/1\/e\/0\/9\/1e095da184a393719886eab7a86621a396b33e8c_89187_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "natural_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris argent avec Mata plan simple en solid surface",
      "sku": "89178_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/7\/e\/e\/e7eef51c9addbed034c7411a72ff40633ea6133a_89178_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_silver",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc brillant avec Mata plan simple en solid surface",
      "sku": "88779_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/6\/a\/4\/e6a419d5a6460e27f20ae6f9afe8b1c7040d17dd_88779_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "88773_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/e\/9\/f\/9e9f2c3915618c952a076207a1895c346324604e_88773_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm noir mat avec Mata plan simple en solid surface",
      "sku": "83717_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/d\/d\/b\/cddbdd1bfc80889d8fdcc1ecd53c2a6d5c46bc0f_83717_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm blanc mat avec Mata plan simple en solid surface",
      "sku": "83710_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/8\/0\/ca806e726e50701161ae4ed6e43a69140a97afe0_83710_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm ch\u00eane brut avec Mata plan simple en solid surface",
      "sku": "83703_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/a\/b\/a\/fabae9b3e5a4286b8ebdd10b20fe98d2d38e2b18_83703_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 105 x 52  cm b\u00e9ton gris fonc\u00e9 avec Mata plan simple en solid surface",
      "sku": "83696_72259",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/e\/b\/7\/5eb7c3defe958b0716cd8bead35dc63ed0565680_1050_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/8\/9\/8\/2\/8982c7549affedc85794070eff13d33834a29f8a_83696_tw1050tc1050tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/5\/a\/0\/55a06fd239dcfa5f722209a6dc38c43bcc9ffaf5_72259_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "105cm",
      "series_t": "mata",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge composition de meuble noir mat 85cm",
      "sku": "65546_37163",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/a\/c\/0\/cac0a68debde76b8eb6aab7b2e01ee0fe07e09a4_65546_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/6\/f\/526f7acdc4d572b6f913d8fa8008d40ab50d67f6_37163_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble blanc mat 85cm",
      "sku": "65538_37163",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/7\/f\/d47f23eb3c26622e08bacd1f6566d92f7d99cfcd_65538_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/6\/f\/526f7acdc4d572b6f913d8fa8008d40ab50d67f6_37163_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 85cm",
      "sku": "65520_37163",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/7\/5\/b\/9\/75b935a0fcd6f243fc9f825f1678c0110ed8931b_65520_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/6\/f\/526f7acdc4d572b6f913d8fa8008d40ab50d67f6_37163_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble gris b\u00e9ton 85cm",
      "sku": "65496_37163",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/9\/9\/1\/e991b12b092a785778d0c63453c9e69e8328860d_850_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/e\/4\/2\/ce42a6a475288b9acaad55cb9b80361f4b901204_65496_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/6\/f\/526f7acdc4d572b6f913d8fa8008d40ab50d67f6_37163_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "precioza",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 85 x 52  cm blanc brillant avec Precioza plan simple en marbre composite mat",
      "sku": "88778_37163",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/4\/8\/9\/d48969967ef3d91798bffc5c3ac62319864fefb6_850_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/b\/a\/1\/cba1497186fea103efccea2f367317ba99a0442e_88778_tw850tc850tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/5\/2\/6\/f\/526f7acdc4d572b6f913d8fa8008d40ab50d67f6_37163_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "85cm",
      "series_t": "precioza",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm soie grise avec Precioza plan simple en marbre composite",
      "sku": "201727_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/2\/8\/f\/0\/28f0ccc6102b445ed6ee69393b78aee4508c8e10_201727_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "silk_grey",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm vert olive avec Precioza plan simple en marbre composite",
      "sku": "201720_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/4\/8\/0\/048061444d8bb38b1e0c11106b2dc4d6d7c58d8b_201720_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza",
      "color_detail_bc": "olive_green",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm bleu nuit avec Precioza plan simple en marbre composite",
      "sku": "201713_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/d\/9\/48d944d894df142f48fdb39eeb5ffba4416e9988_201713_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza",
      "color_detail_bc": "midnight_blue",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm noir mat avec Precioza plan simple en marbre composite",
      "sku": "91078_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/e\/f\/1\/3\/ef13e8ec0cd33deaad4f6c1ae80dc8f2707069d0_91078_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza",
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm blanc mat avec Precioza plan simple en marbre composite",
      "sku": "91077_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/a\/d\/f\/9\/adf9685c3cb2ebe8dfea0517d216ff78ca550fbe_91077_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza",
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm b\u00e9ton gris fonc\u00e9 avec Precioza plan simple en marbre composite",
      "sku": "91076_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/d\/d\/d\/f\/dddf41993f86c787726e1c9426ce414e34d8aadd_91076_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Seda meuble salle de bains suspendu 75 x 52  cm ch\u00eane brut avec Precioza plan simple en marbre composite",
      "sku": "91063_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/1\/2\/1\/4121f26de57059adf92cf58535c8e3a5334379d3_storke_seda_bg.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/f\/5\/7\/0f57e60efa13fda7eddba42861be2b944a3a3575_91063_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "seda",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble noir mat 75cm",
      "sku": "65545_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/0\/d\/8\/e\/0d8e996782a3d8fd64c3372f9881fce36e50e5b7_65545_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_black",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble blanc mat 75cm",
      "sku": "65537_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/3\/e\/4\/a\/3e4a57c1f2def4f354b28a5c246945565251a182_65537_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "mat_white",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble ch\u00eane brut 75cm",
      "sku": "65519_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/c\/5\/66c588300cf53f1ea1c58704d5955e35be83a08e_65519_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "rough_oak",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge composition de meuble gris b\u00e9ton 75cm",
      "sku": "65495_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/6\/6\/4\/5\/66451585527e42acddd8d0ff71b91b9c6e4a4e0c_750_standing_cabinet_shadow.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/6\/8\/1\/46815aa83c98b8e4626cd2fca2ff085038874855_65495_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza",
      "color_detail_bc": "concrete_dark_gray",
      "standing_floating_bc": "standing",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 75 x 52  cm blanc brillant avec Precioza plan simple en marbre composite mat",
      "sku": "88777_37162",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/8\/5\/9\/4859080068a76679fcd926079bc4cded0da787d9_750_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/b\/5\/8\/4b5809af67b3fc6bec25bc3bd9a5c8c6b4771334_88777_tw750tc750tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/9\/6\/5\/d\/965dc12baaafff78e529b4a14f5116b38f7e8be5_37162_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm",
      "color_detail_t": "mat_white",
      "type_basin_t": "integrated",
      "width_t": "75cm",
      "series_t": "precioza"
    },
    {
      "title": "Storke Edge meuble salle de bains suspendu 65 x 52  cm blanc brillant avec Precioza plan simple en marbre composite brillant",
      "sku": "88776_31630",
      "views": [
        {
          "name": "front",
          "images": [
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/4\/5\/e\/5\/45e51c63176b7553d1592a25aa984ac7bbd05ba4_background_6.jpg",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/f\/3\/6\/a\/f36a2afed1e0257abbc2e5ff6b5fdfd2c8974002_650_shadows_hangende_kast.png",
              "posx": "0",
              "posy": "0"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/b\/e\/3\/3\/be3371cc17c921a81d380d26c21e49d7e3a613f5_88776_tw650tc650tbps_f.png",
              "posx": "0",
              "posy": "-766"
            },
            {
              "image": "https:\/\/s3.eu-central-1.amazonaws.com\/akeneo.x2o.assets.prod\/assets\/c\/5\/e\/a\/c5ea80b3378a55525e0ba1cb28f31a84bd5957dc_31630_f.png",
              "posx": "0",
              "posy": "-766"
            }
          ]
        }
      ],
      "color_detail_t": "shiny_white",
      "type_basin_t": "integrated",
      "width_t": "65cm",
      "series_t": "precioza",
      "color_detail_bc": "shiny_white",
      "standing_floating_bc": "floating",
      "brand_bc": "storke",
      "series_bc": "Edge",
      "depth_bc": "52cm"
    }
  ]
};